import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';

import { ProgramFacets, TacticFacets } from '../find/dtos/filter-set.dto';
import { Organization } from '../organization/organization.entity';
import { PublicUser, User } from '../user/user.entity';

export enum AppSection {
	Planning = 'planning',
	Activation = 'activation',
	MediaPlan = 'media-plan'
}

// Base layout location codes, but can be extended by the client.
export enum QVLayoutLocationCode {
	TableView = 'tableView',
	PerformanceView = 'performanceView',
	BudgetView = 'budgetView'
}

export type PublicQuickView = Pick<
	QuickView,
	'id' | 'organizationId' | 'name' | 'description' | 'appSection' | 'code' | 'layoutLocationCode'
> & {
	system: boolean;
	default: boolean;
	author?: PublicUser;
};

@Entity('quickViews')
@Index(['organizationId', 'remoteId'], {
	unique: true
})
export class QuickView {
	@PrimaryGeneratedColumn('uuid')
	id: string;
	@Column('text', { nullable: true })
	remoteId: string;
	@Column('text', { nullable: false })
	organizationId: string;
	@ManyToOne(type => Organization, {
		onDelete: 'CASCADE'
	})
	@JoinColumn({ name: 'organizationId' })
	organization: Organization;
	@Column('text', { nullable: false })
	name: string;
	@Column('text', {
		nullable: false,
		default: 'placeholder-slug'
	})
	slug: string;
	@Column({
		type: 'enum',
		enum: AppSection,
		default: AppSection.Activation
	})
	appSection: AppSection;
	@Column('text', { nullable: true })
	description: string;
	@Column('jsonb', { nullable: false })
	code: ProgramFacets | TacticFacets | { filters: ProgramFacets | TacticFacets };
	@Column('text', { nullable: true })
	authorId: string;
	@ManyToOne(() => User, {
		eager: true,
		nullable: true,
		cascade: true
	})
	@JoinColumn({ name: 'authorId' })
	author?: User | Partial<User>;
	@Column({ type: 'timestamptz', nullable: false, default: () => 'NOW()' })
	created?: string;
	@Column({
		type: 'text',
		default: QVLayoutLocationCode.TableView,
		nullable: false
	})
	layoutLocationCode: string;

	constructor(value?: Partial<QuickView>) {
		if (value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for (const k in value) {
			this[k] = value[k];
		}
	}

	public toPublic(isDefault: boolean = false) {
		const pub: Partial<PublicQuickView> = {
			id: this.id,
			organizationId: this.organizationId,
			name: this.name,
			description: this.description,
			appSection: this.appSection,
			layoutLocationCode: this.layoutLocationCode,
			code: this.code,
			system: false,
			default: isDefault
		};

		if (this.remoteId) {
			pub.system = true;
		}

		if (this.author) {
			pub.author = new User(this.author).toPublic();
		}

		return pub as PublicQuickView;
	}
}
