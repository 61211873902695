import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Tactic } from './tactic.model';

export interface TacticState extends EntityState<Tactic>, ActiveState {
	sortByProperty: string;
	sortByDirection: 'asc' | 'desc';
}

export const initialTacticState: TacticState = {
	sortByProperty: 'start',
	sortByDirection: 'asc',
	active: undefined
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tactic' })
export class TacticStore extends EntityStore<TacticState> {
	constructor() {
		super(initialTacticState);
	}
}
