import { Column, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm';

import { CostType, PublicCostType } from '../cost-type/cost-type.entity';
import { PublicTactic, Tactic } from '../tactic/tactic.entity';
import { PublicUser, User } from '../user/user.entity';
import { BrandAllocation, PublicBrandAllocation } from '../brand-allocation/brand-allocation.entity';
import { BudgetDistributionGroup, PublicBudgetDistributionGroup } from '../budget-distribution-group/budget-distribution-group.entity';

export type PublicCost = Pick<Cost, 'id' | 'amountFromPlan' | 'amountPlanned' | 'tacticId' | 'created'> & {
	brandAllocations?: PublicBrandAllocation[];
	costType: PublicCostType;
	tactic?: PublicTactic;
	author: PublicUser;
	isDistributed: boolean;
	hidden?: boolean;
	budgetDistributionGroups?: PublicBudgetDistributionGroup[];
};

@Entity('costs')
export class Cost {
	@PrimaryGeneratedColumn('uuid')
	id: string;
	@Column('text', { nullable: false })
	costTypeId: string;
	@ManyToOne(() => CostType, {
		eager: true,
		onDelete: 'CASCADE'
	})
	@JoinColumn({ name: 'costTypeId' })
	costType: CostType | Partial<CostType>;
	@OneToMany(
		() => BrandAllocation,
		brandAllocation => brandAllocation.cost,
		{
			nullable: true,
			eager: true,
			cascade: true,
			onDelete: 'CASCADE'
		}
	)
	brandAllocations?: BrandAllocation[];
	@Column('decimal', { nullable: false })
	amountFromPlan: number;
	@Column('decimal', { nullable: false })
	amountPlanned: number;
	@Column('text', { nullable: true })
	tacticId: string;
	@ManyToOne(
		type => Tactic,
		tactic => tactic.costs,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'tacticId' })
	tactic: Tactic;
	@Column('boolean', { default: false, nullable: false })
	deleted: boolean;
	@Column('text', { nullable: false })
	authorId: string;
	@ManyToOne(type => User, {
		eager: true,
		onDelete: 'CASCADE'
	})
	author: User;
	@Column({ type: 'timestamptz', nullable: false, default: () => 'NOW()' })
	created: string;
	@OneToMany(
		() => BudgetDistributionGroup,
		budgetDistributionGroup => budgetDistributionGroup.cost,
		{
			nullable: true
		}
	)
	budgetDistributionGroups?: BudgetDistributionGroup[];
	// element, but it is not visible in the list of costs in the Tactic Cost tab.
	@Column('boolean', { nullable: true })
	hidden?: boolean;

	// "hidden" is set to true when we select a Cost Type in Planned Offers section in Tactic Cost tab. It has the same role as "regular" cost

	constructor(value?: Partial<Cost>) {
		if (value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for (const k in value) {
			this[k] = value[k];
		}
	}

	public get isDistributed(): boolean {
		if (this.brandAllocations?.length) {
			for (const ba of this.brandAllocations) {
				if (ba.budgetDistributions?.length) {
					return true;
				}
			}
		}
		return false;
	}

	public set isDistributed(value) {}

	public toPublic(): PublicCost {
		const pub: Partial<PublicCost> = {
			id: this.id,
			tacticId: this.tacticId,
			created: this.created,
			isDistributed: false,
			hidden: this.hidden
		};

		if (this.costType) {
			pub.costType = new CostType(this.costType).toPublic();
		}

		if (this.brandAllocations?.length) {
			pub.brandAllocations = (this.brandAllocations as BrandAllocation[]).map(a => new BrandAllocation(a).toPublic());
			pub.isDistributed = this.isDistributed;
		}

		if (typeof this.amountFromPlan !== 'undefined' && this.amountFromPlan !== null) {
			pub.amountFromPlan = Number(this.amountFromPlan);
		}

		if (typeof this.amountPlanned !== 'undefined' && this.amountPlanned !== null) {
			pub.amountPlanned = Number(this.amountPlanned);
		}

		if (this.budgetDistributionGroups?.length) {
			pub.budgetDistributionGroups = (this.budgetDistributionGroups as BudgetDistributionGroup[])?.map(g =>
				new BudgetDistributionGroup(g).toPublic()
			);
		}

		if (this.tactic) {
			pub.tactic = new Tactic(this.tactic).toPublic();
		}

		if (this.author) {
			pub.author = new User(this.author).toPublic();
		}

		return pub as PublicCost;
	}
}
