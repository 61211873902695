import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivateOverviewParams, MediaPlanOverviewParams, PlanOverviewParams } from './state/overview/overview.model';

const routes: Routes = [
	// Main Pages
	{ path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
	{
		path: 'sso/okta/:orgId/login',
		loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
		data: {
			oktaCallback: true
		}
	},
	{
		path: 'sso/server-to-server/:orgId/login/:userId',
		loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
		data: {
			serverToServerCallback: true
		}
	},
	{
		path: 'sso/azure/:orgId/login/:userId',
		loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
		data: {
			azureCallback: true
		}
	},

	{ path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule) },
	{ path: 'home', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule) },

	{ path: 'admin', loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminPageModule) },
	{ path: 'admin/:id', loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminPageModule) },
	{ path: 'settings', loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule) },
	{ path: 'settings/:id', loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule) },

	// Overview sections
	{
		path: 'planning',
		loadChildren: () => import('./pages/overview/overview.module').then(m => m.OverviewPageModule),
		data: PlanOverviewParams
	},
	{
		path: 'activate',
		loadChildren: () => import('./pages/overview/overview.module').then(m => m.OverviewPageModule),
		data: ActivateOverviewParams
	},
	{
		path: 'media-planning',
		loadChildren: () => import('./pages/overview/overview.module').then(m => m.OverviewPageModule),
		data: MediaPlanOverviewParams
	},

	// Plan
	{
		path: 'plan/create',
		loadChildren: () => import('./pages/plan/plan.module').then(m => m.PlanPageModule),
		data: { create: true }
	},
	{ path: 'plan/:id/:section', loadChildren: () => import('./pages/plan/plan.module').then(m => m.PlanPageModule) },
	{ path: 'plan/:id', loadChildren: () => import('./pages/plan/plan.module').then(m => m.PlanPageModule) },

	{ path: 'reporting', loadChildren: () => import('./pages/reporting/reporting.module').then(m => m.ReportingPageModule) },

	// Program
	{
		path: 'program/create',
		loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule),
		data: { create: true }
	},
	// {
	// 	path: 'program/:id/snapshot',
	// 	loadChildren: () => import('./pages/program-snapshot/program-snapshot.module').then(m => m.ProgramSnapshotPageModule)
	// },
	{ path: 'program/:id/:section', loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule) },
	{ path: 'program/:id', loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule) },

	// Plan Programs
	{
		path: 'plan/:planId/program/create',
		loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule),
		data: { create: true, planning: true }
	},
	{
		path: 'plan/:planId/program/:id/:section',
		loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule),
		data: { planning: true }
	},
	{
		path: 'plan/:planId/program/:id',
		loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule),
		data: { planning: true }
	},

	// Tactic
	{
		path: 'program/:programId/tactic/create',
		loadChildren: () => import('./pages/tactic/tactic.module').then(m => m.TacticPageModule),
		data: { create: true }
	},
	{
		path: 'program/:programId/tactic/:id/:section',
		loadChildren: () => import('./pages/tactic/tactic.module').then(m => m.TacticPageModule)
	},
	{ path: 'program/:programId/tactic/:id', loadChildren: () => import('./pages/tactic/tactic.module').then(m => m.TacticPageModule) },

	// Media Plan
	{
		path: 'media-plan/:id/:section',
		loadChildren: () => import('./pages/media-plan/media-plan.module').then(m => m.MediaPlanModule),
		data: { section: 'media-plan' }
	},
	{
		path: 'media-plan/:id',
		loadChildren: () => import('./pages/media-plan/media-plan.module').then(m => m.MediaPlanModule),
		data: { section: 'media-plan' }
	},
	{
		path: 'media-plan/:programId/scenarios/:id',
		loadChildren: () => import('./pages/tactic-group/tactic-group.module').then(m => m.TacticGroupPageModule),
		data: { section: 'media-plan' }
	},
	{
		path: 'media-plan/:programId/scenarios/:id/:section',
		loadChildren: () => import('./pages/tactic-group/tactic-group.module').then(m => m.TacticGroupPageModule),
		data: { section: 'media-plan' }
	},

	{
		path: 'media-plan/:programId/scenarios/:targetGroupId/tactic/:id/:section',
		loadChildren: () => import('./pages/tactic/tactic.module').then(m => m.TacticPageModule),
		data: { appSection: 'media-plan' }
	},
	{
		path: 'media-plan/:programId/scenarios/:targetGroupId/tactic/:id',
		loadChildren: () => import('./pages/tactic/tactic.module').then(m => m.TacticPageModule),
		data: { appSection: 'media-plan' }
	},

	// Vendor
	{ path: 'vendor/:id/:section', loadChildren: () => import('./pages/vendor/vendor.module').then(m => m.VendorPageModule) },
	{ path: 'vendor/:id', loadChildren: () => import('./pages/vendor/vendor.module').then(m => m.VendorPageModule) },

	// Initiative
	{
		path: 'initiative/:id/:section',
		loadChildren: () => import('./pages/initiative/initiative.module').then(m => m.InitiativePageModule)
	},
	{ path: 'initiative/:id', loadChildren: () => import('./pages/initiative/initiative.module').then(m => m.InitiativePageModule) },

	// File Review
	{ path: 'file-review', loadChildren: () => import('./pages/file-review/file-review.module').then(m => m.FileReviewPageModule) },

	// Used as a temp solution to seed programs into the db
	{ path: 'seed-programs', loadChildren: () => import('./pages/seed-programs/seed-programs.module').then(m => m.SeedProgramsPageModule) },

	// Wildcards
	{ path: '', redirectTo: 'home', pathMatch: 'full' },
	{ path: '*', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled' })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
