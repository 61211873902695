import { PanelCategory, PanelContentModule } from "../../state/entities/panel/panel.model";

export const PanelTypes = [
	{
		id: 'frameURL',
		name: 'Iframe URL',
		steps: [PanelContentModule.Metadata]
	},
	{
		id: 'embedCode',
		name: 'Embed Code',
		steps: [PanelContentModule.Metadata, PanelContentModule.EmbedCode]
	},
	{
		id: 'file',
		name: 'File',
		steps: [PanelContentModule.Metadata, PanelContentModule.FilePicker]
	},
	{
		id: 'group',
		name: 'Panel Group',
		steps: [PanelContentModule.Metadata]
	}
	,{
		id: 'ccp',
		name: 'CCP Module',
		steps: [PanelContentModule.Metadata, PanelContentModule.CCP]
	}
];

/**
 * Panel category list of possible values.
 */
 export const PanelCategories: PanelCategory[] = [
	{
		id: undefined,
		name: 'All'
	},
	{
		name: 'Details',
		id: 'programDetailsTab'
	},
	{
		name: 'Budget',
		id: 'programBudgetTab'
	},
	{
		name: 'Reporting',
		id: 'programReportingTab'
	},
	{
		name: 'Objectives',
		id: 'programObjectivesTab'
	}
];
