export enum CostSelect {
	BrandAllocations = 'brandAllocations',
	CostType = 'costType',
	Author = 'author',
	BudgetDistributionGroups = 'budgetDistributionGroups'
}

export class Query {
	private static readonly COST_QUERY_ALIAS = 'co';

	public static getSelects(
		alias: string = this.COST_QUERY_ALIAS,
		targets: CostSelect[] = Object.values(CostSelect),
		asJsonSelect: boolean = false
	) {
		const selects: string[] = [];
		const json: boolean = asJsonSelect;

		selects.push(`
			${json ? `'id', ${alias}.id` : `${alias}.id`}
		`);
		selects.push(`
			${json ? `'amountPlanned', ${alias}."amountPlanned"` : `${alias}."amountPlanned"`}
		`);
		selects.push(`
			${json ? `'amountFromPlan', ${alias}."amountFromPlan"` : `${alias}."amountFromPlan"`}
		`);
		selects.push(`
			${json ? `'costTypeId', ${alias}."costTypeId"` : `${alias}."costTypeId"`}
		`);
		selects.push(`
			${json ? `'tacticId', ${alias}."tacticId"` : `${alias}."tacticId"`}
		`);
		selects.push(`
			${json ? `'authorId', ${alias}."authorId"` : `${alias}."authorId"`}
		`);
		selects.push(`
			${json ? `'created', ${alias}.created` : `${alias}.created`}
		`);
		selects.push(`
			${json ? `'deleted', ${alias}.deleted` : `${alias}.deleted`}
		`);
		selects.push(`
			${(json) ? `'hidden', ${alias}.hidden` : `${alias}.hidden`}
		`);

		if (targets.includes(CostSelect.BrandAllocations)) {
			if (json) {
				selects.push(`
					'brandAllocations', ${alias}_brandallocations."allocationsArr"
				`);
			} else {
				selects.push(`
					${alias}_brandallocations."allocationsArr" AS "brandAllocations"
				`);
			}
		}

		if (targets.includes(CostSelect.CostType)) {
			if (json) {
				selects.push(`
					'costType', ${alias}_costtype."costType"
				`);
			} else {
				selects.push(`
					${alias}_costtype."costType" AS "costType"
				`);
			}
		}

		if (targets.includes(CostSelect.Author)) {
			if (json) {
				selects.push(`
					'author', ${alias}_author.author
				`);
			} else {
				selects.push(`
					${alias}_author.author AS author
				`);
			}
		}

		if (targets.includes(CostSelect.BudgetDistributionGroups)) {
			if (json) {
				selects.push(`
					'budgetDistributionGroups', ${alias}_budgetdistributiongroups."distributionGroupsArr"
				`);
			} else {
				selects.push(`
					${alias}_budgetdistributiongroups."distributionGroupsArr" AS "budgetDistributionGroups"
				`);
			}
		}

		return selects.join(',');
	}

	public static getSubqueries(alias: string = this.COST_QUERY_ALIAS, targets: CostSelect[] = Object.values(CostSelect)) {
		if (!targets.length) {
			return '';
		}

		const subQueries: string[] = [];

		if (targets.includes(CostSelect.BrandAllocations)) {
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								'id', bra2.id,
								'brandId', brands2.id,
								'brand', JSON_BUILD_OBJECT (
									'id', brands2.id,
									'name', brands2.name
								),
								'split', bra2.split,
								'budgetDistributions', (
									SELECT
										JSON_AGG(bd2)
									FROM
										"budgetDistributions" AS bd2
									WHERE
										bd2."brandAllocationId" = bra2.id
								)
							)
						FROM
							"brandAllocations" AS bra2
						LEFT JOIN
							brands AS brands2
								ON
									brands2.id = bra2."brandId"
						WHERE
							bra2."costId" = ${alias}.id
						GROUP BY
							bra2.id,
							brands2.id
					) AS "allocationsArr"
				) AS ${alias}_brandallocations
			`);
		}

		if (targets.includes(CostSelect.CostType)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSON_BUILD_OBJECT (
								'id', ct2.id,
								'name', ct2.name
							)
						FROM
							"costTypes" AS ct2
						WHERE
							ct2.id = ${alias}."costTypeId"
					) AS "costType"
				) AS ${alias}_costtype
			`);
		}

		if (targets.includes(CostSelect.Author)) {
			subQueries.push(`
				LATERAL (
					SELECT
						JSON_BUILD_OBJECT (
							'id', u2.id,
							'email', u2."email",
							'profile', u2."profile"
						) AS "author"
					FROM
						"users" AS u2
					WHERE
						u2.id = ${alias}."authorId"
				) AS ${alias}_author
			`);
		}

		if (targets.includes(CostSelect.BudgetDistributionGroups)) {
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							ROW_TO_JSON(bdg2)
						FROM
							"budgetDistributionGroups" AS bdg2
						WHERE
							bdg2."costId" = ${alias}.id
					) AS "distributionGroupsArr"
				) AS ${alias}_budgetdistributiongroups
			`);
		}

		if (!subQueries.length) {
			return '';
		}

		return ',\n' + subQueries.join(',');
	}
}
