import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn, Unique } from 'typeorm';
import { Sortable } from '../_core/decorators/sortable.decorator';
import { BudgetPeriod, PublicBudgetPeriod } from './budget-period.entity';

export type PublicBudgetPeriodQuarter = Pick<BudgetPeriodQuarter, 'id' | 'start' | 'end'> & {
	budgetPeriod: PublicBudgetPeriod;
};

export enum QuarterEnum {
	Q1 = 'Q1',
	Q2 = 'Q2',
	Q3 = 'Q3',
	Q4 = 'Q4'
}

@Entity('budgetPeriodsQuarters')
@Unique(['budgetPeriodId', 'quarter'])
export class BudgetPeriodQuarter {
	constructor(value?: Partial<BudgetPeriodQuarter>) {
		if (value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column({
		type: 'enum',
		enum: QuarterEnum,
		nullable: true
	})
	quarter: QuarterEnum;

	@Column({ type: 'date', nullable: true })
	@Sortable
	start: Date | string;

	@Column({ type: 'date', nullable: true })
	@Sortable
	end: Date | string;

	@Column('text', { nullable: true })
	budgetPeriodId: string;
	@ManyToOne(
		() => BudgetPeriod,
		budgetPeriod => budgetPeriod.calendarLayers,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'budgetPeriodId' })
	budgetPeriod: BudgetPeriod;

	public toPublic(include: string[] = []): PublicBudgetPeriodQuarter {
		const pub: Partial<PublicBudgetPeriodQuarter> = {
			id: this.id,
			start: this.start,
			end: this.end
		};

		if (include.includes('budgetPeriod') && this.budgetPeriod) {
			pub.budgetPeriod = this.budgetPeriod.toPublic();
		}

		return pub as PublicBudgetPeriodQuarter;
	}
}
