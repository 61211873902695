import {
	Entity,
	PrimaryGeneratedColumn,
	Column,
	ManyToOne,
	JoinColumn,
	Index
} from 'typeorm';
import { Invoice, PublicInvoice } from '../invoice/invoice.entity';
import { Program, PublicProgram } from '../program/program.entity';
import { PublicTactic, Tactic } from '../tactic/tactic.entity';

export enum WarningCategory {
	AtRisk = 'atRisk',
	MissingInformation = 'missingInformation',
	NeedsUpdating = 'needsUpdating'
}

export enum WarningType {
	AtRisk = 'atRisk',
	MissingObjectives = 'missingObjectives',
	MissingTactics = 'missingTactics',
	PlannedCostExceeded = 'plannedCostExceeded',
	InvoiceMissingProMax = 'invoiceMissingProMax',
	ProgramBudgetExceeded = 'programBudgetExceeded',
	MissingBudgetAllocation = 'missingBudgetAllocation',
	ProgramBudgetLeftover = 'programBudgetLeftover'
}

export type PublicWarning = Pick<Warning,
	'id' | 'category' |
	'type' | 'description' |
	'dismissable' | 'created' |
	'programId' | 'tacticId' | 'invoiceId'
> & {
	program?: PublicProgram,
	tactic?: PublicTactic,
	invoice?: PublicInvoice
};

@Entity('warnings')
@Index(['programId', 'type'], {
	where: '("tacticId" IS NULL AND "invoiceId" IS NULL)',
	unique: true
})
@Index(['programId', 'tacticId', 'type'], {
	where: '("tacticId" IS NOT NULL AND "invoiceId" IS NULL)',
	unique: true
})
@Index(['programId', 'tacticId', 'invoiceId', 'type'], {
	where: '("tacticId" IS NOT NULL AND "invoiceId" IS NOT NULL)',
	unique: true
})
export class Warning {
	constructor(value?: Partial<Warning>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column({
		type: 'enum',
		enum: WarningCategory,
		nullable: false
	})
	category: WarningCategory;

	@Column({
		type: 'enum',
		enum: WarningType,
		nullable: false
	})
	type: WarningType;

	@Column('text', { nullable: false })
	description: string;

	@Column('text', { nullable: true })
	programId?: string;
	@ManyToOne(
		() => Program,
		{
			nullable: true,
			orphanedRowAction: 'delete',
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'programId' })
	program?: Program;

	@Column('text', { nullable: true })
	tacticId?: string;
	@ManyToOne(
		() => Tactic,
		{
			nullable: true,
			orphanedRowAction: 'delete',
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'tacticId' })
	tactic?: Tactic;

	@Column('text', { nullable: true })
	invoiceId?: string;
	@ManyToOne(
		() => Invoice,
		{
			nullable: true,
			orphanedRowAction: 'delete',
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'invoiceId' })
	invoice?: Invoice;

	resolved: boolean = false;

	@Column('boolean', { nullable: false, default: false })
	dismissable: boolean = false;

	@Column('boolean', { nullable: false, default: false })
	dismissed: boolean = false;

	@Column({ type: 'timestamptz', nullable: false, default: () => 'NOW()' })
	created: string;

	public toPublic(): PublicWarning {
		const pub: Partial<PublicWarning> = {
			id: this.id,
			category: this.category,
			type: this.type,
			description: this.description,
			dismissable: this.dismissable,
			programId: this.programId,
			tacticId: this.tacticId,
			invoiceId: this.invoiceId,
			created: this.created
		};

		if(this.program) {
			pub.program = new Program(this.program).toPublic();
		}

		if(this.tactic?.id) {
			pub.tactic = new Tactic(this.tactic).toPublic();
		}

		if(this.invoice?.id) {
			pub.invoice = new Invoice(this.invoice).toPublic();
		}

		return pub as PublicWarning;
	}
}
