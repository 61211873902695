import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { GlobalSettings } from '../../global/global.model';
import { GlobalQuery } from '../../global/global.query';
import { Filter, FilterListConfig, FilterParameters } from './filter.model';
import { FilterState, FilterStore } from './filter.store';
import { doesFilterMatchEndpoint } from './filter.utils';
import { Validation } from '../../../../../../api/src/_core/utils';
import { FormGroup } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { sortBy, uniqBy } from 'lodash';
import { LogicalConjunction } from '../../../../../../api/src/_core/models/math-operations';

@Injectable({ providedIn: 'root' })
export class FilterQuery extends QueryEntity<FilterState> {
	constructor(protected store: FilterStore, private readonly globalQuery: GlobalQuery) {
		super(store);
	}

	selectFiltersWhenFormChanges(form: FormGroup) {
		return combineLatest([form.valueChanges, this.selectActive()]).pipe(
			// Make sure this procs the first time and load in some filters
			startWith([form.value, this.getActive()]),
			filter(([formValue, filters]) => formValue.include && filters),
			// Filter out filters that don't match the endpoint or
			// have visibility conditions
			map(([formValue, filters]) =>
				filters.filter(
					f =>
						doesFilterMatchEndpoint(f, formValue?.include?.value?.endpoint) &&
						this.doesFilterPassVisibilityCondition(f, this.globalQuery.getValue().settings, formValue)
				)
			),
			map(filters => sortBy(filters, ['order']))
		);
	}

	/**
	 * Return the filters that currently 'active' and have 'matchEndpoint' value that matches the input
	 */
	getActiveFilterBySlug(slug: string, matchEndpoint?: string) {
		let activeIds = this.getValue().active;

		return this.getAll().find(filter => {
			if (matchEndpoint && filter.matchEndpoint !== matchEndpoint) {
				return false;
			}

			return filter.slug === slug && activeIds.includes(filter.id);
		});
	}

	getFilterById(id: string) {
		return this.getAll().find(filter => filter.id === id);
	}

	getFiltersById(ids: string[]) {
		return ids.map(id => this.getFilterById(id));
	}

	getFiltersBySlug(slugs: string[]) {
		return this.getAll().filter(filter => slugs.includes(filter.slug));
	}

	getFiltersByCategory(categories: string[]) {
		return this.getAll().filter(filter => categories.includes(filter.category));
	}

	getFiltersByEndpoint(endpoint: string) {
		return this.getAll().filter(filter => doesFilterMatchEndpoint(filter, endpoint));
	}

	getFiltersFilteredByListConfig(config: FilterListConfig, keepInclude?: boolean): Filter[] {
		let filters: Filter[] = [];

		// Retrieve the proper columns
		if (config?.includeEndpoint) {
			filters = filters.concat(this.getFiltersByEndpoint(config.includeEndpoint));
		}

		if (config?.includeIds) {
			filters = filters.concat(this.getFiltersById(config.includeIds));
		}

		// Exclude any columns suggested.
		if (config?.excludeIds) {
			filters = filters.filter(filter => !config.excludeIds.includes(filter.id));
		}

		// Get rid of includes
		// Get rid of includes and any filters that don't pass the visibility condition.
		if (!keepInclude) {
			filters = filters.filter(filter => filter && filter.slug !== 'include');
		}

		return sortBy(uniqBy(filters, 'id'), ['order']);
	}

	doesFilterPassVisibilityCondition(filter: Filter, settings: GlobalSettings, filterParameters?: FilterParameters): boolean {
		// Case 1: No visibility condition provided
		if (!filter.visibilityCondition && !filter.visibilityConditions) {
			return true;
		}

		// Case 2: Multiple visibility conditions with filter parameters
		if (filterParameters && filter.visibilityConditions) {
			const conditionResult = filter.visibilityConditions.filterConditions.map(visibilityCondition =>
				Validation.validateConditionOnObject(filterParameters, visibilityCondition)
			);

			if (filter.visibilityConditions.operator === LogicalConjunction.AND) {
				return conditionResult.every(value => value === true);
			} else {
				return conditionResult.includes(true);
			}
		}

		// Case 3: Multiple visibility conditions without filter parameters, fall back to settings
		if (!filterParameters && filter.visibilityConditions) {
			const conditionResult = filter.visibilityConditions.filterConditions.map(visibilityCondition =>
				Validation.validateConditionOnObject(settings, visibilityCondition)
			);

			if (filter.visibilityConditions.operator === LogicalConjunction.AND) {
				return conditionResult.every(value => value === true);
			} else {
				return conditionResult.includes(true);
			}
		}

		// Case 4: Single visibility condition with filter parameters
		if (filterParameters && filter.visibilityCondition) {
			return Validation.validateConditionOnObject(filterParameters, filter.visibilityCondition);
		}

		// Case 5: Single visibility condition without filter parameters, fall back to settings
		if (!filterParameters && filter.visibilityCondition) {
			return Validation.validateConditionOnObject(settings, filter.visibilityCondition);
		}

		// Default return false if none of the conditions are met
		return false;
	}
}
