export enum Condition {
	GT = 'gt', // >
	GTE = 'gte', // >=
	LT = 'lt', // <
	LTE = 'lte', // <=
	EQ = 'eq', // ===
	NEQ = 'neq' // !==
}

export enum AggregateFunction {
	Join = 'join', // Join entries with ','
	Count = 'count', // Count number of entries
	Sum = 'sum', // Add all entries
	Mean = 'mean', // Average entries
	MeanWeighted = 'mean-weighted', // Calculate weighted average (weight defined statically)
	Median = 'median', // Get the median entry value
	Mode = 'mode', // Get the most frequent entry value
	First = 'first' // Get the first entry value
}
export enum LogicalConjunction {
	OR = 'or',
	AND = 'and'
}
