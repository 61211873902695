
// we have 3 statuses:
// - Pending Brief (program utilization = 0% or null)
// - In Progress (program utilization = 1-99%)
// - Complete (program utilization = 100%)
export enum KimberlyClarkProgramStatusEnum {
	PendingBrief = 'Pending Brief',
	InProgress = 'In Progress',
	Complete = 'Complete'
}
