import { Column, Entity } from 'typeorm';
import { Permission, PermissionLevel, PermissionType } from './permission.entity'

export type EntityMap = {
	[key in PermissionType]?: string[];
};

export type PublicEntityPermission = Pick<
	EntityPermission,
	'id' | 'permissionLevel' | 'entityMap'
> & {

};

@Entity('entityPermissions')
export class EntityPermission extends Permission {
	constructor(value?: Partial<EntityPermission>) {
		super(value);
		for(const k in value) {
			this[k] = value[k];
		}
	}

	// @Column({
	// 	type: 'enum',
	// 	enum: PermissionType
	// })
	// permissionType: PermissionType;

	@Column('jsonb', { nullable: false })
	entityMap: EntityMap;

	public toPublic(): PublicEntityPermission {
		const pub: Partial<PublicEntityPermission> = {
			id: this.id,
			// permissionType: this.permissionType,
			permissionLevel: this.permissionLevel,
			entityMap: this.entityMap
		};

		return pub as PublicEntityPermission;
	}
}