import { BudgetCache, FundingSource, Retailer } from '../../global/global.model';
import { Entity } from '../entities.model';
import { Note } from '../note/note.model';
import { Offer, PlannedOffersData } from '../offer/offer.model';
import { Category, Program } from '../program/program.model';
import { v4 as uuidv4 } from 'uuid';
import { Invoice } from '../invoice/invoice.model';
import { Cost } from '../cost/cost.model';
import { Tag } from '../tag/tag.model';
import { Vendor } from '../vendor/vendor.model';
import { ColumnCollection, SortStrategy } from '../../table/table.model';
import { Measurement } from '../measurement/measurement.model';
import { Filter, FilterGroupType } from '../filter/filter.model';
import { Brand } from '../brand/brand.model';
import { Location } from '../location/location.model';
import { ExternalId } from '../external-id/external-id.model';
import { TacticSelect } from '../../../../../../api/src/tactic/utils/query.utils';
import { MediaType } from '../../../../../../api/src/base-tactic-type/base-tactic-type.entity';
import { CacheResultItem } from '../../../../../../api/src/budget-cache/models/budget-cache.models';
import { Milestones } from '../milestone/milestones.model';
import { AggregateFunction, Condition, LogicalConjunction } from '../../../../../../api/src/_core/models/math-operations';
import { PublicBudgetPeriod } from '../../../../../../api/src/budget-period/budget-period.entity';
import { TacticGroup, TacticGroupStatus } from '../../../../../../api/src/tactic-group/tactic-group.entity';
import { capitalize } from '../../../_core/utils/string.utils';
import { PublicTacticAgency } from '../../../../../../api/src/tactic-agency/tactic-agency.entity';
import { PercentageOfSpendCompleteColumn } from '../../../../../../api/src/organization/organization.settings';

/**
 * Tactic model
 * A tactic is an entity that describes a particular execution of a program.
 *
 * Many Tactics can be nested inside a program
 * Tactics track both estimated costs and actual spend view Invoices
 * Tactics have types that describe the work that is being done.
 * Tactics are typically tied to a vendor who is doing the work.
 * Tactics can be measured with Measurements that reflect the summary performance of the tactic
 */
export interface Tactic {
	id: string;
	programId?: Program['id'];
	program?: Program;
	budgetPeriod?: PublicBudgetPeriod;
	PublicBudgetPeriodId?: PublicBudgetPeriod['id'];
	budgetCache?: BudgetCache;
	brandCaches?: CacheResultItem[];
	destinationURL?: string;
	retailer: Retailer;
	retailerId: Retailer['id'];
	tacticPhase: TacticPhase;
	tacticType: TacticType;
	tacticTypeId: string;
	tacticGroupId: string;
	tacticGroup: TacticGroup;
	name?: string;
	detail?: string;
	category: TacticCategory;
	status: TacticStatus;
	measurements: Measurement[];
	vendors: Vendor[];
	brands?: Brand[];
	start: string;
	end: string;
	lastCouponExpiration?: string;
	tags?: Tag[];
	fundingSource: FundingSource;
	proMax?: string;
	retailMediaPO?: string;
	costs?: Cost[];
	invoices?: Invoice[];
	offers?: Offer[];
	externalIds?: ExternalId[];
	notes?: Note[];
	milestones?: Milestones[];
	type: string;
	files?: File[];
	rmn: string;
	dueDate: string;
	nextSteps: string;
	buySpecs: string;
	flowchart: string;
	landingPage: string;
	location: Location;
	plannedOffersData?: PlannedOffersData;
	tacticAgency?: PublicTacticAgency;
}

/**
 * Tactics are tied to a type.  There can be many types underneath a TacticCategory.
 * Tactic types are configured in the CMS.
 */
export interface TacticType extends Entity {
	tacticCategoryId?: string;
	mediaType?: MediaType;
	tacticCategory?: TacticCategory;
}

/**
 * Tactic types are grouped into a tactic category.
 * Tactic categories are configured in the CMS.
 */
export interface TacticCategory extends Entity {}

/**
 * Tactic Phases are workflow phases (eg. In Progress, Completed).
 * Tactic Phases are configured via the CMS.
 */
export interface TacticPhase extends Entity {
	order?: number;
}

/**
 * Tactic status represent the editability of a Tactic.
 * They are not currently being used by the system.
 */
export type TacticStatus = 'draft' | 'published' | 'approved';

/**
 * Create a blank tactic.  Put any default values here.
 */
export function createTactic(tactic: Partial<Tactic>, program: Program): Tactic {
	if (program) {
		tactic = {
			id: uuidv4(),
			program: program as Program,
			budgetPeriod: program.budgetPeriod,
			retailer: program.retailer,
			start: program.start,
			end: program.end,
			brands: program.brands,
			...tactic,
		};

		if (program.budgetAllocations && program.budgetAllocations[0]) {
			tactic.fundingSource = program.budgetAllocations[0]?.fundingSource;
		}
	}

	// console.log('Creating Tactic', tactic);

	return tactic as Tactic;
}

/**
 * A tactic section represents a UI section / tab in the tactic page
 * Tactic sections are stactically build below.
 */
export interface TacticSection {
	label: string;
	slug: string;
}

/**
 * The sections for a tactic page.
 */
export const TacticSections: TacticSection[] = [
	{
		label: 'Program Details',
		slug: 'details',
	},
	{
		label: 'Budget',
		slug: 'budget',
	},
	{
		label: 'Invoices',
		slug: 'invoices',
	},
	{
		label: 'Reporting',
		slug: 'reporting',
	},
	{
		label: 'Activity',
		slug: 'activity',
	},
	{
		label: 'Milestones',
		slug: 'milestones',
	},
];

/**
 * The columns that are unique to tactics.
 */
export const TacticColumnCollection: ColumnCollection = {
	id: 'tactics',
	name: 'Tactics',
	items: [
		{
			id: 'program-name',
			name: 'Program',
			path: 'program',
			exportPath: 'program.name',
			type: 'entityName',
			category: 'Tactic',
			entityTypes: ['tactic'],
			dependencies: [TacticSelect.Program],
			visibilityCondition: {
				path: 'form.include.value.endpoint',
				condition: Condition.EQ,
				value: 'tactics',
			},
			sortable: false,
			hideFromMenu: true,
		},
		{
			id: 'tactic-type',
			name: 'Tactic Type',
			path: 'tacticType',
			exportPath: 'tacticType.name',
			type: 'entityName',
			category: 'Tactic',
			entityTypes: ['tactic'],
			dependencies: [TacticSelect.TacticType],
			editable: {
				type: 'quick-edit-dialog',
				enabled: true,
			},
			sortable: false,
		},
		{
			id: 'tactic-category',
			name: 'Tactic Category',
			path: 'tacticType.tacticCategory',
			exportPath: 'tacticType.tacticCategory.name',
			type: 'entityName',
			category: 'Tactic',
			entityTypes: ['tactic'],
			dependencies: [TacticSelect.TacticType],
			editable: {
				type: 'quick-edit-dialog',
				enabled: true,
				idAlias: 'tactic-type',
			},
			sortable: false,
		},
		{
			id: 'tactic-phase',
			name: 'Tactic Phase',
			path: 'tacticPhase',
			exportPath: 'tacticPhase.name',
			type: 'badge',
			category: 'Tactic',
			entityTypes: ['tactic'],
			dependencies: [TacticSelect.TacticPhase],
			editable: {
				type: 'single-select',
				optionsFromSettings: 'tacticPhases',
				enabled: true,
				uneditableReason: 'Tactic Phase is not editable.',
			},
			extra: {
				settingsEntity: 'tacticPhases',
			},
			sortable: false,
		},
		{
			id: 'vendors',
			name: 'Vendor(s)',
			path: 'vendors',
			exportPath: 'vendors.name',
			type: 'entityNames',
			category: 'Tactic',
			entityTypes: ['tactic'],
			dependencies: [TacticSelect.Vendors],
			editable: {
				type: 'multi-select',
				filterId: 'tactic-vendors',
				enabled: true,
				uneditableReason: 'Vendors are not editable.',
			},
			extra: {
				settingsEntity: 'vendors',
				maskPath: 'tactic.vendors.mask',
			},
			visibilityCondition: {
				path: 'settings.entities.tactic.vendors.disabled',
				condition: Condition.NEQ,
				value: true,
			},
			sortable: false,
		},
		{
			id: 'tactic-country',
			name: 'Tactic Location',
			path: 'location.name',
			type: 'field',
			category: 'Tactic',
			dependencies: [TacticSelect.Location],
			entityTypes: ['tactic'],
			extra: {
				maskPath: 'tactic.location.mask',
			},
			visibilityConditions: {
				operator: LogicalConjunction.AND,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'activation',
					},
					{
						path: 'settings.entities.tactic.location.disabled',
						condition: Condition.NEQ,
						value: true,
					},
				],
			},
			sortable: false,
		},
		{
			id: 'agency',
			name: 'Tactic Agency',
			path: 'agency',
			exportPath: 'agency.name',
			type: 'entityName',
			category: 'Tactic',
			entityTypes: ['tactic'],
			dependencies: [TacticSelect.TacticAgency],
			editable: {
				type: 'single-select',
				optionsFromSettings: 'agencies',
				enabled: true,
			},
			extra: {
				maskPath: 'tactic.tacticAgency.mask',
			},
			hideFromMenu: false,
			sortable: false,
			visibilityConditions: {
				operator: LogicalConjunction.AND,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'activation',
					},
					{
						path: 'settings.entities.tactic.tacticAgency.disabled',
						condition: Condition.NEQ,
						value: true,
					},
				],
			},
		},
		{
			id: 'rmn',
			name: 'RMN',
			path: 'rmn',
			type: 'field',
			category: 'Tactic',
			entityTypes: ['tactic'],
			editable: {
				type: 'toggle',
				enabled: true,
			},
			visibilityCondition: {
				path: 'settings.entities.tactic.rmn.disabled',
				condition: Condition.NEQ,
				value: true,
			},
			extra: {
				maskPath: 'tactic.rmn.mask',
			},
		},
		/* {
			id: 'media-type',
			name: 'Media Type',
			path: 'tacticType.mediaType',
			exportPath: 'tacticType.mediaType',
			type: 'entityName',
			category: 'Tactic',
			entityTypes: ['tactic'],
			dependencies: [TacticSelect.TacticType]
		}, */
		{
			id: 'tactic-funding-source',
			name: 'Tactic Funding Source',
			path: 'fundingSource',
			exportPath: 'fundingSource.name',
			type: 'badge',
			category: 'Tactic',
			entityTypes: ['tactic'],
			dependencies: [TacticSelect.FundingSource],
			editable: {
				type: 'single-select',
				optionsFromSettings: 'fundingSources',
				enabled: true,
				uneditableReason: 'Tactic Funding Source is not editable.',
			},
			sortable: false,
		},
		/*{
			id: 'vendors',
			name: 'Vendor(s)',
			path: 'vendors',
			exportPath: 'vendors.name',
			type: 'entityNames',
			category: 'Tactic',
			entityTypes: ['tactic'],
			dependencies: [TacticSelect.Vendors],
			editable: {
				type: 'multi-select',
				filterId: 'tactic-vendors',
				enabled: true,
				uneditableReason: 'Vendors are not editable.',
			},
			extra: {
				maskPath: 'tactic.vendors.mask',
			},
		},*/
		{
			id: 'last-coupon-expiration',
			name: 'Last Coupon Expiration',
			path: 'lastCouponExpiration',
			type: 'date',
			category: 'Tactic',
			entityTypes: ['tactic'],
			editable: {
				type: 'date',
				enabled: true,
				uneditableReason: 'Last Coupon Expiration is not editable.',
			},
			extra: {
				maskPath: 'tactic.lastCouponExpiration.mask',
			},
			visibilityConditions: {
				operator: LogicalConjunction.AND,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'media-plan',
					},
					{
						path: 'settings.entities.tactic.lastCouponExpiration.disabled',
						condition: Condition.NEQ,
						value: true,
					},
				],
			},
		},
		{
			id: 'costs',
			name: 'Costs',
			path: 'costs',
			type: 'pluckFromArray',
			category: 'Budgets',
			entityTypes: ['tactic'],
			hideFromMenu: true,
			dependencies: [TacticSelect.Costs],
			aggregate: {
				path: 'amountActual',
				function: AggregateFunction.Sum,
			},
		},
		{
			id: 'tactic-cost-types',
			name: 'Tactic Cost Types',
			path: 'costs',
			exportPath: 'costs.costTypes.name',
			type: 'entityNames',
			category: 'Tactic',
			entityTypes: ['tactic'],
			dependencies: [TacticSelect.Costs],
			extra: {
				mapToProperty: 'costType',
			},
			sortable: false,
		},
		{
			id: 'tactic-dueDate',
			name: 'Tactic Due Date',
			path: 'dueDate',
			type: 'date',
			category: 'Tactic',
			entityTypes: ['tactic'],
			editable: {
				type: 'date',
				enabled: true,
			},
			visibilityCondition: {
				path: 'settings.entities.tactic.dueDate.disabled',
				condition: Condition.NEQ,
				value: true,
			},
			extra: {
				maskPath: 'tactic.dueDate.mask',
			},
		},
		{
			id: 'nextSteps',
			name: 'Next Steps',
			path: 'nextSteps',
			type: 'field',
			category: 'Tactic',
			entityTypes: ['tactic'],
			extra: {
				tooltip: true,
				stripHtml: true,
				maskPath: 'tactic.nextSteps.mask',
			},
			editable: {
				type: 'textarea',
				enabled: true,
			},
			visibilityCondition: {
				path: 'settings.entities.tactic.nextSteps.disabled',
				condition: Condition.NEQ,
				value: true,
			},
		},
		{
			id: 'buySpecs',
			name: 'Buy Specs',
			path: 'buySpecs',
			type: 'field',
			category: 'Tactic',
			entityTypes: ['tactic'],
			extra: {
				tooltip: true,
				stripHtml: true,
				maskPath: 'tactic.buySpecs.mask',
			},
			editable: {
				type: 'textarea',
				enabled: true,
			},
			visibilityCondition: {
				path: 'settings.entities.tactic.buySpecs.disabled',
				condition: Condition.NEQ,
				value: true,
			},
		},
		{
			id: 'landingPage',
			name: 'Landing Page',
			path: 'landingPage',
			type: 'field',
			category: 'Tactic',
			entityTypes: ['tactic'],
			extra: {
				tooltip: true,
				stripHtml: true,
				maskPath: 'tactic.landingPage.mask',
			},
			editable: {
				type: 'textarea',
				enabled: true,
			},
			visibilityCondition: {
				path: 'settings.entities.tactic.landingPage.disabled',
				condition: Condition.NEQ,
				value: true,
			},
		},
		{
			id: 'flowchart',
			name: 'Flow Chart',
			path: 'flowchart',
			type: 'field',
			category: 'Tactic',
			entityTypes: ['tactic'],
			extra: {
				tooltip: true,
				stripHtml: true,
				maskPath: 'tactic.flowchart.mask',
			},
			editable: {
				type: 'textarea',
				enabled: true,
			},
			visibilityCondition: {
				path: 'settings.entities.tactic.flowchart.disabled',
				condition: Condition.NEQ,
				value: true,
			},
		},
		{
			id: 'all-external-ids',
			name: 'All External Ids',
			path: 'externalIds',
			exportPath: 'externalIds',
			type: 'field',
			category: 'External Ids',
			entityTypes: ['tactic'],
			sortStrategy: SortStrategy.ASC,
			dependencies: [TacticSelect.ExternalIds],
			hideFromMenu: true,
			extra: {
				maskPath: 'program.externalIdentifier.mask',
			},
		},
		{
			id: 'all-measurements',
			name: 'All Measurements',
			path: 'measurements',
			exportPath: 'measurements',
			type: 'field',
			category: 'Measurement',
			entityTypes: ['tactic'],
			sortStrategy: SortStrategy.ASC,
			dependencies: [TacticSelect.Measurements],
			extra: {
				alternativeName: 'All Measurements',
			},
			hideFromMenu: true,
		},
		{
			id: 'last-coupon-expiration',
			name: 'Last Coupon Expiration',
			path: 'lastCouponExpiration',
			type: 'date',
			category: 'Tactic',
			entityTypes: ['tactic'],
			editable: {
				type: 'date',
				enabled: true,
				uneditableReason: 'Last Coupon Expiration is not editable.',
			},
			extra: {
				maskPath: 'tactic.lastCouponExpiration.mask',
			},
			hideFromMenu: true,
		},
		/* {
			id: 'costs',
			name: 'Costs',
			path: 'costs',
			type: 'pluckFromArray',
			category: 'Budgets',
			entityTypes: ['tactic'],
			hideFromMenu: true,
			dependencies: [TacticSelect.Costs],
			aggregate: {
				path: 'amountActual',
				function: AggregateFunction.Sum
			}
		}, */
		{
			id: 'add-invoice',
			name: 'Create Invoice',
			path: 'createInvoice',
			type: 'button',
			category: 'Tactic',
			entityTypes: ['tactic'],
			editable: {
				enabled: false,
				hideSnackBar: true,
			},
			button: {
				action: 'createEntity',
				actionParams: 'invoice',
				type: 'mat-mini-fab',
				icon: 'uil-plus',
				size: 'extra-small',
				color: 'tertiary',
				tooltip: 'Create Invoice',
			},
			extra: {
				width: 80,
				//hideHeaderLabel: true
			},
			hideFromMenu: true,
		},
		{
			id: 'estimated-spend',
			name: 'Estimated Tactic Spend',
			path: 'budgetCache.spendEstimated',
			exportPath: 'budgetCache.spendEstimated',
			type: 'budgetCacheValue',
			category: 'Budgets',
			entityTypes: ['tactic'],
			dependencies: [TacticSelect.BudgetCache],
			extra: {
				showDifferenceWith: 'amountActual',
				invertDifference: true,
				width: 230,
			},
			editable: {
				type: 'quick-edit-dialog',
				enabled: true,
				dismissWithUpdatedEntity: true,
				modalSize: 'lg',
			},
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'activation',
			},
		},
		{
			id: 'actual-spend',
			name: 'Actual Spend (Invoiced)',
			path: 'budgetCache.spendActual',
			exportPath: 'budgetCache.spendActual',
			type: 'budgetCacheValue',
			category: 'Budgets',
			entityTypes: ['tactic'],
			dependencies: [TacticSelect.BudgetCache],
			extra: {
				showDifferenceWith: 'spendEstimated',
				invertDifference: true,
				width: 230,
			},
			editable: {
				type: 'quick-edit-dialog',
				enabled: true,
				dismissWithUpdatedEntity: true,
				modalSize: 'lg',
				rowTypeOverrides: [
					{
						rowType: 'invoice',
						type: 'text',
					},
				],
			},
			visibilityConditions: {
				operator: LogicalConjunction.OR,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'activation',
					},
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'media-plan',
					},
				],
			},
		},
		{
			id: 'percentage-of-tactic-spend-complete',
			name: '% of Tactic Spend Complete',
			path: 'percentTacticSpendComplete',
			type: 'percentSpentComplete',
			entityTypes: ['tactic'],
			category: 'Budgets',
			visibilityConditions: {
				operator: LogicalConjunction.AND,
				filterConditions: [
					{
						path: 'settings.percentageOfSpendCompleteColumn',
						condition: Condition.NEQ,
						value: 'off' as PercentageOfSpendCompleteColumn,
					},
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'activation',
					},
				],
			},
			sortable: false,
		},
	],
};

/**
 * The default active columns that should show up when we're viewing tactics in a table.
 */
export const TacticDefaultActiveColumns = [
	'Name',
	'Program',
	'Start Date',
	'End Date',
	'Estimated Spend',
	'Actual Spend',
	'shim',
	'action',
];

/**
 * The filters that are unique to tactics
 */
export const TacticFilterCollection: Filter[] = [
	{
		id: 'tactic-group-by',
		name: 'Grouped By',
		category: 'overall',
		matchEndpoint: 'tactics',
		slug: 'groups',
		type: 'single-select',
		order: 1,
		options: [
			{
				id: 'none',
				name: '(None)',
				value: undefined,
			},
			{
				id: 'retailers',
				name: 'Retailers',
				entityName: 'Retailer',
				value: 'retailers',
				extra: {
					maskPath: 'program.retailer.mask',
				},
			},
			{
				id: 'brands',
				name: 'Brands',
				entityName: 'Brand',
				value: 'brands',
				extra: {
					maskPath: 'program.brand.mask',
				},
			},
			{
				id: 'programs',
				name: 'Programs',
				entityName: 'Program',
				value: 'programs',
			},
			{
				id: 'tacticTypes',
				name: 'Tactic Types',
				entityName: 'TacticType',
				value: 'tacticTypes',
			},
			{
				id: 'tacticCategories',
				name: 'Tactic Category',
				entityName: 'TacticCategory',
				value: 'tacticCategories',
			},
			/* {
				id: 'mediaType',
				name: 'Media Type',
				entityName: 'MediaType',
				value: 'mediaType'
			}, */
			{
				id: 'tacticPhases',
				name: 'Tactic Phase',
				entityName: 'TacticPhase',
				value: 'tacticPhases',
			},
			{
				id: 'vendors',
				name: 'Vendors',
				entityName: 'Vendor',
				value: 'vendors',
				visibilityCondition: {
					path: 'settings.entities.tactic.vendors.disabled',
					condition: Condition.NEQ,
					value: true,
				},
				extra: {
					maskPath: 'tactic.vendors.mask',
				},
			},
			{
				id: 'tags',
				name: 'Tags',
				entityName: 'Tag',
				value: 'tags',
			},
			{
				id: 'tacticGroups',
				name: 'Tactic Group',
				entityName: 'TacticGroup',
				value: 'tacticGroups',
			},
		],
		extra: {
			buttonSelect: {
				buttonText: 'Group By ${formGroup.groups.name}',
				buttonTextMask: 'formGroup.groups.extra.maskPath',
				emptyText: 'Group By',
			},
			iconName: 'uil-layer-group',
		},
	},
	{
		id: 'tactic-name',
		name: 'Tactic Name',
		category: 'overall',
		matchEndpoint: 'tactics',
		slug: 'name',
		type: 'search',
		order: 2,
		extra: { placeholder: 'Search by Tactic Name' },
	},
	{
		id: 'tactic-types',
		category: 'tactics',
		name: 'Tactic Type(s)',
		entityName: 'TacticType',
		slug: 'tacticTypes',
		type: 'multi-select',
		order: 4.7,
		options: [],
	},
	{
		id: 'tactic-type',
		category: 'tactics',
		name: 'Tactic Type',
		entityName: 'TacticType',
		groupTypes: [FilterGroupType.tactic],
		matchEndpoint: 'tactics',
		slug: 'tacticType',
		type: 'single-select-with-groups',
		options: [],
		extra: {
			buttonSelect: {
				emptyText: 'Tactic Type',
			},
			optionsFromSettings: {
				path: 'tacticTypeGroupByCategory',
			},
		},
		order: 2,
	},
	{
		id: 'tactic-category',
		name: 'Tactic Category',
		category: 'tactics',
		groupTypes: [FilterGroupType.tactic],
		matchEndpoint: 'tactics',
		slug: 'tacticCategory',
		type: 'single-select',
		options: [],
		extra: {
			buttonSelect: {
				emptyText: 'Tactic Category',
			},
		},
		order: 1,
	},
	{
		id: 'tactic-group-status',
		name: 'Tactic Group Status',
		category: 'tactics',
		matchEndpoint: 'tactics',
		slug: 'tacticGroupStatuses',
		type: 'multi-select',
		options: [
			{ id: TacticGroupStatus.Draft, name: capitalize(TacticGroupStatus.Draft) },
			{ id: TacticGroupStatus.Approved, name: capitalize(TacticGroupStatus.Approved) },
		],
		extra: {
			buttonSelect: { buttonText: 'Scenario status' },
		},
	},
	{
		id: 'cost-types',
		name: 'Has Cost Type(s)',
		extra: { placeholder: 'Add Cost Types' },
		category: 'tactics',
		matchEndpoint: 'tactics',
		slug: 'costTypes',
		type: 'multi-select',
		options: [],
	},
	/* 	{
		id: 'media-type',
		name: 'Media Type',
		category: 'tactics',
		matchEndpoint: 'tactics',
		slug: 'mediaType',
		type: 'single-select',
		options: []
	}, */
	{
		id: 'tactic-phase',
		name: 'Tactic Phase',
		category: 'tactics',
		groupTypes: [FilterGroupType.tactic],
		matchEndpoint: 'tactics',
		slug: 'tacticPhase',
		type: 'single-select',
		options: [],
		extra: {
			buttonSelect: {
				emptyText: 'Tactic Phase',
			},
			iconName: 'uil-clock',
		},
		order: 3,
	},
	{
		id: 'tactic-funding-source',
		name: 'Funding Source(s)',
		category: 'tactics',
		matchEndpoint: 'tactics',
		slug: 'fundingSources',
		type: 'multi-select',
		options: [],
	},
	{
		id: 'tactic-vendors',
		name: 'Vendors',
		category: 'tactics',
		groupTypes: [FilterGroupType.tactic],
		matchEndpoint: 'tactics',
		slug: 'vendors',
		type: 'multi-select',
		extra: {
			suggestEntity: 'vendor',
			maskPath: 'tactic.vendors.mask',
		},
	},
	{
		id: 'tactic-created-by-me',
		name: 'Created by Me',
		category: 'tactics',
		groupTypes: [FilterGroupType.additional],
		matchEndpoint: 'tactics',
		slug: 'createdByMe',
		type: 'toggle',
	},
	{
		id: 'tactic-tags',
		name: 'Tags',
		category: 'tactics',
		matchEndpoint: 'tactics',
		slug: 'tags',
		type: 'multi-select',
		extra: {
			suggestEntity: 'tag-tactic',
		},
	},
	{
		id: 'date-range-tactics',
		name: 'Date Range',
		category: 'overall',
		matchEndpoint: 'tactics',
		slug: 'date-range',
		type: 'date-range',
		options: [],
		extra: {
			prependDates: '',
		},
	},
	{
		id: 'tactic-created-by',
		name: 'Created By',
		category: 'tactics',
		matchEndpoint: 'tactics',
		slug: 'authors',
		type: 'multi-select',
		extra: {
			suggestEntity: 'user',
		},
	},
	{
		id: 'tactic-has-planned-costs',
		name: 'Has Planned Costs',
		category: 'tactics',
		matchEndpoint: 'tactics',
		slug: 'hasPlannedCosts',
		type: 'toggle',
	},
	{
		id: 'tactic-has-invoices',
		name: 'Has Invoices',
		category: 'tactics',
		matchEndpoint: 'tactics',
		slug: 'hasInvoices',
		type: 'toggle',
	},
	{
		id: 'tactic-has-milestones',
		name: 'Has Milestones',
		category: 'tactics',
		matchEndpoint: 'tactics',
		slug: 'hasMilestones',
		type: 'toggle',
	},
	{
		id: 'tactic-created',
		name: 'Created by Me',
		category: 'overall',
		matchEndpoint: 'tactics',
		slug: 'createdByMe',
		type: 'toggle',
	},
	{
		id: 'tactic-programs',
		name: 'Programs',
		category: undefined, // Hide from most places
		matchEndpoint: 'tactics',
		slug: 'programs',
		type: 'multi-select',
		extra: {
			suggestEntity: 'program',
		},
	},
	{
		id: 'tactic-groups',
		name: 'Tactic Groups',
		category: undefined, // Hide from most places
		matchEndpoint: 'tactics',
		slug: 'tacticsGroups',
		type: 'multi-select',
		extra: {
			suggestEntity: 'program',
		},
	},
	{
		id: 'tactic-measurements',
		name: 'Has Measurements',
		category: 'overall',
		matchEndpoint: 'tactics',
		slug: 'hasMeasurements',
		type: 'toggle-chip',
		options: [
			{
				id: 'tactic-full-measurements',
				name: 'Off',
				value: undefined,
			},
			{
				id: 'tactic-measurements',
				name: 'Yes',
				value: true,
			},
			{
				id: 'tactic-no-measurements',
				name: 'No',
				value: false,
			},
		],
	},
	{
		id: 'tactic-files',
		name: 'Has Files',
		category: 'overall',
		matchEndpoint: 'tactics',
		slug: 'hasFiles',
		type: 'toggle-multi-select',
		options: [
			{
				id: 'tactic-full-files',
				name: 'Off',
				value: undefined,
			},
			{
				id: 'tactic-files',
				name: 'Yes',
				value: true,
			},
			{
				id: 'tactic-no-files',
				name: 'No',
				value: false,
			},
		],
		extra: {
			controlSelectlName: 'tacticHasFilesInCategory',
			category: Category.FileCategory,
		},
	},
	{
		id: 'tactic-is-rmn',
		name: 'Is RMN',
		category: 'tactics',
		matchEndpoint: 'tactics',
		slug: 'isRmn',
		type: 'toggle',
		visibilityCondition: {
			path: 'settings.entities.program.rmn.disabled',
			condition: Condition.NEQ,
			value: true,
		},
	},
];
