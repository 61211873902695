import * as DataUtils from './data.utils';

export async function hashObject(data: Object, algorithm: AlgorithmIdentifier = 'SHA-256', stripNullAndEmpty: boolean = true) {
	data = JSON.parse(JSON.stringify(data));
	const dataString = unwrapObjectData(data, stripNullAndEmpty);
	const buf = await crypto.subtle
			.digest(algorithm, new TextEncoder().encode(dataString));
	return DataUtils.uint8ArrayToHex(new Uint8Array(buf));
}

function unwrapObjectData(input: any, stripNullAndEmpty: boolean = true) {
	let output = '';
	if(Array.isArray(input)) {
		let values = [];
		for(const v of input) {
			let unwrapped = unwrapObjectData(v, stripNullAndEmpty);
			if(unwrapped?.length) {
				values.push(unwrapped);
			}
		}
		output = values.sort().join('');
	} else if(typeof input === 'string' && input.length) {
		output = input;
	} else if(typeof input === 'number' && !isNaN(input)) {
		output = input.toString();
	} else if(typeof input === 'boolean') {
		output = input.toString();
	} else if(input instanceof Date) {
		output = input.getTime().toString();
	} else if(typeof input === 'undefined' || input === null) {
		output = '';
	} else {
		let keys = Object.keys(input).sort();
		for(const k of keys) {
			let unwrapped = unwrapObjectData(input[k], true);
			if(unwrapped?.length) {
				output += k;
				output += unwrapped;
			}
		}
	}
	return output;
}