import { PublicAgency } from '../../agency/agency.entity';
import { PublicBrandInitiative } from '../../brand-initiative/brand-initiative.entity';
import { PublicBrand } from '../../brand/brand.entity';
import { BudgetCache } from '../../budget-cache/budget-cache.entity';
import { CacheResultItem } from '../../budget-cache/models/budget-cache.models';
import { PublicBudgetPeriod } from '../../budget-period/budget-period.entity';
import { PublicInvoice, PublicInvoiceStatus } from '../../invoice/invoice.entity';
import { PublicPlan } from '../../plan/plan.entity';
import { PublicProgramPhase } from '../../program-phase/program-phase.entity';
import { PublicProgramSector } from '../../program-sector/program-sector.entity';
import { PublicProgramType } from '../../program-type/program-type.entity';
import { PublicProgram } from '../../program/program.entity';
import { PublicRetailer } from '../../retailer/retailer.entity';
import { PublicTactic } from '../../tactic/tactic.entity';
import { PublicTag } from '../../tag/tag.entity';

export const basePath: string = '/organization/:orgId/find';
export const planPath: string = '/plans';
export const planProgramsPath: string = '/:planId/programs';
export const programPath: string = '/programs';
export const programTacticsPath: string = '/:programId/tactics';
export const tacticPath: string = '/tactics';
export const tacticInvoicesPath: string = '/:tacticId/invoices';
export const invoicePath: string = '/invoices';

export enum SortStrategy {
	ASC = 'ASC',
	DESC = 'DESC'
}

export type GroupResult<T> = {
	header: GroupHeader<T>,
	cacheMap?: CacheMap
};

export interface TableResult<T> {
	totalPages: number,
	items: Array<T & { children?: TableResult<any> }>,
	budgetSummaries?: any,
}

export interface GroupMeta {
	totalResults: number,
	totalPages: number,
	limit: number,
	type: string,
	budgetCache?: CacheResultItem
}

export type GroupHeader<T> = GroupMeta & {
	items?: GroupItem<T>[];
	paginationEndpoint?: string;
};

export type GroupItem<T> = T & {
	budgetCache?: CacheResultItem;
	children?: GroupHeader<any>;
};

export type QueryResult<T> = Partial<T> & {
	total: string;
};

export type CacheMap = Record<string, BudgetCache[]>;

export type PlanBudgetResult = GroupHeader<
	PublicPlan | PublicRetailer | PublicAgency |
	PublicBrand
>;

export type ProgramBudgetResult = GroupHeader<
	PublicProgram | PublicRetailer |
	PublicBrand | PublicBrandInitiative | PublicProgramPhase |
	PublicProgramSector | PublicPlan |
	PublicTag | PublicProgramType |
	PublicBudgetPeriod
>;

export type TacticBudgetResult = GroupHeader<
	PublicTactic |
	PublicBrand | PublicRetailer | PublicAgency | PublicProgram
>;

export type InvoiceBudgetResult = GroupHeader<
	PublicInvoice |
	PublicBrand |
	PublicProgram |
	PublicRetailer |
	PublicAgency |
	PublicInvoiceStatus
>;