import {
	Column,
	Entity,
	PrimaryGeneratedColumn,
	ManyToOne,
	JoinColumn,
	Index
} from 'typeorm';

import { BudgetPeriod } from '../budget-period/budget-period.entity';

import { Retailer } from '../retailer/retailer.entity';
import { Plan } from '../plan/plan.entity';
import { Program } from '../program/program.entity';
import { Tactic } from '../tactic/tactic.entity';
import { Brand } from '../brand/brand.entity';

export class BudgetCacheDistribution {
	start: string;
	end: string;
	global?: boolean;
	value: Pick<BudgetCache, 'amountEstimated' | 'amountPlanned' | 'amountActual' | 'spendEstimated' | 'spendActual'>
}

export class BudgetCacheDetails {
	brandId?: string;
	distributions?: BudgetCacheDistribution[];
}

export type PublicBudgetCache = Pick<BudgetCache,
	'amountEstimated' | 'amountPlanned' | 'amountActual' | 'spendEstimated' | 'spendActual' | 'details'
>;

@Entity('budgetCaches')
// Plan Indexes
@Index(['planId'], {
	where: '("planId" IS NOT NULL AND "programId" IS NULL AND "tacticId" IS NULL AND "brandId" IS NULL)',
	unique: true
})
@Index(['planId', 'brandId'], {
	where: '("planId" IS NOT NULL AND "programId" IS NULL AND "tacticId" IS NULL AND "brandId" IS NOT NULL)',
	unique: true
})
@Index(['planId', 'programId'], {
	where: '("planId" IS NOT NULL AND "programId" IS NOT NULL AND "tacticId" IS NULL AND "brandId" IS NULL)',
	unique: true
})
@Index(['planId', 'programId', 'brandId'], {
	where: '("planId" IS NOT NULL AND "programId" IS NOT NULL AND "tacticId" IS NULL AND "brandId" IS NOT NULL)',
	unique: true
})
// Program Indexes
@Index(['programId'], {
	where: '("planId" IS NULL AND "programId" IS NOT NULL AND "tacticId" IS NULL AND "brandId" IS NULL)',
	unique: true
})
@Index(['programId', 'brandId'], {
	where: '("planId" IS NULL AND "programId" IS NOT NULL AND "tacticId" IS NULL AND "brandId" IS NOT NULL)',
	unique: true
})
@Index(['programId', 'tacticId'], {
	where: '("planId" IS NULL AND "programId" IS NOT NULL AND "tacticId" IS NOT NULL AND "brandId" IS NULL)',
	unique: true
})
@Index(['programId', 'tacticId', 'brandId'], {
	where: '("planId" IS NULL AND "programId" IS NOT NULL AND "tacticId" IS NOT NULL AND "brandId" IS NOT NULL)',
	unique: true
})
// Tactic Indexes
@Index(['tacticId', 'brand'])
export class BudgetCache {
	constructor(value?: Partial<BudgetCache>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: true })
	budgetPeriodId: string;
	@ManyToOne(
		() => BudgetPeriod,
		{
			eager: false,
			nullable: true,
			// onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'budgetPeriodId' })
	budgetPeriod: BudgetPeriod;

	@Column('text', { nullable: true })
	planId?: string;
	@ManyToOne(
		() => Plan,
		plan => plan.budgetCache,
		{
			//nullable: true,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'planId' })
	plan?: Plan;

	@Column('text', { nullable: true })
	programId?: string;
	@ManyToOne(
		() => Program,
		program => program.budgetCache,
		{
			//nullable: true,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'programId' })
	program?: Program;

	@Column('text', { nullable: true })
	brandId?: string;
	@ManyToOne(
		() => Brand,
		{
			// nullable: true,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'brandId' })
	brand?: Brand;

	@Column('text', { nullable: true })
	tacticId?: string;
	@ManyToOne(
		() => Tactic,
		tactic => tactic.budgetCache,
		{
			// nullable: true,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'tacticId' })
	tactic?: Tactic;

	@Column('decimal', {
		nullable: true,
		transformer: {
			to: (value: any) => Number(value),
			from: (value: any) => Number(value)
		}
	})
	amountEstimated: number;

	@Column('decimal', {
		nullable: true,
		transformer: {
			to: (value: any) => Number(value),
			from: (value: any) => Number(value)
		}
	})
	amountPlanned: number;

	@Column('decimal', {
		nullable: true,
		transformer: {
			to: (value: any) => Number(value),
			from: (value: any) => Number(value)
		}
	})
	amountActual: number;

	@Column('decimal', {
		nullable: true,
		transformer: {
			to: (value: any) => Number(value),
			from: (value: any) => Number(value)
		}
	})
	spendEstimated?: number;

	@Column('decimal', {
		nullable: true,
		transformer: {
			to: (value: any) => Number(value),
			from: (value: any) => Number(value)
		}
	})
	spendActual?: number;

	@Column('jsonb', { nullable: true })
	details?: BudgetCacheDetails;

	@Column({ type: 'timestamptz', default: () => 'NOW()' })
	created: string;

	public toPublic(): PublicBudgetCache {
		return {
			amountEstimated: this.amountEstimated,
			amountPlanned: this.amountPlanned,
			amountActual: this.amountActual,
			spendEstimated: this.spendEstimated,
			spendActual: this.spendActual,
			details: this.details
		};
	}
}