import { Agency } from '../agency/agency.entity';
import { Organization } from '../organization/organization.entity';
import { Retailer } from '../retailer/retailer.entity';
import { UserRoleMap } from '../user/models';
import { User } from '../user/user.entity';
import { EntityMap, EntityPermission } from './entity-permission.entity';
import { CustomPermission } from './models/custom-permission.model';
import { PermissionLevel, PermissionType } from './permission.entity';

export class RemotePermission {
	id: string;
	permission: Array<{
		read: '' | '1',
		write: '' | '1',
		maintain: '' | '1'
	}>;
	subject: Array<{
		typeHandle: string;
		id: string;
		title: string;
	}>
}

export class Utils {
	public static hasCustomPermission(user: User, customPermission: CustomPermission, previousPermission?: CustomPermission) {
		if(!customPermission) {
			return true;
		}
		const valid = this.checkCustomPermission(user, customPermission);
		if(valid && previousPermission) {
			const entityMap = this.getEntityMap(customPermission);
			let previousEntityMap = this.getEntityMap(previousPermission)
			for(const g of this.guardsEnabled(user.organization.settings)) {
				if(previousEntityMap[g]?.length) {
					previousEntityMap[g] = previousEntityMap[g].filter(i => !entityMap[g]?.includes(i));
					if(!this.hasEntityPermission(user.entityPermissions as EntityPermission[], g, PermissionLevel.Write, previousEntityMap[g])) {
						return false;
					}
				}
			}
		}
		return valid;
	}

	public static checkCustomPermission(user: User, customPermission: CustomPermission) {
		let roleMatch = false;
		for(const r of customPermission.roles) {
			if(UserRoleMap[user.role] <= UserRoleMap[r]) {
				roleMatch = true;
				break;
			}
		}

		if(!roleMatch) {
			return false;
		}

		let valid = true;
		if(customPermission.permissionType === PermissionType.Retailer) {
			if(this.guardEnabled(user.organization.settings, PermissionType.Retailer)) {
				if(!this.hasEntityPermission(
					user.entityPermissions as EntityPermission[],
					customPermission.permissionType,
					customPermission.permissionLevel,
					customPermission.retailerIds
				)) {
					valid = false;
				}
			}
		}

		if(customPermission.permissionType === PermissionType.Agency) {
			if(this.guardEnabled(user.organization.settings, PermissionType.Agency)) {
				if(!this.hasEntityPermission(
					user.entityPermissions as EntityPermission[],
					customPermission.permissionType,
					customPermission.permissionLevel,
					customPermission.retailerIds
				)) {
					valid = false;
				}
			}
		}

		return valid;
	}

	public static guardEnabled(settings: Organization['settings'], type: PermissionType) {
		// Handle legacy case. If no settings are saved, retailers are guarded.
		if(!settings?.guardsEnabled?.length && type === PermissionType.Retailer) {
			return true;
		}
		if(settings?.guardsEnabled?.includes(type)) {
			return true;
		}
		return false;
	}

	public static guardsEnabled(settings: Organization['settings']) {
		// Handle legacy case. If no settings are saved, retailers are guarded.
		if(!settings?.guardsEnabled?.length) {
			return [PermissionType.Retailer]
		}
		return settings.guardsEnabled;
	}

	public static getIdsForType(permissionType: PermissionType, retailers: Retailer[], agencies: Agency[]) {
		switch(permissionType) {
			case PermissionType.Retailer:
				return retailers?.map(r => r.id) ?? [];
			case PermissionType.Agency:
				return agencies?.map(a => a.id) ?? [];
			default:
				return [];
		}
	}

	public static getReadableIdsByType(
		entityPermissions: EntityPermission[],
		permissionType: PermissionType,
		entityIds: string[] = []
	) {
		if(!entityPermissions?.length) {
			return [];
		}
		const ids = this.extractIdsByType(entityPermissions, permissionType);
		if(entityIds?.length) {
			return entityIds.filter(i => ids.includes(i));
		}
		return ids;
	}

	public static filterPermissionsAndExtractIdsByType(
		entityPermissions: EntityPermission[],
		permissionLevel: PermissionLevel,
		permissionType: PermissionType
	) {
		entityPermissions = this.filterPermissions(entityPermissions, permissionLevel);
		return this.extractIdsByType(entityPermissions, permissionType);
	}

	public static extractIdsByType(entityPermissions: EntityPermission[], permissionType: PermissionType) {
		let ids = [];
		for(const p of entityPermissions) {
			if(p.entityMap[permissionType]?.length) {
				ids.push(...p.entityMap[permissionType])
			}
		}
		return ids;
	}

	public static filterPermissions(entityPermissions: EntityPermission[], permissionLevel: PermissionLevel) {
		return entityPermissions
			.filter(item => {
				// User has Read.
				if(
					item.permissionLevel === PermissionLevel.Read &&
					permissionLevel === PermissionLevel.Read
				) {
					return true;
				}
				// User has Write.
				if(
					item.permissionLevel === PermissionLevel.Write &&
					(
						permissionLevel === PermissionLevel.Read ||
						permissionLevel === PermissionLevel.Write
					)
				) {
					return true;
				}
				// User has Maintain.
				if(
					item.permissionLevel === PermissionLevel.Maintain &&
					(
						permissionLevel === PermissionLevel.Read ||
						permissionLevel === PermissionLevel.Write ||
						permissionLevel === PermissionLevel.Maintain
					)
				) {
					return true;
				}

				return false;
			});
	}

	public static canAccess(user: User, entityMap: EntityMap, permissionLevel: PermissionLevel) {
		for(const k of Object.keys(entityMap)) {
			if(this.guardEnabled(user.organization.settings, k as PermissionType)) {
				if(!this.hasEntityPermission(user.entityPermissions as EntityPermission[], k as PermissionType, permissionLevel, entityMap[k])) {
					return false;
				}
			}
		}
		return true;
	}

	public static canRead(user: User, entityMap: EntityMap) {
		return this.canAccess(user, entityMap, PermissionLevel.Read);
	}

	public static getEntityMap(obj: unknown) {
		const map: EntityMap = {};
		for(const k of Object.values(PermissionType)) {
			map[k] = [];
			let idKey = `${k}Id`;
			if(obj[idKey] && typeof obj[idKey] === 'string' && obj[idKey].length) {
				map[k].push(obj[idKey])
			}
		}
		return map;
	}

	public static addEntityPermission(
		permissionType: PermissionType,
		permissionLevel: PermissionLevel,
		ids: string[],
		currentPermissions: EntityPermission[] = []
	) {
		let idx = currentPermissions.findIndex(p => p.permissionLevel === permissionLevel);

		let permission;
		if(idx === -1) {
			permission = new EntityPermission({
				permissionLevel: permissionLevel,
				entityMap: {}
			});
		} else {
			permission = new EntityPermission(currentPermissions[idx]);
		}

		if(!permission.entityMap[permissionType]) {
			permission.entityMap[permissionType] = [];
		}

		let newIds = [];
		for(const id of ids) {
			if(!permission.entityMap[permissionType].includes(id)) {
				newIds.push(id);
			}
		}
		permission.entityMap[permissionType].push(...newIds);
		console.log(newIds, ids);

		if(permission.id === null) {
			delete permission.id;
		}
		if(permission.userId === null) {
			delete permission.userId;
		}

		if(idx === -1) {
			currentPermissions.push(permission);
		} else {
			currentPermissions[idx] = permission;
		}

		return currentPermissions;
	}

	public static removeEntityPermission(
		permissionType: PermissionType,
		permissionLevel: PermissionLevel,
		ids: string[],
		currentPermissions: EntityPermission[] = []
	) {
		let idx = currentPermissions.findIndex(p => p.permissionLevel === permissionLevel);

		let permission;
		if(idx === -1) {
			return currentPermissions;
		} else {
			permission = new EntityPermission(currentPermissions[idx]);
		}

		if(!permission.entityMap[permissionType]) {
			return currentPermissions;
		}

		let newIds = [];
		for(const id of permission.entityMap[permissionType]) {
			if(!ids.includes(id)) {
				newIds.push(id);
			}
		}
		permission.entityMap[permissionType] = newIds;

		if(permission.id === null) {
			delete permission.id;
		}
		if(permission.userId === null) {
			delete permission.userId;
		}

		currentPermissions[idx] = permission;

		return currentPermissions;
	}

	public static hasEntityPermission(
		entityPermissions: EntityPermission[],
		permissionType: PermissionType,
		permissionLevel: PermissionLevel,
		entityIds: string[]
	) {
		if(!entityIds?.length) {
			return true;
		}
		for(const id of entityIds) {
			if(!id) {
				return false;
			}
			let idMatch = false;
			for(const item of entityPermissions) {
				if(this.matchPermission(item, permissionType, permissionLevel, id)) {
					idMatch = true;
				}
			}
			if(!idMatch) {
				return false;
			}
		}
		return true;
	}

	private static matchPermission(permission: EntityPermission, permissionType: PermissionType, permissionLevel: PermissionLevel, id: string) {
		// User has Read.
		if(
			permission.permissionLevel === PermissionLevel.Read &&
			permissionLevel === PermissionLevel.Read &&
			permission.entityMap[permissionType]?.includes(id)
		) {
			return true;
		}
		// User has Write.
		if(
			permission.permissionLevel === PermissionLevel.Write &&
			(
				permissionLevel === PermissionLevel.Read ||
				permissionLevel === PermissionLevel.Write
			) &&
			permission.entityMap[permissionType]?.includes(id)
		) {
			return true;
		}
		// User has Maintain.
		if(
			permission.permissionLevel === PermissionLevel.Maintain &&
			(
				permissionLevel === PermissionLevel.Read ||
				permissionLevel === PermissionLevel.Write ||
				permissionLevel === PermissionLevel.Maintain
			) &&
			permission.entityMap[permissionType]?.includes(id)
		) {
			return true;
		}
		return false;
	}

	public static cmsToSystemPermissionType(cmsType: string) {
		for(const v of Object.values(PermissionType)) {
			if(cmsType === v) {
				return v as PermissionType;
			}
		}
		return null;
	}

	public static cmsToSystemPermission(cmsPermission) {
		if(cmsPermission.maintain === '1') {
			return PermissionLevel.Maintain;
		} else if(cmsPermission.write === '1') {
			return PermissionLevel.Write;
		} else if(cmsPermission.read === '1') {
			return PermissionLevel.Read;
		}
		return null;
	}

	public static getRemotePermissionMap(cmsPermissions: RemotePermission[], userId: string, retailers: Retailer[] = [], agencies: Agency[] = []): Record<PermissionLevel, EntityPermission> {
		if(!cmsPermissions?.length) {
			return null;
		}
		return cmsPermissions
			.map(p => {
				const permissionType = Utils.cmsToSystemPermissionType(p.subject[0].typeHandle);
				const permissionLevel = Utils.cmsToSystemPermission(p.permission[0]);
				if(!permissionType || !permissionLevel) {
					return null;
				}
				let eId;
				switch(permissionType) {
					case PermissionType.Retailer:
						eId = retailers?.find(r => r.remoteId == p.subject[0].id)?.id;
						break;
					case PermissionType.Agency:
						eId = agencies?.find(a => a.remoteId == p.subject[0].id)?.id;
						break;
					default:
						break;
				}
				if(!eId) {
					return null;
				}
				return new EntityPermission({
					userId: userId,
					permissionLevel,
					entityMap: {
						[permissionType]: [eId]
					}
				});
			})
			.reduce((acc, cur) => {
				if(!cur) {
					return acc;
				}
				if(!acc[cur.permissionLevel]) {
					acc[cur.permissionLevel] = cur;
					return acc;
				}
				if(!acc[cur.permissionLevel].entityMap) {
					acc[cur.permissionLevel].entityMap = {};
				}
				for(const p of Object.values(PermissionType)) {
					if(cur.entityMap[p]) {
						if(!acc[cur.permissionLevel].entityMap[p]) {
							acc[cur.permissionLevel].entityMap[p] = cur.entityMap[p];
						} else {
							acc[cur.permissionLevel].entityMap[p] = [
								...acc[cur.permissionLevel].entityMap[p],
								...cur.entityMap[p]
							];
						}
					}
				}
				return acc;
			}, {} as Record<PermissionLevel, EntityPermission>);
	}
}