export function uint8ArrayToHex(arr: Uint8Array) {
	return [...arr].map(v => v.toString(16).padStart(2, '0')).join('');
}

export function dataUrlToBlob(dataUrl: string): Blob {
	const byteString = atob(dataUrl.split(',')[1]);
	const mimeType = dataUrl.split(',')[0].split(':')[1].split(';')[0];
	const arrayBuffer = new ArrayBuffer(byteString.length);
	const uint8Array = new Uint8Array(arrayBuffer);

	for (let i = 0; i < byteString.length; i++) {
		uint8Array[i] = byteString.charCodeAt(i);
	}

	return new Blob([arrayBuffer], { type: mimeType });
}
