export enum WarningSelect {
	// TODO?
}

export class Query {
	private static readonly WARNING_QUERY_ALIAS: string = 'p';

	public static getSelects(
		alias: string = this.WARNING_QUERY_ALIAS,
		//targets: WarningSelect[] = Object.values(WarningSelect),
		asJsonSelect: boolean = false
	) {
		// if(!targets) {
		// 	targets = [];
		// }
		const selects: string[] = [];
		const json: boolean = asJsonSelect;

		selects.push(`
			${(json) ? `'id', ${alias}.id` : `${alias}.id`}
		`);
		selects.push(`
			${(json) ? `'category', ${alias}.category` : `${alias}.category`}
		`);
		selects.push(`
			${(json) ? `'type', ${alias}.type` : `${alias}.type`}
		`);
		selects.push(`
			${(json) ? `'description', ${alias}.description` : `${alias}.description`}
		`);
		selects.push(`
			${(json) ? `'dismissable', ${alias}.dismissable` : `${alias}.dismissable`}
		`);
		selects.push(`
			${(json) ? `'programId', ${alias}."programId"` : `${alias}."programId"`}
		`);
		selects.push(`
			${(json) ? `'tacticId', ${alias}."tacticId"` : `${alias}."tacticId"`}
		`);
		selects.push(`
			${(json) ? `'invoiceId', ${alias}."invoiceId"` : `${alias}."invoiceId"`}
		`);
		selects.push(`
			${(json) ? `'created', ${alias}.created` : `${alias}.created`}
		`);

		return selects.join(',');
	}

	public static getSubqueries(
		alias: string = this.WARNING_QUERY_ALIAS,
		//targets: WarningSelect[] = Object.values(WarningSelect)
	) {
		// if(!targets?.length) {
		// 	return '';
		// }

		const subQueries: string[] = [];


		if(!subQueries.length) {
			return '';
		}

		return ',\n' + subQueries.join(',');
	}
}