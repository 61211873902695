import { Column, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm';
import { PublicUser, User } from '../user/user.entity';
import { File, PublicFile } from '../file/file.entity';

export enum CommentStatus {
	ChangesRequested = 'changesRequested',
	Approved = 'approved'
}

export type PublicComment = Pick<Comment,
	'id' | 'status' | 'body' | 'annotations' | 'authorId' | 'created' | 'updated'
> & {
	fileId?: string;
	file?: PublicFile;
	author?: PublicUser;
	commentFiles?: Array<PublicFile>;
}

@Entity('comments')
export class Comment {
	constructor(value?: Partial<Comment>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: true })
	body?: string;

	@Column({
		type: 'enum',
		enum: CommentStatus,
		nullable: true
	})
	status?: CommentStatus;

	@Column('uuid', { nullable: true })
	fileId?: string;
	@ManyToOne(
		() => File,
		{
			nullable: true,
			orphanedRowAction: 'delete',
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'fileId' })
	file?: File;

	@Column('jsonb', {
		nullable: true
	})
	annotations?: any[];

	@Column('uuid', { nullable: false })
	authorId: string;
	@ManyToOne(
		() => User,
		{
			eager: true,
			onDelete: 'CASCADE'
		}
	)
	author: User;

	@OneToMany(
		() => File,
		file => file.comment,
		{
			nullable: true,
			eager: true,
			cascade: true,
			onDelete: 'CASCADE'
		}
	)
	commentFiles?: File[];

	@Column({ type: 'timestamptz', nullable: false, default: () => 'NOW()' })
	created: string;

	@Column({ type: 'timestamptz', nullable: false, default: () => 'NOW()' })
	updated: string;


	@Column('boolean', { nullable: true, default: false })
	isMailSent: boolean;

	public toPublic() {
		const pub: Partial<PublicComment> = {
			id: this.id,
			status: this.status,
			body: this.body,
			annotations: this.annotations,
			created: this.created,
			updated: this.updated,
			authorId: this.authorId
		};

		if(this.fileId) {
			pub.fileId = this.fileId;
		}

		if(this.author) {
			pub.author = new User(this.author).toPublic();
		}

		if(this.commentFiles) {
			pub.commentFiles = this.commentFiles.map(file => new File(file).toPublic());
		}

		return pub as PublicComment;
	}
}
