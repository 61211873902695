import {
	Entity,
	PrimaryGeneratedColumn,
	Column,
	Index
} from 'typeorm';

import { Sortable } from '../_core/decorators/sortable.decorator';

export type PublicVendorType = Pick<VendorType, 'id' | 'name'>;

@Entity('vendorTypes')
@Index(['name'], { unique: true })
export class VendorType {
	constructor(value?: Partial<VendorType>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', {
		nullable: false
	})
	@Sortable
	name: string;

	@Column('text', { nullable: true })
	remoteId: string;

	public toPublic(): PublicVendorType {
		return {
			id: this.id,
			name: this.name
		};
	}
}
