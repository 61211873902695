import { Tactic } from '../tactic/tactic.entity';

import {
	Entity,
	PrimaryGeneratedColumn,
	Column,
	ManyToOne,
	JoinColumn,
	OneToMany
} from 'typeorm';

import { PublicUser, User } from '../user/user.entity';
import { Measurement, PublicMeasurement } from '../measurement/measurement.entity';

export type PublicOffer = Pick<
	Offer,
	'id' | 'created' | 'data'
> & {
	author: PublicUser;
	measurements?: PublicMeasurement[],
};

@Entity('offers')
export class Offer {
	constructor(value?: Partial<Offer>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: true })
	name?: string;

	@Column('text', { nullable: true })
	code?: string;

	@Column('text', { nullable: false })
	tacticId: string;
	@ManyToOne(
		type => Tactic,
		tactic => tactic.offers,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'tacticId' })
	tactic: Tactic;

	@Column('boolean', { default: false, nullable: false })
	deleted: boolean;

	@Column('text', { nullable: false })
	authorId: string;
	@ManyToOne(
		type => User,
		{
			eager: true,
			onDelete: 'CASCADE'
		}
	)
	author: User;

	@Column({ type: 'timestamptz', nullable: false, default: () => 'NOW()' })
	created: string;

	@Column('jsonb', { nullable: true })
	data?: any;

	@OneToMany(
		() => Measurement,
		measurement => measurement.offer,
		{
			cascade: true
		}
	)
	measurements?: Measurement[] | Partial<Measurement>[];

	public toPublic(): PublicOffer {
		const pub: Partial<PublicOffer> = {
			id: this.id,
			created: this.created,
			data: this.data
		};

		if(this.author) {
			pub.author = new User(this.author).toPublic();
		}

		if(this.measurements){
			pub.measurements = (this.measurements as Partial<Measurement>[])
				.map(m => {
					return new Measurement(m).toPublic();
				});
		} else {
			pub.measurements = [];
		}

		return pub as PublicOffer;
	}
}
