import {
	MeasurementType,
	PublicMeasurementType
} from '../measurement-type/measurement-type.entity';
import {
	Column,
	Entity,
	Index,
	JoinColumn,
	JoinTable,
	ManyToMany,
	ManyToOne,
	PrimaryGeneratedColumn
} from 'typeorm';
import {
	PublicTacticType,
	TacticType
} from '../tactic-type/tactic-type.entity';
import {
	Organization,
	PublicOrganization
} from '../organization/organization.entity';

export type PublicMeasurementGroup = Pick<
	MeasurementGroup,
	'id' | 'name' | 'slug' | 'hidden'
> & {
	organization?: PublicOrganization;
	tacticTypes?: PublicTacticType[];
	measurementTypes?: PublicMeasurementType[];
};

@Entity('measurementGroups')
export class MeasurementGroup {
	constructor(value?: Partial<MeasurementGroup>) {
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: true })
	organizationId: string;
	@ManyToOne(
		type => Organization,
		organization => organization.id,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'organizationId' })
	organization?: Organization | Partial<Organization>;

	@Column('text', { nullable: false })
	name: string;

	@Column('text', { nullable: false })
	slug: string;

	@Column('boolean', { nullable: false, default: false })
	hidden: boolean;

	@ManyToMany(
		() => TacticType,
		tacticType => tacticType.measurementGroups,
		{
			nullable: true,
			cascade: true
		}
	)
	@JoinTable({ name: 'measurementGroupTacticTypes' })
	tacticTypes?: TacticType[] | Partial<TacticType>[];

	@ManyToMany(() => MeasurementType, {
		eager: true,
		nullable: true,
		cascade: true
	})
	@JoinTable({ name: 'measurementGroupMeasurementTypes' })
	measurementTypes?: MeasurementType[] | Partial<MeasurementType>[];

	public toPublic(): PublicMeasurementGroup {
		let pub: Partial<PublicMeasurementGroup> = {
			id: this.id,
			name: this.name,
			slug: this.slug,
			hidden: this.hidden
		};

		if (this.organization) {
			pub.organization = new Organization(this.organization).toPublic();
		}

		if (this.tacticTypes?.length) {
			pub.tacticTypes = (this.tacticTypes as TacticType[]).map(t =>
				new TacticType(t).toPublic()
			);
		}

		if (this.measurementTypes?.length) {
			pub.measurementTypes = (this
				.measurementTypes as MeasurementType[]).map(t =>
				new MeasurementType(t).toPublic()
			);
		}

		return pub as PublicMeasurementGroup;
	}
}
