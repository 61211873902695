import {
	Entity,
	PrimaryGeneratedColumn,
	Column,
	ManyToOne,
	JoinColumn,
	Index
} from 'typeorm';

import { Sortable } from '../_core/decorators/sortable.decorator';
import { StringUtils } from '../_core/utils/utils.string';

import { Organization } from '../organization/organization.entity';
import { Brand, PublicBrand } from '../brand/brand.entity';

export type PublicProduct = Pick<Product, 'id' | 'name' | 'hidden'> & {
	parentBrand?: PublicBrand
};

@Entity('products')
@Index(['organizationId', 'remoteId'], { unique: true })
export class Product {
	constructor(value?: Partial<Product>) {
		for(let k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false, default: () => `md5(random()::text)` })
	remoteId: string;

	@Column('text', {
		nullable: false,
		transformer: {
			to: StringUtils.titleCase,
			from: StringUtils.titleCase
		}
	})
	@Sortable
	name: string;

	@Column('text', { nullable: false })
	organizationId: string;
	@ManyToOne(
		() => Organization,
		{
			eager: false,
			nullable: false,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'organizationId' })
	organization: Organization;

	@Column('text', { nullable: true })
	parentBrandId: string;
	@ManyToOne(
		type => Brand,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'parentBrandId' })
	parentBrand?: Brand;

	@Column('boolean', { nullable: false, default: false})
	hidden: boolean;

	public toPublic(): PublicProduct {
		let pub: Partial<PublicProduct> = {
			id: this.id,
			name: this.name,
			hidden: this.hidden
		};

		if(this.parentBrand) {
			pub.parentBrand = new Brand(pub.parentBrand).toPublic();
		}
		
		return pub as PublicProduct;
	}
}