import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { BrandAllocation, PublicBrandAllocation } from '../brand-allocation/brand-allocation.entity';
import { BudgetDistributionGroup, PublicBudgetDistributionGroup } from '../budget-distribution-group/budget-distribution-group.entity';

/**
 * Merged Budget Distribution is a combination of several budget distributions across brand allocations.
 * We store the ids that are stored and add a total to help sum up the total amount of the distribution item.
 */
 export type MergedBudgetDistribution = {
	ids: string[];
	name: string;
	budgetItemId: string;
	budgetItemBrandAllocationKey: string;
	budgetDistributionGroupId: string;
	total: number;
	start?: string;
	end?: string;
	details?: {
		[key: string]: any;
	}
};

export interface MergedBudgetDistributionWithGroup extends MergedBudgetDistribution, PublicBudgetDistributionGroup {}

export type PublicBudgetDistribution = Pick<BudgetDistribution,
	'id' | 'brandAllocationId' | 'start' | 'end' | 'split' |
	'budgetDistributionGroupId'
> & {
	brandAllocation?: PublicBrandAllocation,
	budgetDistributionGroup?: PublicBudgetDistributionGroup
}

@Entity('budgetDistributions')
export class BudgetDistribution {
	constructor(value?: Partial<BudgetDistribution>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('uuid', { nullable: true })
	brandAllocationId: string;
	@ManyToOne(
		() => BrandAllocation,
		{
			nullable: true,
			orphanedRowAction: 'delete',
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'brandAllocationId' })
	brandAllocation?: BrandAllocation;

	@Column('text', { nullable: true })
	budgetDistributionGroupId?: string;
	@ManyToOne(
		() => BudgetDistributionGroup
	)
	@JoinColumn({ name: 'budgetDistributionGroupId' })
	budgetDistributionGroup?: BudgetDistributionGroup;

	@Column({ type: 'timestamptz', nullable: false })
	start: string;

	@Column({ type: 'timestamptz', nullable: false })
	end: string;

	// @Column('decimal', { nullable: false })
	// amount: number;

	@Column('numeric', { nullable: false })
	split: number;

	public toPublic() {
		const pub: Partial<PublicBudgetDistribution> = {
			id: this.id,
			brandAllocationId: this.brandAllocationId,
			budgetDistributionGroupId: this.budgetDistributionGroupId,
			start: this.start,
			end: this.end,
			split: this.split
		};

		if(this.brandAllocation) {
			pub.brandAllocation = new BrandAllocation(this.brandAllocation).toPublic();
		}

		if(this.budgetDistributionGroup) {
			pub.budgetDistributionGroup = new BudgetDistributionGroup(this.budgetDistributionGroup).toPublic();
		}

		return pub as PublicBudgetDistribution;
	}
}
