import {
	Entity,
	Column,
	JoinColumn,
	ManyToOne,
	PrimaryGeneratedColumn,
	Index,
	OneToMany
} from 'typeorm';

import { PublicBrand, Brand } from '../brand/brand.entity';
import { BudgetAllocation } from '../budget-allocation/budget-allocation.entity';
import { Invoice } from '../invoice/invoice.entity';
import { Cost } from '../cost/cost.entity';
import { BudgetDistribution, PublicBudgetDistribution } from '../budget-distribution/budget-distribution.entity';


export type PublicBrandAllocation = Pick<BrandAllocation,
	'brandId' | 'split'
> & {
	brand?: PublicBrand;
	budgetDistributions?: PublicBudgetDistribution[];
};

@Entity('brandAllocations')
@Index(['brandId'])
@Index(['budgetAllocationPlannedId'])
@Index(['budgetAllocationActualId'])
@Index(['budgetAllocationPlannedId', 'brandId'], { 
	where: '("budgetAllocationPlannedId" IS NOT NULL)',
	unique: true
})
@Index(['budgetAllocationActualId', 'brandId'], {
	where: '("budgetAllocationActualId" IS NOT NULL)',
	unique: true
})
@Index(['costId', 'brandId'], { 
	where: '("costId" IS NOT NULL)',
	//unique: true
})
@Index(['invoiceId', 'brandId'], { 
	where: '("invoiceId" IS NOT NULL)',
	//unique: true
})
export class BrandAllocation {
	constructor(value?: Partial<BrandAllocation>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for (let k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('uuid', { nullable: false })
	brandId: string;
	@ManyToOne(
		type => Brand,
		{
			nullable: false,
			eager: true
		}
	)
	@JoinColumn({ name: 'brandId' })
	brand: Brand | Partial<Brand>;

	@Column('numeric', { nullable: false })
	split: number;

	@Column('uuid', { nullable: true })
	invoiceId?: string;
	@ManyToOne(
		() => Invoice,
		{
			orphanedRowAction: 'delete',
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'invoiceId'})
	invoice?: Invoice;

	@Column('uuid', { nullable: true })
	budgetAllocationPlannedId: string;
	@ManyToOne(
		() => BudgetAllocation,
		{
			onDelete: 'CASCADE',
			orphanedRowAction: 'delete'
		}
	)
	@JoinColumn({ name: 'budgetAllocationPlannedId' })
	budgetAllocationPlanned?: BudgetAllocation;

	@Column('uuid', { nullable: true })
	budgetAllocationActualId: string;
	@ManyToOne(
		() => BudgetAllocation,
		{
			onDelete: 'CASCADE',
			orphanedRowAction: 'delete'
		}
	)
	@JoinColumn({ name: 'budgetAllocationActualId' })
	budgetAllocationActual?: BudgetAllocation;

	@Column('uuid', { nullable: true })
	costId?: string;
	@ManyToOne(
		() => Cost,
		{
			orphanedRowAction: 'delete',
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'costId' })
	cost?: Cost;

	@OneToMany(
		() => BudgetDistribution,
		budgetDistribution => budgetDistribution.brandAllocation,
		{
			nullable: true,
			eager: true,
			cascade: true
		}
	)
	budgetDistributions?: BudgetDistribution[];

	public toPublic(): PublicBrandAllocation {
		const pub: Partial<PublicBrandAllocation> = {
			split: this.split,
			brandId: this.brandId
		};

		if(!this.brand && this.brandId) {
			pub.brand = new Brand({ id: this.brandId }).toPublic();
		} else if(this.brand) {
			pub.brand = new Brand(this.brand).toPublic();
		}

		if(this.budgetDistributions?.length) {
			pub.budgetDistributions = this.budgetDistributions.map(d => new BudgetDistribution(d).toPublic());
		}

		return pub as PublicBrandAllocation;
	}
}