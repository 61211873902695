import { Injectable } from '@angular/core';
import { EntityState, EntityStore, MultiActiveState, StoreConfig } from '@datorama/akita';
import { Filter } from './filter.model';

export interface FilterState extends EntityState<Filter>, MultiActiveState {}

const initialState = {
	active: [],
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'filter' })
export class FilterStore extends EntityStore<FilterState> {

	constructor() {
		super(initialState);
	}

}
