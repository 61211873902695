import { resolveDotNotationPath } from './object.utils';

/**
 * Convert a string into a slugified string
 * @param str
 */
export function stringToSlug(str: string) {
	if (!str) {
		return;
	}

	str = str.replace(/^\s+|\s+$/g, ''); // trim
	str = str.toLowerCase();

	// remove accents, swap ñ for n, etc
	const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
	const to = 'aaaaeeeeiiiioooouuuunc------';
	for (let i = 0, l = from.length; i < l; i++) {
		str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
	}

	str = str
		.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
		.replace(/\s+/g, '-') // collapse whitespace and replace by -
		.replace(/-+/g, '-'); // collapse dashes

	return str;
}

/**
 * Looks through a string and replaces any merge tags using a ${} syntax with variables.
 * Example: A ${option.color} fox jumps over a ${option.size} dog.
 * @param str
 * @param obj
 */
export function replaceMergeTags(str: string, obj: any) {
	// Replace ${} with evaluated variable paths
	return str.replace(/\${(.*?)}/g, (match) => {
		return resolveDotNotationPath(match.replace('${', '').replace('}', ''), obj);
	});
}

/**
 * Looks through a string and replaces any merge tags using a ${} syntax with a string value.
 * Example: A ${option.color} fox jumps over a ${option.size} dog.
 * @param str
 * @param value
 */
export function replaceMergeTagsWithString(str: string, value: string) {
	// Replace ${} with evaluated variable paths
	return str.replace(/\${(.*?)}/g, (match) => {
		return value;
	});
}

/**
 * Retrieves the query string from the page url
 * @param name
 */
export function getQueryParamFromMalformedURL(name) {
	const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(decodeURIComponent(window.location.href));
	if (!results) {
		return 0;
	}
	return results[1] || 0;
}

export function stripHtml(html: string, keepLineBreaks?: boolean) {
	if (keepLineBreaks) {
		html = html.replace(/[<]br[^>]*[>]/gi, '\n').replace(/<\/div><div>/gi, '\n');
	}
	return html.replace(/[&]nbsp[;]/gi, ' ').replace(/<\/?[^>]+(>|$)/g, '');
}

export function getNumberFromString(str: string) {
	return Number(str.replace('$', '').replace('%', '').replace(/,/gi, ''));
}

export function capitalize(str: string) {
	return str?.length ? str[0].toUpperCase() + str.slice(1).toLowerCase() : '';
}

// Get the letter of the alphabet at a given index
export function getLetterAtIndex(index: number) {
	return String.fromCharCode(65 + index);
}

export function getBackgroundColorFromString(stringInput) {
	const stringUniqueHash = [...stringInput].reduce((acc, char) => {
		return char.charCodeAt(0) + ((acc << 5) - acc);
	}, 0);
	return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
}

export function getInitialsFromString(stringInput): string {
	//const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

	// Using regex literal with flags directly instead of RegExp constructor
	const rgx = /(\p{L}{1})\p{L}+/gu;

	const matches = stringInput.matchAll(rgx);
	const initials = matches ? [...matches] : [];

	return ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
}
