import { Program } from '../program/program.model';
import { Tactic } from '../tactic/tactic.model';
import { User } from '../user/user.model';
import { v4 as uuidv4 } from 'uuid';
import { PermissionLevel, PermissionType } from '../../global/global.model';
import { Plan } from '../plan/plan.model';
import { Retailer } from '../retailer/retailer.model';
import { Entity } from '../entities.model';
import { PublicBudgetPeriod } from '../../../../../../api/src/budget-period/budget-period.entity';

/**
 * Panel model
 * A panel is an entity that frames in a website or web widget into SIMPL.
 * They can exist on many entities, and can be nested within each other.
 */
export interface Panel {
	id: string;
	name: string;
	type: PanelTypeSlug;
	index?: number; // Used for sorting logic
	icon?: string;
	initials?: string;
	layoutLocationCode?: string; // This governs where the panel is located in the layout
	config?: any;
	fileConfig?: any;
	configOptions?: any;
	programId?: string;
	program?: Program;
	tacticId?: string;
	tactic?: Tactic;
	planId?: string;
	plan?: Plan;
	external?: boolean;
	budgetPeriodId: string;
	budgetPeriod: PublicBudgetPeriod;
	customPermission: {
		permissionType: PermissionType;
		permissionLevel: PermissionLevel;
		roles: string[];
		retailerIds: Retailer['id'][];
	},
	authorId: string;
	author: User;
	parentPanelId?: string; // Used if this is a nested panel
	created?: string;
	embedCodeRaw?: string; // Raw reference to the embed code provided
	src?: string;
	customLabel?: string; // Used for statically composed panels
	/** Don't allow editing or adding new panels to this Panel */
	readOnly?: boolean;
	isTableau?: boolean; // Used for tableau hacks
	files: File[]; // Used for File type panels
}

/**
 * Panels all have a particular type.
 *   embedCode means the user has copied an <embed> tag into the panel.
 *   frameURL means the user has entered a URL into the panel.
 *   group is used if this panel is merely a container of other panels.  Useful for nesting many panels under one tab.
 *   custom is used when the application has a static panel that needs to be inserted into the panel container.
 */
export interface PanelType {
	id: string;
	name: string;
	steps: PanelSteps;
}


/**
 * Panels all have a particular type.
 *   EmbedCode means the user has copied an <embed> tag into the panel.
 *   FrameURL means the user has entered a URL into the panel.
 *   Group is used if this panel is merely a container of other panels.  Useful for nesting many panels under one tab.
 *   Custom is used when the application has a static panel that needs to be inserted into the panel container.
 */
export enum PanelTypeSlug {
	File = 'file',
	EmbedCode = 'embedCode',
	FrameURL = 'frameURL',
	Group = 'group',
	Custom = 'custom'
}

/**
 * Panel Steps sets the number and type of content modules for each panel type
 */
export type PanelSteps = PanelContentModule[];

/**
 * Panel content modules describe the different UI that facilitates a panel type to get all
 * of the data it needs to be created / edited.
 */
export enum PanelContentModule {
	Metadata = 'Metadata',
	EmbedCode = 'Embed Code',
	FilePicker = 'Choose a File',
	CCP = 'Choose a CCP Module',
}

/**
 * Panels are attached to varioud entities in the system.
 */
export type PanelEntityType = 'program' | 'tactic' | 'plan' | 'budgetPeriod' | 'organization';

/**
 * Depending on the panel entity type, we need to use different API endpoints to modify the panel.
 */
export const panelEndpoints = {
	organization: '/panel',
	budgetPeriod: '/budget-period/:id/panel',
	plan: '/plan/:id/panel',
	program: '/program/:id/panel',
	tactic: '/tactic/:id/panel'
};

export interface setCurrentPanelEvent {
	panel: Panel;
	index: number;
}

/**
 * Create a blank panel.  Default values go here.
 */
export function createPanel(params: Partial<Panel>) {
	return {
		id: uuidv4(),
		...params
	} as Panel;
}

/**
 * Possible values for Panel categories.
 */
 export type PanelCategoryValue = 'programDetailsTab' | 'programBudgetTab' | 'programReportingTab' | 'programObjectivesTab';

/**
 * Panel entities are grouped by a category.  PanelCategory items come from a static list below.
 */
 export interface PanelCategory extends Entity {
	id: PanelCategoryValue;
	name: string;
}
