import { StringUtils } from '../../_core/utils/utils.string';

export class Map {
	public static getParentId(id: string, map?: string) {
		if(!map?.length) {
			return null;
		}

		let ancestors = map.split(`.${StringUtils.dashToUnderscore(id)}`)[0];
		const parent = (ancestors.includes('.')) ? ancestors.slice(ancestors.lastIndexOf('.') + 1) : ancestors;

		return StringUtils.underscoreToDash(parent);
	}
}

