import {
	Column,
	Entity,
	JoinColumn,
	JoinTable,
	ManyToMany,
	ManyToOne,
	PrimaryGeneratedColumn
} from 'typeorm';
import { Plan, PublicPlan } from '../plan/plan.entity';
import { Program, PublicProgram } from '../program/program.entity';
import { PublicTactic, Tactic } from '../tactic/tactic.entity';
import { PublicUser, User } from '../user/user.entity';
import { Sortable } from '../_core/decorators/sortable.decorator';

export enum MilestoneType {
	Default = 'default',
	Task = 'task'
}
export enum MilestoneStatus {
	Open = 'open',
	Completed = 'completed'
}

export type PublicMilestone = Pick<
	Milestone,
	| 'id'
	| 'name'
	| 'description'
	| 'start'
	| 'end'
	| 'type'
	| 'status'
	| 'planId'
	| 'programId'
	| 'tacticId'
	| 'modified'
	| 'modifiedById'
	| 'created'
	| 'authorId'
> & {
	assignedTo?: PublicUser[];
	plan?: PublicPlan;
	program?: PublicProgram;
	tactic?: PublicTactic;
	author?: PublicUser;
	modifiedBy?: PublicUser;
};

@Entity('milestones')
export class Milestone {
	constructor(value?: Partial<Milestone>) {
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	name: string;

	@Column('text', { nullable: true })
	description?: string;

	@Column({ type: 'timestamptz', nullable: false })
	@Sortable
	start: string;

	@Column({ type: 'timestamptz', nullable: false })
	@Sortable
	end: string;

	@ManyToMany(() => User, {
		nullable: true,
		cascade: true,
		onDelete: 'CASCADE'
	})
	@JoinTable({ name: 'milestoneAssignees' })
	assignedTo: User[] | Partial<User>[];

	@Column({ type: 'enum', enum: MilestoneType, nullable: false, default: MilestoneType.Default })
	type: MilestoneType;

	@Column({ type: 'enum', enum: MilestoneStatus, nullable: false, default: MilestoneStatus.Open })
	status: MilestoneStatus;

	@Column('text', { nullable: true })
	planId?: string;
	@ManyToOne(() => Plan, {
		nullable: true,
		orphanedRowAction: 'delete',
		onDelete: 'CASCADE'
	})
	@JoinColumn({ name: 'planId' })
	plan?: Plan | Partial<Plan>;

	@Column('text', { nullable: true })
	programId?: string;
	@ManyToOne(() => Program, {
		nullable: true,
		orphanedRowAction: 'delete',
		onDelete: 'CASCADE'
	})
	@JoinColumn({ name: 'programId' })
	program?: Program | Partial<Program>;

	@Column('text', { nullable: true })
	tacticId?: string;
	@ManyToOne(type => Tactic, {
		nullable: true,
		onDelete: 'CASCADE'
	})
	@JoinColumn({ name: 'tacticId' })
	tactic?: Tactic | Partial<Tactic>;

	@Column('text', { nullable: true })
	modifiedById?: string;
	@ManyToOne(type => User, {
		onDelete: 'CASCADE',
		nullable: true
	})
	@JoinColumn({ name: 'modifiedById' })
	modifiedBy?: User | Partial<User>;

	@Column({ type: 'timestamptz', nullable: false, default: () => 'NOW()' })
	@Sortable
	modified: string;

	@Column({
		type: 'timestamptz',
		nullable: false,
		default: () => 'NOW()'
	})
	created: string;

	@Column('text', { nullable: false })
	authorId: string;
	@ManyToOne(
		() => User,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'authorId' })
	author: User | Partial<User>;

	public toPublic() {
		const pub: Partial<PublicMilestone> = {
			id: this.id,
			name: this.name,
			description: this.description,
			start: this.start,
			end: this.end,
			type: this.type,
			status: this.status,
			planId: this.planId,
			programId: this.programId,
			tacticId: this.tacticId,
			modified: this.modified,
			modifiedById: this.modifiedById,
			created: this.created,
			authorId: this.authorId
		};

		if(this.assignedTo?.length) {
			pub.assignedTo = this.assignedTo.map(a => new User(a).toPublic());
		}

		if(this.plan) {
			pub.plan = new Plan(this.plan).toPublic();
		}

		if(this.program) {
			pub.program = new Program(this.program).toPublic();
		}

		if(this.tactic) {
			pub.tactic = new Tactic(this.tactic).toPublic();
		}

		if(this.author) {
			pub.author = new User(this.author).toPublic();
		}

		if(this.modifiedBy) {
			pub.modifiedBy = new User(this.modifiedBy).toPublic();
		}

		return pub as PublicMilestone;
	}
}
