import { Column, Entity, Index, PrimaryGeneratedColumn } from 'typeorm';
import { PublicBudgetCache, BudgetCache } from '../budget-cache/budget-cache.entity';
import { Sortable } from '../_core/decorators/sortable.decorator';

export type PublicAgency = Pick<Agency, 'id' | 'name'> & {
	budgetCache?: PublicBudgetCache
};

@Entity('agencies')
@Index(['remoteId'], { unique: true })
export class Agency {
	constructor(value?: Partial<Agency>) {
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	remoteId: string;

	@Column('text', { nullable: false })
	@Sortable
	name: string;

	budgetCache?: BudgetCache;

	@Column('text', { nullable: true })
	color?: string;

	public toPublic(): PublicAgency {
		let pub: PublicAgency = {
			id: this.id,
			name: this.name
		};

		if(this.budgetCache) {
			pub.budgetCache = new BudgetCache(this.budgetCache).toPublic();
		}

		return pub;
	}
}