import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Program } from './program.model';

export interface ProgramState extends EntityState<Program>, ActiveState {
	loadingRecommendations: boolean;
	loadingKeyLearnings: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'program' })
export class ProgramStore extends EntityStore<ProgramState> {
	constructor() {
		super();
	}
}
