import {
	Column,
	Entity,
	PrimaryGeneratedColumn,
	Index
} from 'typeorm';

export enum MediaType {
	Paid = 'paid',
	Earned = 'earned',
	Shared = 'shared',
	Owned = 'owned'
}

export type PublicBaseTacticType = Pick<
	BaseTacticType,
	'id' | 'name' | 'mediaType'
> & {
	// TODO?
};

@Entity('baseTacticTypes')
@Index(['remoteId'], { unique: true })
export class BaseTacticType {
	constructor(value?: Partial<BaseTacticType>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: true })
	remoteId: string;

	@Column('text', { nullable: false })
	name: string;

	@Column({
		type: 'enum',
		enum: MediaType,
		nullable: false,
		default: MediaType.Paid
	})
	mediaType: MediaType;

	@Column('boolean', { nullable: false, default: false })
	hidden: boolean;

	public toPublic(): PublicBaseTacticType {
		const pub: Partial<PublicBaseTacticType> = {
			id: this.id,
			name: this.name,
			mediaType: this.mediaType
		};

		return pub as PublicBaseTacticType;
	}
}
