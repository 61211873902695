import {
	Entity,
	PrimaryGeneratedColumn,
	Column,
	Index,
	ManyToOne,
	ManyToMany,
	JoinColumn,
	JoinTable
} from 'typeorm';

import { Sortable } from '../_core/decorators/sortable.decorator';

import { Organization } from '../organization/organization.entity';
import { Brand, PublicBrand } from '../brand/brand.entity';

export type PublicCategory = Pick<
	Category,
	'id' | 'name'
> & {
	brands?: PublicBrand[]
};

@Entity('categories')
@Index(['remoteId', 'organizationId'], { unique: true })
@Index(['organizationId', 'hidden'])
export class Category {
	constructor(value?: Partial<Category>) {
		for(let k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	remoteId: string;

	@Column('text', { nullable: false })
	@Sortable
	name: string;

	@Column('text', { nullable: false })
	organizationId: string;
	@ManyToOne(
		type => Organization,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'organizationId' })
	organization: Organization;

	@ManyToMany(
		() => Brand,
		{
			eager: true,
			nullable: true,
			cascade: true
		}
	)
	@JoinTable({ name: 'categoryBrands' })
	brands?: Brand[] | Partial<Brand>[];

	@Column('boolean', { nullable: false, default: false })
	hidden: boolean;

	public toPublic(): PublicCategory {
		let pub: Partial<PublicCategory> = {
			id: this.id,
			name: this.name
		};

		if(this.brands?.length) {
			pub.brands = (this.brands as Brand[])
				.map(b => {
					return new Brand(b).toPublic();
				});
		}

		return pub as PublicCategory;
	}
}