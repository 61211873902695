import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

// tslint:disable-next-line:nx-enforce-module-boundaries
import { SessionQuery } from '../../state/session/session.query';
import { SessionState } from '../../state/session/session.model';
import { UserRole } from '../../state/global/global.model';

@Directive({
	selector: '[hasRoles]'
})
export class HasRolesDirective implements OnDestroy {
	private subs: Subscription;
	private hasView = false;
	private authUser$: Observable<SessionState>;

	constructor(
		private readonly templateRef: TemplateRef<any>,
		private readonly viewContainer: ViewContainerRef,
		private readonly sessionQuery: SessionQuery
	) {
		this.authUser$ = this.sessionQuery.select();
	}

	@Input() set hasRoles(allowedRoles: UserRole[]) {
		this.subs = this.authUser$.subscribe(sessionState => {
			if (!sessionState) {
				this.viewContainer.clear();
			} else if (!sessionState?.profile?.role) {
				this.viewContainer.clear();
			} else if (allowedRoles.includes(sessionState.profile.role)) {
				if (!this.hasView) {
					this.viewContainer.createEmbeddedView(this.templateRef);
				}
				this.hasView = true;
			} else {
				this.viewContainer.clear();
				this.hasView = false;
			}
		});
	}

	ngOnDestroy(): void {
		if (this.subs) {
			this.subs.unsubscribe();
		}
	}
}
