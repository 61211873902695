export class ObjectUtils {
	public static addNewFieldToObject<T>(oldEntity: T, newEntity: T, fieldName: string, oldValue: any, newValue: any): [T, T] {
		oldEntity[fieldName] = this.deepCopy(oldValue);
		newEntity[fieldName] = this.deepCopy(newValue);

		return [newEntity, oldEntity];
	}

	public static updateField<T>(entity: T, fieldName: string, value: any): T {
		if (entity[fieldName] != null) {
			entity[fieldName] = this.deepCopy(value);
		}

		return entity;
	}

	/**
	 * Safely traverse through an object with a dot notated path string
	 * @param path
	 * @param obj
	 */
	public static resolveDotNotationPath(path, obj) {
		if (!path) {
			return null;
		}
		if (path.includes('|')) {
			path = path.split('|')[0];
		}
		return path.split('.').reduce((acc, cur, idx, arr) => {
			if (arr.length === 1) {
				return acc[cur];
			}
			if (typeof acc !== 'object') {
				return acc;
			}
			if (acc[cur]) {
				if (Array.isArray(acc[cur])) {
					const items = [];
					for (const i in acc[cur]) {
						const item = acc[cur][i][arr[idx + 1]];
						if (item && typeof item !== 'object') {
							items.push(item);
						}
					}
					return items.join(', ');
				}
				return acc[cur];
			}
			return undefined;
		}, obj);
	}

	public static updateFields<T>(oldEntity: T, newEntity: T): T {
		for (const field in oldEntity) {
			oldEntity[field] = this.deepCopy(newEntity[field]);
		}

		return oldEntity;
	}

	public static getPartialObject<T>(entity: T, fields: Array<keyof T>) {
		const newEntity: T = {} as T;
		fields.forEach(field => (newEntity[field] = this.deepCopy(entity[field])));

		return newEntity;
	}

	public static deepCopy<T>(value: T): any {
		if (value instanceof Date) {
			return value.toISOString();
		}
		if (typeof value !== 'object' || value === null) {
			return value;
		}
		if (Array.isArray(value)) {
			return this.deepArray(value as any[]);
		}
		return this.deepObject(value);
	}

	public static isObject(item) {
		return item && typeof item === 'object' && !Array.isArray(item);
	}

	public static isJSON(str: string) {
		try {
			return !!str && JSON.parse(str);
		} catch (e) {
			return false;
		}
	}

	public static mergeDeep(target, source) {
		if (!this.isObject(target) || !this.isObject(source)) {
			return target;
		}
		// Clone
		target = JSON.parse(JSON.stringify(target));
		source = JSON.parse(JSON.stringify(source));
		for (const [k, v] of Object.entries(source)) {
			if (this.isObject(v)) {
				if (typeof target[k] === 'undefined') {
					target[k] = new (Object.getPrototypeOf(v).constructor)();
				}
				target[k] = this.mergeDeep(target[k], source[k]);
			} else {
				target[k] = v === null ? undefined : v;
			}
		}
		return target;
	}

	public static removeUndefinedOrNullProperties(obj: any): any {
		for (const prop in obj) {
			if (obj[prop] === null || obj[prop] === undefined) {
				delete obj[prop];
			} else if (typeof obj[prop] === 'object') {
				this.removeUndefinedOrNullProperties(obj[prop]);
			}
		}
		return obj;
	}

	public static isArrayEmpty<T>(array: Array<T> | undefined): boolean {
		return !array || array.length === 0;
	}

	public static firstOrDefault<T>(array: Array<T>, defaultValue: T | null = null): T | null {
		if (this.isArrayEmpty(array)) {
			return defaultValue;
		}

		return array[0];
	}

	public static uniqWith(arr, fn) {
		return arr.filter((element, index) => arr.findIndex(step => fn(element, step)) === index);
	}

	public static uniqueArray(array, key): any[] {
		const seen = new Set();
		return array.filter(item => {
			const value = item[key];
			if (!seen.has(value)) {
				seen.add(value);
				return true;
			}
			return false;
		});
	}

	public static sortByProperty(array, property, direction) {
		const sortOrder = direction === 'asc' ? 1 : -1;

		array.sort((a, b) => {
			const aValue = this.getNestedPropertyValue(a, property);
			const bValue = this.getNestedPropertyValue(b, property);

			if (aValue < bValue) {
				return -1 * sortOrder;
			} else if (aValue > bValue) {
				return 1 * sortOrder;
			} else {
				return 0;
			}
		});

		return array;
	}

	// Helper function to get nested property value
	public static getNestedPropertyValue(obj, property) {
		const properties = property.split('.');
		return properties.reduce((value, prop) => (value && value[prop] !== undefined ? value[prop] : undefined), obj);
	}

	public static deepEqual(obj1: unknown, obj2: unknown): boolean {
		if (obj1 === obj2) {
			return true;
		}

		if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
			return false;
		}

		const keys1 = Object.keys(obj1);
		const keys2 = Object.keys(obj2);

		if (keys1.length !== keys2.length) {
			return false;
		}

		for (const key of keys1) {
			if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
				return false;
			}
		}

		return true;
	}

	private static deepObject<T>(source: T) {
		const result = {};
		Object.keys(source).forEach(key => {
			const value = source[key];
			result[key] = this.deepCopy(value);
		}, {});
		return result as T;
	}

	private static deepArray<T extends any[]>(collection: T) {
		return collection.map(value => {
			return this.deepCopy(value);
		});
	}
}
