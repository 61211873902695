import {
	Column,
	Entity,
	PrimaryGeneratedColumn,
	Index
} from 'typeorm';

export type PublicCostType = Pick<CostType, 'id' | 'name'>;

@Entity('costTypes')
@Index(['remoteId'], { unique: true })
export class CostType {
	constructor(value?: Partial<CostType>) {
		for(let k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	remoteId: string;

	@Column('text', { nullable: false })
	name: string;

	public toPublic(): PublicCostType {
		return {
			id: this.id,
			name: this.name
		};
	}
}