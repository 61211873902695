import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { Organization } from '../organization/organization.entity';

export type PublicTacticAgency = Pick<TacticAgency, 'id' | 'name' | 'order'>;

@Entity('tacticAgencies')
export class TacticAgency {
  constructor(value?: Partial<TacticAgency>) {
    if (value) {
      value = JSON.parse(JSON.stringify(value));
    }
    for (const k in value) {
      this[k] = value[k];
    }
  }

  @PrimaryGeneratedColumn('uuid')
  id: string;

  @Column('text')
  name: string;

  @Column('text')
  organizationId: string;
  @ManyToOne(() => Organization, { onDelete: 'CASCADE', eager: true })
  @JoinColumn({ name: 'organizationId' })
  organization: Organization;

  @Column('integer')
  order: number;

  public toPublic(): PublicTacticAgency {
    return {
      id: this.id,
      name: this.name,
      order: this.order
    };
  }
}
