import {
	Entity,
	PrimaryGeneratedColumn,
	Column,
	ManyToOne,
	JoinColumn,
	Index,
	ManyToMany,
	JoinTable
} from 'typeorm';
import { FundingType, PublicFundingType } from '../funding-type/funding-type.entity';

import { Organization } from '../organization/organization.entity';

export type PublicFundingSource = Pick<
	FundingSource,
	'id' | 'name'
> & {
	fundingTypes?: PublicFundingType[]
};

@Entity('fundingSources')
@Index(['remoteId', 'organizationId'], { unique: true })
@Index(['organizationId', 'hidden'])
export class FundingSource {
	constructor(value?: Partial<FundingSource>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	remoteId: string;

	@Column('text', { nullable: false })
	name: string;

	@Column('text', { nullable: false })
	organizationId: string;
	@ManyToOne(
		type => Organization,
		{
			eager: false,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'organizationId' })
	organization: Organization;

	@ManyToMany(
		() => FundingType,
		{
			eager: true,
			nullable: true
		}
	)
	@JoinTable({ name: 'fundingSourceTypes' })
	fundingTypes?: FundingType[] | Partial<FundingType>[];

	@Column('boolean', { nullable: false, default: false })
	hidden: boolean;

	public toPublic(): PublicFundingSource {
		const pub: Partial<PublicFundingSource> = {
			id: this.id,
			name: this.name
		};

		if(this.fundingTypes?.length) {
			pub.fundingTypes = (this.fundingTypes as FundingType[])?.map(t => new FundingType(t).toPublic());
		}

		return pub as PublicFundingSource;
	}
}