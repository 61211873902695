import { ApiProperty } from '@nestjs/swagger';
import { IsEnum } from 'class-validator';
import {
	Entity,
	Column,
	PrimaryGeneratedColumn,
	Unique,
	ManyToOne,
	JoinColumn,
	Relation
} from 'typeorm';

import type { User } from '../user/user.entity';

export enum PermissionLevel {
	Read = 'read',
	Write = 'write',
	Maintain = 'maintain'
}

export enum PermissionType {
	Retailer = 'retailer',
	Agency = 'agency'
}

//@Entity('permissions')
export class Permission {
	constructor(value?: Partial<Permission>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string = null;

	@Column('text')
	userId: string = null;
	@ManyToOne(
		'User',
		{
			orphanedRowAction: 'delete',
			nullable: false,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'userId' })
	user: User | Partial<User>;

	@Column({
		type: 'enum',
		enum: PermissionLevel,
		default: PermissionLevel.Read
	})
	permissionLevel: PermissionLevel = null;
}
