import { ApiProperty } from '@nestjs/swagger';
import QueryFragmentGenerator from '../../_core/interfaces/query-fragment-generator.class';

import { SortStrategy } from '../../find/models/find.models';

export class WhereOptions {
	@ApiProperty({ required: false })
	planAlias?: string;
	@ApiProperty({ required: false })
	programAlias?: string;
	@ApiProperty({ required: false })
	tacticAlias?: string;
	@ApiProperty({ required: false })
	brandAlias?: string;
	@ApiProperty({ required: false })
	planMatch?: boolean;
	@ApiProperty({ required: false })
	programMatch?: boolean;
	@ApiProperty({ required: false })
	tacticMatch?: boolean;
	@ApiProperty({ required: false })
	brandMatch?: boolean;
}

export enum OrderKey {
	AmountEstimated = 'amountEstimated',
	AmountPlanned = 'amountPlanned',
	AmountActual = 'amountActual',
	SpendEstimated = 'spendEstimated',
	SpendActual = 'spendActual',
}

export class BudgetCacheFragmentGenerator extends QueryFragmentGenerator {
	public readonly aliasDefault: string = 'bc2';
	private optionsDefault: WhereOptions = {
		planAlias: 'pl',
		programAlias: 'p',
		tacticAlias: 't',
		brandAlias: 'b',
	};

	constructor(whereOptions?: WhereOptions) {
		super();
		this.optionsDefault = { ...this.optionsDefault, ...whereOptions };
	}

	public getFrom(whereOptions: WhereOptions, alias: string = this.aliasDefault) {
		whereOptions = { ...this.optionsDefault, ...whereOptions };

		const fragment = `
			, LATERAL (
				SELECT (
					SELECT
						JSON_BUILD_OBJECT (
							'amountEstimated', ${alias}2."amountEstimated",
							'amountPlanned', ${alias}2."amountPlanned",
							'amountActual', ${alias}2."amountActual",
							'spendEstimated', ${alias}2."spendEstimated",
							'spendActual', ${alias}2."spendActual"
						)
					FROM
						"budgetCaches" AS ${alias}2
					WHERE
						${this.getWhere(whereOptions, alias)}
					ORDER BY ${alias}2.created DESC
					LIMIT 1
				) AS "cacheValues"
			) AS ${alias}
		`;

		return fragment;
	}

	public getGroupBy(orderKey: OrderKey, alias: string = this.aliasDefault) {
		if (!Object.values(OrderKey).includes(orderKey)) {
			orderKey = Object.values(OrderKey)[0];
		}
		return `${alias}."cacheValues" ->> '${orderKey}'`;
	}

	public getOrderBy(
		orderKey: OrderKey,
		strategy: SortStrategy,
		alias: string = this.aliasDefault,
		skipStrategy?: boolean,
		isOuterOrder?: boolean
	) {
		if (!Object.values(OrderKey).includes(orderKey) && !isOuterOrder) {
			orderKey = Object.values(OrderKey)[0];
		}

		if (isOuterOrder) {
			if (skipStrategy) {
				return `(${alias}."${orderKey}")::numeric`;
			}
			return `(${alias}."${orderKey}")::numeric ${strategy} ${strategy === SortStrategy.ASC ? 'NULLS FIRST' : 'NULLS LAST'}`;
		}

		if (skipStrategy) {
			return `(${alias}."cacheValues" ->> '${orderKey}')`;
		}
		return `(${alias}."cacheValues" ->> '${orderKey}')::numeric ${strategy}`;
	}

	private getWhere(whereOptions: WhereOptions, alias: string) {
		let where = '';

		let firstWhere = true;

		if (typeof whereOptions.planMatch === 'boolean' && whereOptions.planMatch) {
			const whereCondition = whereOptions.planMatch ? `= ${whereOptions.planAlias}.id` : ' IS NOT NULL';
			where += `
				${firstWhere ? '' : 'AND '} ${alias}2."planId" ${whereCondition}
			`;
			firstWhere = false;
		} else if (
			typeof whereOptions.programMatch === 'boolean' &&
			!whereOptions.programMatch &&
			typeof whereOptions.tacticMatch === 'boolean' &&
			!whereOptions.tacticMatch
		) {
			where += `
				${firstWhere ? '' : 'AND '} ${alias}2."planId" IS NULL
			`;
			firstWhere = false;
		}

		if (typeof whereOptions.programMatch === 'boolean' && whereOptions.programMatch) {
			const whereCondition = whereOptions.programMatch ? `= ${whereOptions.programAlias}.id` : ' IS NOT NULL';
			where += `
				${firstWhere ? '' : 'AND '} ${alias}2."programId" ${whereCondition}
			`;
			firstWhere = false;
		} else if (
			(typeof whereOptions.tacticMatch === 'boolean' && !whereOptions.tacticMatch) ||
			(typeof whereOptions.planMatch === 'boolean' && whereOptions.planMatch)
		) {
			where += `
				${firstWhere ? '' : 'AND '} ${alias}2."programId" IS NULL
			`;
			firstWhere = false;
		}

		if (typeof whereOptions.tacticMatch === 'boolean' && whereOptions.tacticMatch) {
			const whereCondition = whereOptions.tacticMatch ? `= ${whereOptions.tacticAlias}.id` : ' IS NOT NULL';
			where += `
				${firstWhere ? '' : 'AND '} ${alias}2."tacticId" ${whereCondition}
			`;
			firstWhere = false;
		} else {
			where += `
				${firstWhere ? '' : 'AND '} ${alias}2."tacticId" IS NULL
			`;
			firstWhere = false;
		}

		if (typeof whereOptions.brandMatch === 'boolean' && whereOptions.brandMatch) {
			const whereCondition = whereOptions.brandMatch ? `= ${whereOptions.brandAlias}.id` : ' IS NOT NULL';
			where += `
				${firstWhere ? '' : 'AND '} ${alias}2."brandId" ${whereCondition}
			`;
			firstWhere = false;
		} else {
			where += `
				${firstWhere ? '' : 'AND '} ${alias}2."brandId" IS NULL
			`;
			firstWhere = false;
		}

		if (
			typeof whereOptions.planMatch === 'boolean' &&
			!whereOptions.planMatch &&
			typeof whereOptions.programMatch === 'boolean' &&
			whereOptions.programMatch
		) {
			where += `
				${firstWhere ? '' : 'AND '} ${alias}2."planId" IS NULL
			`;
			firstWhere = false;
		}

		return where;
	}
}
