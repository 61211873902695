import { Injectable } from '@angular/core';
import { SessionStore } from './session.store';
import { catchError, tap } from 'rxjs/operators';
import { LoginResponse, VerifyResponse } from './session.model';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import { of, throwError } from 'rxjs';
import { AccessToken, IDToken } from '@okta/okta-auth-js';

/**
 * Session Service
 * This service is responsible for the session logic and API calls.
 */
@Injectable({
	providedIn: 'root'
})
export class SessionService {
	public defaultHeaders = new HttpHeaders({
		Accept: 'application/json'
	});

	constructor(private sessionStore: SessionStore, protected httpClient: HttpClient) {}

	/**
	 * Verify a user's email on our system and then send the email a code
	 * to enter to verify that it is valid and they have access to it.
	 */
	public requestCode(email: string) {
		const headers = this.defaultHeaders;
		this.sessionStore.setLoading(true);

		return this.httpClient
			.post<VerifyResponse>(
				`${environment.apiUrl}/user/auth/request-sign-in`,
				{ email, organizationId: environment.organizationId },
				{ headers }
			)
			.pipe(
				tap(response => {
					this.sessionStore.setLoading(false);
					this.sessionStore.updateLoginDetails({
						clientId: response.clientId,
						issuer: response.issuer,
						profile: {
							...this.sessionStore.getValue().profile,
							email
						}
					});
				}),
				catchError(err => {
					console.log('Login Error', err);
					this.sessionStore.setLoading(false);
					this.sessionStore.setError(err);
					return throwError(err);
				})
			);
	}

	/**
	 * Send the code provided by the user to make sure its valid, then return an access token.
	 */
	public activateEmail(email: string, singlePass: string, hasAcceptedTerms?: boolean) {
		// http call to verify code
		const params = {
			singlePass,
			email,
			organizationId: environment.organizationId,
			hasAcceptedTerms
		};
		const headers = this.defaultHeaders;
		this.sessionStore.setLoading(true);

		return this.httpClient.post<LoginResponse>(`${environment.apiUrl}/user/auth/basic/code-sign-in`, params, { headers }).pipe(
			tap(resp => {
				this.sessionStore.login({
					token: resp.token,
					profile: resp.profile
				});

				Sentry.setUser({ email: resp?.profile?.email });

				this.sessionStore.setLoading(false);
			})
		);
	}

	/**
	 * Attempt to sign in via okta based on the org settings.
	 */
	public oktaSignIn(email: string, accessToken: AccessToken, idToken: IDToken) {
		const headers = this.defaultHeaders;
		this.sessionStore.setLoading(true);

		return this.httpClient
			.post<LoginResponse>(
				`${environment.apiUrl}/user/auth/okta/sign-in`,
				{
					email,
					organizationId: environment.organizationId,
					accessToken,
					idToken
				},
				{ headers }
			)
			.pipe(
				tap(resp => {
					this.sessionStore.login({
						token: resp.token,
						profile: resp.profile
					});

					Sentry.setUser({ email: resp?.profile?.email });

					this.sessionStore.setLoading(false);
				})
			);
	}

	public serverToServerSignIn(organizationId: string, userId: string, token: string) {
		const headers = this.defaultHeaders;
		this.sessionStore.setLoading(true);

		return this.httpClient
			.post<LoginResponse>(
				`${environment.apiUrl}/user/auth/server-to-server/sign-in`,
				{
					organizationId,
					userId,
					token
				},
				{
					headers
				}
			)
			.pipe(
				tap(resp => {
					this.sessionStore.login({
						token: resp.token,
						profile: resp.profile
					})
					Sentry.setUser({ email: resp?.profile?.email });
					this.sessionStore.setLoading(false);
				})
			);
	}

	public azureSignIn(organizationId: string, userId: string, token: string) {
		const headers = this.defaultHeaders;
		this.sessionStore.setLoading(true);

		return this.httpClient
			.post<LoginResponse>(
				`${environment.apiUrl}/user/auth/azure/sign-in`,
				{
					organizationId,
					userId,
					token
				},
				{
					headers
				}
			)
			.pipe(
				tap(resp => {
					this.sessionStore.login({
						token: resp.token,
						profile: resp.profile
					})
					Sentry.setUser({ email: resp?.profile?.email });
					this.sessionStore.setLoading(false);
				})
			);
	}

	/**
	 * Check a user's token for validity, return user profile.
	 */
	public getUserStatus(token: string) {
		console.log('Getting User Status');
		return this.httpClient.get<LoginResponse>(`${environment.apiUrl}/user/refresh`).pipe(
			tap((resp: LoginResponse) => {
				this.sessionStore.login({
					token: resp.token,
					profile: resp.profile
				});

				Sentry.setUser({ email: resp?.profile?.email });
			})
		);
	}

	/**
	 * Set the Akita loading state.
	 */
	public setLoading(state: boolean) {
		this.sessionStore.setLoading(state);
	}

	/**
	 * Set the url the user was attempting to go to before having to login.
	 */
	public setInitialUrl(url: string) {
		this.sessionStore.update({
			initialUrl: url
		});
	}

	/**
	 * Remove the users's access token.
	 */
	public logout() {
		this.sessionStore.logout();
	}
}
