import {
	Column,
	Entity,
	PrimaryGeneratedColumn,
	Index
} from 'typeorm';

import { Sortable } from '../_core/decorators/sortable.decorator';

export type PublicProgramSector = Pick<ProgramSector, 'id' | 'name'>;

@Entity('programSectors')
@Index(['remoteId'], { unique: true })
export class ProgramSector {
	constructor(value?: Partial<ProgramSector>) {
		for(let k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	remoteId: string;

	@Column('text', { nullable: false })
	@Sortable
	name: string;

	public toPublic(): PublicProgramSector {
		return {
			id: this.id,
			name: this.name
		};
	}
}