import {
	Column,
	Entity,
	PrimaryGeneratedColumn,
	Index
} from 'typeorm';

import { Sortable } from '../_core/decorators/sortable.decorator';

export type PublicProgramPhase = Pick<ProgramPhase, 'id' | 'name' | 'color' | 'order'>;

@Entity('programPhases')
@Index(['remoteId'], { unique: true })
export class ProgramPhase {
	constructor(value?: Partial<ProgramPhase>) {
		for(let k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	remoteId: string;

	@Column('text', { nullable: false })
	@Sortable
	name: string;

	@Column('text', { nullable: true })
	color?: string;

	@Column('integer', { nullable: true })
	order?: number;

	public toPublic(): PublicProgramPhase {
		return {
			id: this.id,
			name: this.name,
			color: this.color,
			order: this.order
		};
	}
}
