export enum ExternalIdSelect {
	ExternalIdType = 'externalIdType',
	Author = 'author',
}

export class Query {
	private static readonly EXTERNAL_ID_QUERY_ALIAS: string = 'eid';

	public static getSelects(
		alias: string = this.EXTERNAL_ID_QUERY_ALIAS,
		targets: ExternalIdSelect[] = Object.values(ExternalIdSelect),
		asJsonSelect: boolean = false
	) {
		if(!targets) {
			targets = [];
		}
		const selects: string[] = [];
		const json: boolean = asJsonSelect;

		selects.push(`
			${(json) ? `'id', ${alias}.id` : `${alias}.id`}
		`);
		selects.push(`
			${(json) ? `'value', ${alias}.value` : `${alias}.value`}
		`);
		selects.push(`
			${(json) ? `'planId', ${alias}."planId"` : `${alias}."planId"`}
		`);
		selects.push(`
			${(json) ? `'programId', ${alias}."programId"` : `${alias}."programId"`}
		`);
		selects.push(`
			${(json) ? `'tacticId', ${alias}."tacticId"` : `${alias}."tacticId"`}
		`);
		selects.push(`
			${(json) ? `'invoiceId', ${alias}."invoiceId"` : `${alias}."invoiceId"`}
		`);
		selects.push(`
			${(json) ? `'externalIdTypeId', ${alias}."externalIdTypeId"` : `${alias}."externalIdTypeId"`}
		`);
		selects.push(`
			${(json) ? `'authorId', ${alias}."authorId"` : `${alias}."authorId"`}
		`);
		selects.push(`
			${(json) ? `'created', ${alias}.created` : `${alias}.created`}
		`);
		
		if(targets.includes(ExternalIdSelect.ExternalIdType)) {
			if(json) {
				selects.push(`
					'externalIdType', ${alias}_externalidtype."externalIdType"
				`);
			} else {
				selects.push(`
					${alias}_externalidtype."externalIdType" AS "externalIdType"
				`);
			}
		}

		if(targets.includes(ExternalIdSelect.Author)) {
			if(json) {
				selects.push(`
					'author', ${alias}_author.author
				`);
			} else {
				selects.push(`
					${alias}_author.author AS author
				`);
			}
		}

		return selects.join(',');
	}

	public static getSubqueries(
		alias: string = this.EXTERNAL_ID_QUERY_ALIAS,
		targets: ExternalIdSelect[] = Object.values(ExternalIdSelect)
	) {
		if(!targets?.length) {
			return '';
		}

		const subQueries: string[] = [];

		if(targets.includes(ExternalIdSelect.ExternalIdType)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSON_BUILD_OBJECT (
								'id', eidt.id,
								'name', eidt.name
							)
						FROM
							"externalIdTypes" AS eidt
						WHERE
							eidt.id = ${alias}."externalIdTypeId"
					) AS "externalIdType"
				) AS ${alias}_externalidtype
			`);
		}

		if(targets.includes(ExternalIdSelect.Author)) {
			subQueries.push(`
				LATERAL (
					SELECT 
						JSON_BUILD_OBJECT (
							'id', u2.id,
							'email', u2."email",
							'profile', u2."profile"
						) AS "author"
					FROM
						"users" AS u2
					WHERE
						u2.id = ${alias}."authorId"
				) AS ${alias}_author
			`);
		}

		if(!subQueries.length) {
			return '';
		}

		return ',\n' + subQueries.join(',');
	}
}