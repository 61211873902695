import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { Organization, PublicOrganization } from '../organization/organization.entity';

export enum LocationType {
	Region = 'region',
	Area = 'area',
	Country = 'country',
	AdministrativeAreaLevel1 = 'administrativeAreaLevel1', // State
	AdministrativeAreaLevel2 = 'administrativeAreaLevel2', // County
	Locality = 'locality' // City
}

export const LocationTypeMap = Object.entries(LocationType)
	.reduce((acc, cur, idx) => {
		acc[cur[1]] = idx;
		return acc;
	}, {});

export type PublicLocation<LocationType> = Pick<Location<LocationType>,
	'id' | 'type' | 'organizationId' | 'name' | 'map' | 'disabled'
> & {
	organization?: PublicOrganization,
	parent?: PublicLocation<any>
}

@Entity('locations')
export class Location<LocationType> {
	constructor(value?: Partial<Location<LocationType>>) {
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column({
		type: 'enum',
		enum: LocationType,
		nullable: false
	})
	type: LocationType;

	@Column('text', { nullable: false })
	organizationId: string;
	@ManyToOne(
		() => Organization,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'organizationId' })
	organization: Organization;

	@Column('text', { nullable: false })
	name: string;

	parent?: Location<any>;

	@Column('ltree', { nullable: true })
	map?: string;

	@Column('boolean', { default: false })
	disabled?: boolean;

	public toPublic() {
		const pub: Partial<PublicLocation<typeof this.type>> = {
			id: this.id,
			type: this.type,
			organizationId: this.organizationId,
			name: this.name,
			map: this.map,
			disabled: this.disabled
		};

		if(this.organization) {
			pub.organization = new Organization(this.organization).toPublic();
		}

		if(this.parent) {
			pub.parent = new Location<any>(this.parent).toPublic();
		}

		return pub as PublicLocation<typeof this.type>;
	}
}