import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { TacticsPhase } from './tactics-phase.model';

export interface TacticsPhaseState extends EntityState<TacticsPhase> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tactics-phase' })
export class TacticsPhaseStore extends EntityStore<TacticsPhaseState> {
  constructor() {
    super();
  }

}
