<ng-container *ngIf="loaded">
	<!-- <app-header *ngIf="(headerSettings$ | async)?.visible"></app-header> -->
	<app-sidebar-nav *ngIf="showSidebar"></app-sidebar-nav>

	<router-outlet></router-outlet>
</ng-container>

<div class="loading" *ngIf="!loaded">
	<mat-spinner [diameter]="40"></mat-spinner>
</div>
