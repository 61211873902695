export interface WhereOptions {
	[key: string]: any;
}

export default abstract class QueryFragmentGenerator {
	/**
	 * Generates From fragment with included where options
	 *
	 * @param whereOptions
	 * @param alias
	 * @returns where clause string
	 */
	public abstract getFrom(whereOptions?: WhereOptions, alias?: string): string;

	/**
	 * Generates group by string based on orderKey
	 *
	 * @param orderKey
	 * @param alias
	 * @returns group by clause string
	 */
	public abstract getGroupBy(orderKey: string, alias?: string): string;

	/**
	 * Generates order by string based on orderKey and strategy
	 *
	 * @param orderKey which entity property to order by
	 * @param strategy ASC OR DESC
	 * @param alias entity alias
	 * @param skipStrategy if true, the strategy will be skipped in the order by clause string
	 * @param isOuterOrder if true, the order by clause string will be generated for based on alias and orderKey
	 * @returns order by clause string
	 */
	public abstract getOrderBy(
		orderKey: string,
		strategy: 'ASC' | 'DESC',
		alias?: string,
		skipStrategy?: boolean,
		isOuterOrder?: boolean
	): string;
}
