import { Column, Entity, Index, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn, Unique } from 'typeorm';

import { Utils as UserUtils } from './user.utils';
import { Organization } from '../organization/organization.entity';
import { AuthenticationStrategy } from '../authentication-strategy/authentication-strategy.entity';
import { OktaOauthToken } from './dtos/okta-login-request.dto';
import { JwtPayloadAzureOpenID, JwtPayloadServerToServer } from './auth/jwt-payload.interface';
import { EntityPermission } from '../permission/entity-permission.entity';
import { ActivationStatus, Profile, PublicProfile, UserRole } from './models';
import { Utils as PermissionUtils } from '../permission/permission.utils';
import { UpdateUserSettingsDto } from './dtos/update-user-settings.dto';

export type PublicUser = Pick<User, 'id' | 'email'> & {
	nameFirst?: string;
	nameLast?: string;
	name?: string;
	agencyUser?: boolean;
};
@Entity('users')
@Unique(['emailNormalized', 'organization'])
export class User {
	constructor(value?: Partial<User>, _privateProfile?: string, keepNulls: boolean = false) {
		if (value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for (const k in value) {
			this[k] = value[k];
		}
		if (_privateProfile) {
			this._privateProfile = _privateProfile;
		}
		if (!keepNulls) {
			this.stripNulls();
		}
	}

	@PrimaryGeneratedColumn('uuid')
	@Index()
	id: string = null;

	@Column('text')
	email: string = null;

	@Column('text')
	emailNormalized: string = null;

	@Column('text')
	organizationId: string = null;

	@ManyToOne(
		type => Organization,
		organization => organization.id,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'organizationId' })
	organization: Organization | Partial<Organization> = null;

	@Column({
		type: 'enum',
		enum: UserRole,
		default: UserRole.User
	})
	role: UserRole = null;

	@Column('boolean', { nullable: false, default: false })
	agencyUser: boolean = null;

	@Column({ type: 'timestamptz', default: () => 'NOW()' })
	created: string = null;

	@Column({
		type: 'enum',
		enum: ActivationStatus,
		default: ActivationStatus.Pending
	})
	activationStatus: ActivationStatus = null;

	@Column({ type: 'boolean', default: false })
	deactivated: boolean = null;

	@Column('text', { nullable: true })
	singlePass: string = null;

	@Column('timestamptz', { nullable: true })
	singlePassExpire: string = null;

	@Column('text', { nullable: false })
	authenticationStrategyId: string = null;

	@Column('jsonb', { nullable: true, default: '{}' })
	settings: UpdateUserSettingsDto = null;

	@ManyToOne(
		type => AuthenticationStrategy,
		authenticationStrategy => authenticationStrategy.id,
		{
			nullable: false,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'authenticationStrategyId' })
	authenticationStrategy: AuthenticationStrategy | Partial<AuthenticationStrategy> = null;

	@Column('text', { array: true, nullable: true })
	authTokens: string[] = null;

	oktaOauthToken?: OktaOauthToken = null;
	serverToServerToken?: JwtPayloadServerToServer['serverToServerToken'] = null;
	azureOpenIDToken?: JwtPayloadAzureOpenID['azureOpenIDToken'] = null;

	@Column({ type: 'timestamptz', default: () => 'NOW()' })
	lastSeen: string = null;

	@Column('jsonb', {
		nullable: true,
		default: '{}'
	})
	profile: PublicProfile = null;

	@OneToMany(
		() => EntityPermission,
		entityPermission => entityPermission.user,
		{
			nullable: true,
			cascade: true,
			onDelete: 'CASCADE'
		}
	)
	entityPermissions?: EntityPermission[] | Partial<EntityPermission>[] = null;

	@Column('text', { nullable: true })
	redirectTo?: string;

	@Column('text', {
		name: 'privateProfile',
		nullable: true
	})
	private _privateProfile: string = null;

	public get privateProfile(): Profile {
		return UserUtils.decryptProfile(this._privateProfile, this.id);
	}

	public set privateProfile(value: Profile) {
		this._privateProfile = UserUtils.encryptProfile(value, this.id);
	}

	@Column({ name: 'hasAcceptedTerms', type: 'boolean', nullable: true })
	hasAcceptedTerms?: boolean;

	@Column('boolean', { nullable: false, default: false })
	accessAllRetailers: boolean;

	public toPublic(): PublicUser {
		return {
			id: this.id,
			email: this.email,
			nameFirst: this.profile?.nameFirst,
			nameLast: this.profile?.nameLast,
			name: `${this.profile?.nameFirst ?? ''} ${this.profile?.nameLast ?? ''}`,
			agencyUser: this.agencyUser
		};
	}

	public toAdmin(adminUserScope: User) {
		const permissions = [];
		if (this.entityPermissions?.length) {
			for (const p of this.entityPermissions) {
				for (const [k, v] of Object.entries(p.entityMap)) {
					permissions.push(
						...v.map(id => {
							return {
								level: p.permissionLevel,
								type: k,
								id
							};
						})
					);
				}
			}
		}
		return {
			id: this.id,
			email: this.email,
			profile: {
				nameFirst: this.profile?.nameFirst,
				nameLast: this.profile?.nameLast
			},
			role: this.role,
			agencyUser: this.agencyUser,
			deactivated: this.deactivated,
			authenticationStrategyId: this.authenticationStrategyId,
			entityPermissions: permissions.filter(p =>
				PermissionUtils.hasEntityPermission(adminUserScope.entityPermissions as EntityPermission[], p.type, p.level, [p.id])
			),
			accessAllRetailers: this.accessAllRetailers
		};
	}

	public clean(): User {
		const keys = Object.keys(new User(undefined, undefined, true));
		const toDelete: string[] = [];
		for (const key of Object.keys(this)) {
			if (!keys.includes(key)) {
				toDelete.push(key);
			}
		}
		for (const key of toDelete) {
			delete this[key];
		}

		return this;
	}

	public stripNulls(): User {
		for (const [k, v] of Object.entries(this)) {
			if (this[k] === null) {
				delete this[k];
			}
		}
		return this;
	}
}
