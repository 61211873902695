import { ApiProperty } from '@nestjs/swagger';
import { IsString, IsUUID } from 'class-validator';
import {
	Entity,
	Column,
	PrimaryGeneratedColumn,
	Unique,
	ManyToOne,
	JoinColumn,
	Index
} from 'typeorm';
import { Organization } from '../organization/organization.entity';

export enum AuthenticationStrategyType {
	Basic = 'basic',
	Okta = 'okta',
	ServerToServer = 'serverToServer',
	AzureOpenID = 'azureOpenId'
}

export enum OktaUiType {
	Redirect = 'redirect',
	Widget = 'widget'
}

export enum OktaStrategy {
	OpenIDConnect = 'openIDConnect',
	OAuth2 = 'oauth2',
	SAML = 'saml'
}

export class OktaConfig {
	clientId: string;
	oktaDomain: string;
	strategy: OktaStrategy;
	uiType: OktaUiType;
}

export class AzureOpenIDConfig {
	tenantId: string;
	clientId: string;
	secret: string;
	whitelistedRedirectUrls: string[];
}

export class AzureOpenIDTokenData {
	token: string;
	userId: string;
	organizationId: string;
}

export class ServerToServerConfig {
	authenticationUrl: string;
	appId: string;
	refreshUrl: string;
	verifyUrl: string;
	apiKey: string;
	whitelistedRedirectUrls: string[];
}

export class DecodedAzureToken {
	aud: string;
	iss: string;
	iat: number;
	nbf: number;
	exp: number;
	acct: number;
	acr: string;
	acrs: string[];
	aio: string;
	amr: string[];
	app_displayname: string;
	appid: string;
	appidacr: string;
	family_name: string;
	given_name: string;
	idtyp: string;
	ipaddr: string;
	name: string;
	oid: string;
	onprem_sid: string;
	platf: string;
	puid: string;
	rh: string;
	scp: string;
	signin_state: string[];
	sub: string;
	tenant_region_scope: string;
	tid: string;
	unique_name: string;
	upn: string;
	uti: string;
	ver: string;
	wids: string[];
	xms_st: { sub: string; }
	xms_tcdt: number;
}

export class ServerToServerTokenData {
	appId: string;
	token: string;
	refreshToken: string;
	refreshUrl: string;
	organizationId: string;
	userId: string;
}
export class BasicConfig {
	codeLength: number;
	codeLifetime: string;
}

// export type PublicAuthenticationStrategy = Pick<AuthenticationStrategy,
// 	'id' | 'name'
// > & {

// };

export class PublicAuthenticationStrategy {
	@IsUUID('4')
	@ApiProperty()
	id: string;

	@IsString()
	@ApiProperty()
	name: string;
}

@Entity('authenticationStrategies')
@Index(['remoteId', 'organizationId'], { unique: true })
export class AuthenticationStrategy {
	constructor(value?: Partial<AuthenticationStrategy>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	remoteId: string;

	@Column('text', { nullable: false })
	name: string;

	@Column({
		type: 'enum',
		enum: AuthenticationStrategyType,
	})
	type: AuthenticationStrategyType;

	@Column('jsonb', { nullable: false })
	config: OktaConfig | ServerToServerConfig | AzureOpenIDConfig | BasicConfig;

	@Column('text')
	organizationId: string;
	@ManyToOne(
		type => Organization,
		organization => organization.id,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'organizationId' })
	organization: Organization | Partial<Organization>;

	public toPublic(): PublicAuthenticationStrategy {
		return {
			id: this.id,
			name: this.name
		};
	}
}
