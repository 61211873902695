import { Column, Entity, Index, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm';

import { Program } from '../program/program.entity';
import { FundingSource, PublicFundingSource } from '../funding-source/funding-source.entity';
import { FundingType, PublicFundingType } from '../funding-type/funding-type.entity';
import { PublicUser, User } from '../user/user.entity';
import { BrandAllocation, PublicBrandAllocation } from '../brand-allocation/brand-allocation.entity';
import { Plan, PublicPlan } from '../plan/plan.entity';
import { BudgetDistributionGroup, PublicBudgetDistributionGroup } from '../budget-distribution-group/budget-distribution-group.entity';

export type PublicBudgetAllocation = Pick<
	BudgetAllocation,
	'id' | 'amountPlanned' | 'amountActual' | 'created' | 'fundingSourceId' | 'fundingTypeId' | 'eventId'
> & {
	programId?: string;
	planId?: string;
	plan?: PublicPlan;
	brandAllocationsPlanned?: PublicBrandAllocation[];
	brandAllocationsActual?: PublicBrandAllocation[];
	budgetDistributionGroups?: PublicBudgetDistributionGroup[];
	fundingSource: PublicFundingSource;
	fundingType: PublicFundingType;
	author: PublicUser;
	childBudgetAllocations?: PublicBudgetAllocation[];
	isDistributed: boolean;
};

@Entity('budgetAllocations')
@Index(['programId'])
@Index(['planId'])
@Index(['fundingSourceId'])
@Index(['fundingTypeId'])
@Index(['fundingSourceId', 'fundingTypeId'])
export class BudgetAllocation {
	constructor(value?: Partial<BudgetAllocation>) {
		if (value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: true })
	planId: string;
	@ManyToOne(
		type => Plan,
		plan => plan.budgetAllocations,
		{
			onDelete: 'CASCADE',
			nullable: true
		}
	)
	plan: Plan;

	@Column('text', { nullable: true })
	programId: string;
	@ManyToOne(
		type => Program,
		program => program.budgetAllocations,
		{
			onDelete: 'CASCADE',
			nullable: true
		}
	)
	program: Program;

	childBudgetAllocations?: BudgetAllocation[];

	@Column('text', { nullable: false })
	fundingSourceId: string;
	@ManyToOne(
		type => FundingSource,
		fundingSource => fundingSource.id,
		{
			eager: true
		}
	)
	@JoinColumn({ name: 'fundingSourceId' })
	fundingSource: FundingSource | Partial<FundingSource>;

	@Column('text', { nullable: false })
	fundingTypeId: string;
	@ManyToOne(
		type => FundingType,
		fundingType => fundingType.id,
		{
			eager: true
		}
	)
	@JoinColumn({ name: 'fundingTypeId' })
	fundingType: FundingType | Partial<FundingType>;

	@OneToMany(
		() => BrandAllocation,
		brandAllocation => brandAllocation.budgetAllocationPlanned,
		{
			nullable: true,
			eager: true,
			cascade: true
		}
	)
	brandAllocationsPlanned?: BrandAllocation[];

	@OneToMany(
		() => BrandAllocation,
		brandAllocation => brandAllocation.budgetAllocationActual,
		{
			nullable: true,
			eager: true,
			cascade: true
		}
	)
	brandAllocationsActual?: BrandAllocation[];

	@OneToMany(
		() => BudgetDistributionGroup,
		budgetDistributionGroup => budgetDistributionGroup.budgetAllocation,
		{
			nullable: true
		}
	)
	budgetDistributionGroups?: BudgetDistributionGroup[];

	@Column('decimal', { nullable: false })
	amountPlanned: number;

	@Column('decimal', { nullable: true })
	amountActual: number;

	@Column('boolean', { default: false, nullable: false })
	deleted: boolean;

	@Column('text', { nullable: true })
	eventId: string;

	@Column('text', { nullable: false })
	authorId: string;
	@ManyToOne(type => User, {
		eager: true,
		onDelete: 'CASCADE'
	})
	author: User;

	@Column({ type: 'timestamptz', default: () => 'NOW()' })
	created: string;

	public set isDistributed(value) {}
	public get isDisttributed(): boolean {
		if (this.brandAllocationsActual?.length) {
			for (const ba of this.brandAllocationsActual) {
				if (ba.budgetDistributions?.length) {
					return true;
				}
			}
		}
		if (this.brandAllocationsPlanned?.length) {
			for (const ba of this.brandAllocationsPlanned) {
				if (ba.budgetDistributions?.length) {
					return true;
				}
			}
		}
		return false;
	}

	public toPublic(): PublicBudgetAllocation {
		const pub: Partial<PublicBudgetAllocation> = {
			id: this.id,
			amountPlanned: this.amountPlanned,
			amountActual: this.amountActual,
			fundingSourceId: this.fundingSourceId,
			fundingTypeId: this.fundingTypeId,
			created: this.created,
			eventId: this.eventId,
			isDistributed: this.isDistributed
		};

		if (this.programId) {
			pub.programId = this.programId;
		}

		if (this.planId) {
			pub.planId = this.planId;
		}

		if (this.plan) {
			pub.plan = new Plan(this.plan).toPublic();
		}

		if (this.brandAllocationsPlanned) {
			pub.brandAllocationsPlanned = (this.brandAllocationsPlanned as BrandAllocation[])?.map(a => new BrandAllocation(a).toPublic());
		}

		if (this.brandAllocationsActual) {
			pub.brandAllocationsActual = (this.brandAllocationsActual as BrandAllocation[])?.map(a => new BrandAllocation(a).toPublic());
		}

		if (this.budgetDistributionGroups?.length) {
			pub.budgetDistributionGroups = (this.budgetDistributionGroups as BudgetDistributionGroup[])?.map(g =>
				new BudgetDistributionGroup(g).toPublic()
			);
		}

		if (this.fundingSource) {
			pub.fundingSource = new FundingSource(this.fundingSource).toPublic();
		}

		if (this.fundingType) {
			pub.fundingType = new FundingType(this.fundingType).toPublic();
		}

		if (this.author) {
			pub.author = new User(this.author).toPublic();
		}

		if (this.childBudgetAllocations) {
			pub.childBudgetAllocations = (this.childBudgetAllocations as BudgetAllocation[])?.map(a => new BudgetAllocation(a).toPublic());
		}

		return pub as PublicBudgetAllocation;
	}
}
