import { FraudPreventionUtils } from './fraud-prevention.utils';

export interface Validators {
	address?: {
		country?: string[];
		state?: string[];
	}
	age?: number;
	bool?: boolean;
	email?: {
		restrictions: string[];
	};
	group?: boolean;
	phone?: boolean | string[];
	required?: boolean;
	string?: boolean;
	values?: any[];
}

export class Validation {
	public static async validate(input, validators: Validators): Promise<boolean> {
		const validatorKeys = Object.keys(validators);

		// Fields should be required by default.
		if(!validatorKeys.includes('required')) {
			validatorKeys.push('required');
			validators.required = true;
		}

		for(const k of validatorKeys) {
			let valid = false;
			switch (k) {
			case ('bool'):
				valid = this.validateBool(input);
				break;
			case ('age'):
				valid = this.validateAge(input, validators[k]);
				break;
			case ('email'):
				valid = this.validateEmail(input, validators[k].restrictions);
				break;
			case ('number'):
				valid = this.validateNumber(input);
				break;
			case ('phone'):
				valid = this.validatePhone(input, validators[k]);
				break;
			case ('required'):
				valid = this.validateRequired(input, validators[k] === false);
				break;
			case ('string'):
				valid = this.validateString(input);
				break;
			case ('values'):
				valid = validators[k].includes(input);
				break;
			default:
				valid = true;
				break;
			}

			if(!valid) {
				return false;
			}
		}

		return true;
	}

	private static validateRequired(input: any, optional: boolean = false) {
		if(optional) {
			return true;
		}
		if(typeof input !== 'undefined' && input !== null && !Number.isNaN(input) && input !== false) {
			if(typeof input === 'string' && input.trim().length === 0) {
				return false;
			}
			if(Array.isArray(input) && !input.length) {
				return false;
			}
			if(typeof input === 'object' && !Object.keys(input).length) {
				return false;
			}
			return true;
		}
		return false;
	}

	private static validateString(str) {
		if(typeof str === 'string' && str.trim().length > 0) {
			return true;
		}
		return false;
	}

	private static validateAge(input, targetAge: number) {
		const now = new Date();
		const then = new Date(input);

		if(!(then instanceof Date && isFinite(then.getTime()))) {
			return false;
		}

		const years = now.getFullYear() - then.getFullYear();
		const months = now.getMonth() - then.getMonth();
		if(years === targetAge && (months < 0 || (months === 0 && now.getDate() < then.getDate()))) {
			return false;
		} else if(years >= targetAge) {
			return true;
		}

		return false;
	}

	private static validateBool(input) {
		// Strict on true, lenient on false (allows for optional booleans to be validated).
		return (input === true || input == false || typeof input === 'undefined' || input === null);
	}

	private static validateNumber(input) {
		// Note: isNaN alone tries to coerce to a number first.
		return !isNaN(input);
	}

	private static validateEmail(email: string, restrictions?: string[]) {
		return FraudPreventionUtils.validateEmail(email, restrictions);
	}

	private static validatePhone(phone, countries?: string[] | boolean) {
		if(!Array.isArray(countries)) {
			countries = [];
		}
		return FraudPreventionUtils.validatePhone(phone, countries);
	}
}