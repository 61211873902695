import { Column, Entity, Index, PrimaryGeneratedColumn } from 'typeorm';

import { Sortable } from '../_core/decorators/sortable.decorator';

export type PublicProgramType = Pick<ProgramType, 'id' | 'name' | 'order'>;

@Entity('programTypes')
@Index(['remoteId'], { unique: true })
export class ProgramType {
	constructor(value?: Partial<ProgramType>) {
		for (let k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: true })
	remoteId: string;

	@Column('text', { nullable: false })
	@Sortable
	name: string;

	@Column('integer', { nullable: true })
	order?: number;

	public toPublic(): PublicProgramType {
		return {
			id: this.id,
			name: this.name,
			order: this.order
		};
	}
}
