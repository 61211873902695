import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ID } from '@datorama/akita';
import { EMPTY, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { FundingSource, FundingType } from '../../global/global.model';
import { programs } from '../../seed-data/programs.data';
import { Brand, BrandStrategy } from '../brand/brand.model';
import { Program } from '../program/program.model';
import { Plan, PreviousPeriodData, getPlanStatusColor } from './plan.model';
import { PlanQuery } from './plan.query';
import { PlanStore } from './plan.store';
import { TableRow } from '../../table/table.model';

@Injectable({ providedIn: 'root' })
export class PlanService {
	constructor(private planStore: PlanStore, private planQuery: PlanQuery, private http: HttpClient, private builder: FormBuilder) {}

	get() {
		this.planStore.setLoading(true);
		return this.http.get<Plan[]>(`${environment.apiUrl}/organization/${environment.organizationId}/plan`).pipe(
			tap(plans => {
				console.log(
					'Plan: get()',
					plans
				);
				this.planStore.set(this.prepareForAkita(plans));
				this.planStore.setLoading(false);
			})
		);
	}

	getOne(id: Plan['id']) {
		this.planStore.setLoading(true);

		// Return cache if we already have this plan
		// TODO: How do we determine if we have a full plan

		// const planCache = this.planStore.getValue().entities[id];
		// if (planCache) {
		// 	this.planStore.setLoading(false);
		// 	console.log('Loaded plan from cache', planCache);
		// 	return of(planCache);
		// }

		return this.http.get<Plan>(`${environment.apiUrl}/organization/${environment.organizationId}/plan/${id}`).pipe(
			tap(plan => {
				console.log('Plan: getOne()', plan);
				this.planStore.upsert(plan.id, { ...plan, type: 'Plan' });
				this.planStore.setLoading(false);
			})
		);
	}

	set(plans: Plan[]) {
		this.planStore.set(plans);
	}

	create(plan: Plan) {
		return this.http.post<Plan>(`${environment.apiUrl}/organization/${environment.organizationId}/plan`, this.prepareForApi(plan)).pipe(
			tap(response => {
				console.log(response);
				this.planStore.upsert(response.id, { ...response });
				this.planStore.remove(this.planQuery.getActiveId());
				this.planStore.setActive(response.id);
			})
		);
	}

	add(plan: Plan) {
		this.planStore.add(plan);
	}

	update(id, plan: Partial<Plan>, skipHTTP?: boolean) {
		if (skipHTTP) {
			this.planStore.upsert(id, { ...plan });
			return EMPTY;
		}

		console.log('Trying to update', id, plan);
		return this.http
			.put<Plan>(`${environment.apiUrl}/organization/${environment.organizationId}/plan/${id}`, this.prepareForApi(plan))
			.pipe(
				tap(response => {
					console.log(response);
					this.planStore.upsert(response.id, { ...response });
				})
			);
	}

	updateActive(plan: Partial<Plan>) {
		this.planStore.updateActive(plan);
	}

	upsertProgramIntoActive(program: Program) {
		const activePlan = this.planQuery.getActive();
		this.planStore.updateActive({
			...activePlan,
			programs: [...activePlan.programs.filter(p => p.id !== program.id), program]
		});
	}

	removeProgramIntoActive(programId: Program['id']) {
		const activePlan = this.planQuery.getActive();
		this.planStore.updateActive({
			...activePlan,
			programs: [...activePlan.programs.filter(p => p.id !== programId)]
		});
	}

	clone(id: Plan['id']) {
		return this.http.post<Plan>(`${environment.apiUrl}/organization/${environment.organizationId}/plan/${id}/clone`, {}).pipe(
			tap(response => {
				this.planStore.upsert(response.id, { ...response });
			})
		);
	}

	remove(id: Plan['id']) {
		return this.http.delete<string>(`${environment.apiUrl}/organization/${environment.organizationId}/plan/${id}`).pipe(
			tap(response => {
				this.planStore.remove(id);
			})
		);
	}

	setActive(id: Plan['id']) {
		this.planStore.setActive(id);
	}

	removeActive(id: Plan['id']) {
		this.planStore.removeActive(id);
	}

	setLoading(state: boolean) {
		this.planStore.setLoading(state);
	}

	linkProgram(plan: Plan, program: Program) {
		return this.http
			.put<Program>(`${environment.apiUrl}/organization/${environment.organizationId}/plan/${plan.id}/link-program/${program.id}`, {})
			.pipe(
				tap(program => {
					console.log('Plan: Link Program', program);
					this.planStore.update(plan.id, {
						programs: [...(plan.programs || []), program],
						programIds: [...(plan.programIds || []), program.id]
					});
				})
			);
	}

	getPreviousPeriodPlansAndPrograms(id: Plan['id']) {
		return this.http
			.get<PreviousPeriodData>(`${environment.apiUrl}/organization/${environment.organizationId}/plan/${id}/previous-period`)
			.pipe(
				tap(response => {
					console.log('Plan: Get Previous period', response);
					this.planStore.update({
						previousPeriodData: response
					});
				})
			);
	}

	getSuggestedPrograms(id: Plan['id'], name?: string, brandIds?: Brand['id'][]) {
		return this.http.post<Program[]>(`${environment.apiUrl}/organization/${environment.organizationId}/plan/${id}/suggested-programs`, {
			name,
			brandIds
		});
	}

	getFormObject(plan: Plan, controlOverrides: any = {}) {
		return {
			name: [plan.name],
			detail: [plan.detail],
			budgetPeriod: [plan.budgetPeriod, Validators.required],
			retailer: [plan.retailer, Validators.required],
			brands: [plan.brands || [], Validators.required],
			programSector: [plan.programSector, Validators.required],
			brandStrategy: [plan.brandStrategy],
			customerStrategy: [plan.customerStrategy],
			owners: [plan.owners || []],
			tags: [plan.tags || []],
			notes: [plan.notes || []],
			...controlOverrides
		};
	}

	prepareForAkita(items: Plan[]) {
		return items.map(item => ({
			...item,
			type: 'Plan'
		}));
	}

	prepareForApi(plan: Partial<Plan>) {
		const obj = {};
		console.log('Preparing for API', plan);

		if (plan) {
			Object.keys(plan).forEach(key => {
				switch (key) {
					case 'budgetPeriod':
					case 'retailer':
					case 'programSector':
						obj[key + 'Id'] = plan[key]?.id;
						break;

					case 'brands':
					case 'categories':
					case 'owners':
						obj[key.slice(0, key.length - 1) + 'Ids'] = plan[key]?.map(v => v.id);
						break;

					case 'tags':
						obj[key] = plan[key]?.map(v => v.name);
						break;

					case 'brandStrategy':
						// Detect whether brandStrategy is an array or open text, and package up for API as necessary.
						if (Array.isArray(plan[key])) {
							let brandStrategies = plan[key] as BrandStrategy[];
							obj['brandStrategyMerged'] = {
								// Careful here in case someone changes the label string on the component
								brandStrategy: brandStrategies.filter(strat => strat.name !== '(None)')
							};
						} else {
							let value: any = plan[key];
							if (value) {
								if (!value?.['body']) {
									value = { body: value as string };
								}

								obj['brandStrategyMerged'] = {
									brandStrategyFreeform: value
								};
							}
						}

						break;

					case 'notes':
					case 'name':
						break;

					default:
						obj[key] = plan[key];
						break;
				}
			});
		}

		return obj;
	}

	// Subtract the plan budget total by program allocation totals, split by brands.
	// Optionally, pass in a source / type to filter allocations by those criteria
	getAllocationGap(plan: Plan, brands: Brand[], total, fundingSource?: FundingSource, fundingType?: FundingType) {
		const brandIds = brands.map(brand => brand.id);
		const programTotal =
			plan?.programs
				?.map(
					program =>
						program.budgetAllocations
							?.filter(bA => bA.created)
							// Filter by source and type if they exist
							?.filter(bA => {
								if (
									(fundingSource && fundingSource?.id !== bA.fundingSource.id) ||
									(fundingType && fundingType?.id !== bA.fundingType.id)
								) {
									return false;
								}

								return true;
							})
							.map(bA => {
								// Get the brand split then multiply by the total
								const brandSplit =
									bA.brandAllocationsPlanned
										?.filter(bAP => {
											// There is a chance that we're passing un-normalized allocations in so check for both
											const id = bAP?.brand?.id || bAP?.id;
											return brandIds.includes(id);
										})
										.map(bAP => bAP.split || 1)
										.reduce((a, b) => a + Number(b), 0) || 0;

								// console.log('Brand Split:', brandSplit, bA.amountPlanned, bA.amountPlanned * brandSplit);

								return bA.amountPlanned * brandSplit;
							}) || []
				)
				.reduce((acc, val) => acc.concat(val), [])
				.reduce((a, b) => a + Number(b), 0) || 0;

		// console.log('Total: ProgramTotal', total, programTotal, total - programTotal);
		// console.log('---------------------');
		return total - programTotal;
	}
}
