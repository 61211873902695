import {
	Column,
	Entity,
	JoinColumn,
	ManyToOne,
	PrimaryGeneratedColumn,
	Index,
	ManyToMany
} from 'typeorm';
import {
	BaseTacticType,
	MediaType,
	PublicBaseTacticType
} from '../base-tactic-type/base-tactic-type.entity';
import {
	MeasurementGroup,
	PublicMeasurementGroup
} from '../measurement-group/measurement-group.entity';
import { Organization } from '../organization/organization.entity';

import {
	TacticCategory,
	PublicTacticCategory
} from '../tactic-category/tactic-category.entity';

export type PublicTacticType = Pick<
	TacticType,
	| 'id'
	| 'name'
	| 'tacticCategoryId'
	| 'baseTacticTypeId'
	| 'previousId'
	| 'mediaType'
> & {
	tacticCategory: PublicTacticCategory;
	baseTacticType?: PublicBaseTacticType;
	measurementGroups?: PublicMeasurementGroup[];
};
export type PublicTacticTypeMediaType = { id?: string; name: string };

// This is a shim type since "Media Type" is just a text field,
// and won't be able to run through the normal sort utility, which
// is meant to sort actual classes.
export class TacticTypeMediaType {
	constructor(value?: Partial<TacticType>) {
		for (const k in value) {
			this[k] = value[k];
		}
	}

	public mediaType: MediaType;

	public toPublic(): PublicTacticTypeMediaType {
		return {
			id: null,
			name: this.mediaType ? this.mediaType.toString() : null
		};
	}
}

@Entity('tacticTypes')
@Index(['remoteId', 'organizationId'], { unique: true })
@Index(['tacticCategoryId'])
export class TacticType {
	constructor(value?: Partial<TacticType>) {
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('uuid', { nullable: true })
	previousId?: string;

	@Column('text', { nullable: true })
	remoteId: string;

	@Column('text', { nullable: true })
	organizationId?: string;
	@ManyToOne(
		type => Organization,
		organization => organization.id,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'organizationId' })
	organization?: Organization | Partial<Organization>;

	@Column('text', { nullable: false })
	name: string;

	@Column('boolean', { nullable: false, default: false })
	hidden: boolean;

	@Column('text', { nullable: false })
	tacticCategoryId: string;
	@ManyToOne(() => TacticCategory, {
		eager: true,
		nullable: false,
		cascade: true,
		onDelete: 'CASCADE'
	})
	@JoinColumn({ name: 'tacticCategoryId' })
	tacticCategory: TacticCategory;

	@Column('text', { nullable: true })
	baseTacticTypeId?: string;
	@ManyToOne(() => BaseTacticType, {
		eager: true,
		nullable: true,
		onDelete: 'CASCADE'
	})
	@JoinColumn({ name: 'baseTacticTypeId' })
	baseTacticType?: BaseTacticType;

	@Column({
		type: 'enum',
		enum: MediaType,
		nullable: false,
		default: MediaType.Paid
	})
	mediaType: MediaType;

	@ManyToMany(
		() => MeasurementGroup,
		measurementGroup => measurementGroup.tacticTypes,
		{
			// eager: true,
			nullable: true
		}
	)
	measurementGroups?: MeasurementGroup[];

	public toPublic(): PublicTacticType {
		const pub: Partial<PublicTacticType> = {
			id: this.id,
			name: this.name,
			tacticCategoryId: this.tacticCategoryId,
			baseTacticTypeId: this.baseTacticTypeId,
			previousId: this.previousId,
			mediaType: this.mediaType
		};

		if (this.tacticCategory) {
			pub.tacticCategory = new TacticCategory(
				this.tacticCategory
			).toPublic();
		}

		if (this.baseTacticType) {
			pub.baseTacticType = new BaseTacticType(
				this.baseTacticType
			).toPublic();
		}

		if (this.measurementGroups?.length) {
			pub.measurementGroups = this.measurementGroups.map(g =>
				new MeasurementGroup(g).toPublic()
			);
		}

		return pub as PublicTacticType;
	}
}
