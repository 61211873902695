import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { map, startWith, switchMap } from 'rxjs/operators';
import { resolveDotNotationPath } from '../../../_core/utils/object.utils';
import { Tactic } from './tactic.model';
import { TacticStore, TacticState } from './tactic.store';

const sortBy = (a: Tactic, b: Tactic, state: TacticState) => {
	// Get the path values from dot notation in the state
	a = resolveDotNotationPath(state.sortByProperty, a);
	b = resolveDotNotationPath(state.sortByProperty, b);

	if (state.sortByDirection === 'asc') {
		return a > b ? 1 : -1;
	} else {
		return a < b ? 1 : -1;
	}
};

@Injectable({ providedIn: 'root' })
export class TacticQuery extends QueryEntity<TacticState> {
	public tactics$ = this.select().pipe(switchMap(state => this.selectAll({ sortBy })));

	constructor(protected store: TacticStore) {
		super(store);
	}
}
