import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';

import { Sortable } from '../_core/decorators/sortable.decorator';
import { Organization } from '../organization/organization.entity';

export type PublicProgramUtilization = Pick<ProgramUtilization, 'id' | 'name' | 'order'>;

@Entity('programUtilizations')
@Index(['remoteId', 'organizationId'], { unique: true })
export class ProgramUtilization {
	constructor(value?: Partial<ProgramUtilization>) {
		if (value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: true })
	remoteId: string;

	@Column('text', { nullable: false })
	@Sortable
	name: string;

	@Column('text', { nullable: false })
	organizationId: string;
	@ManyToOne(type => Organization, {
		onDelete: 'CASCADE'
	})
	@JoinColumn({ name: 'organizationId' })
	organization: Organization;

	@Column('integer', { nullable: true })
	order?: number;

	public toPublic(): PublicProgramUtilization {
		return {
			id: this.id,
			name: this.name,
			order: this.order
		};
	}
}
