import {
	Column,
	Entity,
	PrimaryGeneratedColumn,
	Index,
	ManyToOne,
	JoinColumn,
	OneToMany
} from 'typeorm';
import { Organization } from '../organization/organization.entity';
import { TacticType } from '../tactic-type/tactic-type.entity';

export type PublicTacticCategory = Pick<
	TacticCategory,
	'id' | 'name'
>;

@Entity('tacticCategories')
@Index(['remoteId', 'organizationId'], { unique: true })
export class TacticCategory {
	constructor(value?: Partial<TacticCategory>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('uuid', { nullable: true })
	previousId?: string;

	@Column('text', { nullable: true })
	remoteId: string;

	@Column('text', { nullable: true })
	organizationId?: string;
	@ManyToOne(
		type => Organization,
		organization => organization.id,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'organizationId' })
	organization?: Organization | Partial<Organization>;

	@OneToMany(
		type => TacticType,
		tacticType => tacticType.tacticCategory,
		{
			eager: false
		}
	)
	tacticTypes?: TacticType[];

	@Column('text', { nullable: true })
	name: string;

	@Column('boolean', { nullable: false, default: false })
	hidden: boolean;

	public toPublic(): PublicTacticCategory {
		return {
			id: this.id,
			name: this.name
		};
	}
}