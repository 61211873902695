import { Query as WarningQuery } from '../../warning/utils/query.utils';
import { Query as ExternalIdQuery } from '../../external-id/utils/query.utils';
import { ProgramSelect, Query as ProgramQuery } from '../../program/utils/query.utils';

export enum InvoiceSelect {
	Tactic = 'tactic',
	BrandAllocations = 'brandAllocations',
	Vendor = 'vendor',
	Author = 'author',
	Warnings = 'warnings',
	ExternalIds = 'externalIds',
	BudgetCache = 'budgetCache'
}

export class Query {
	private static readonly INVOICE_QUERY_ALIAS: string = 'i';

	public static getSelects(
		alias: string = this.INVOICE_QUERY_ALIAS,
		targets: InvoiceSelect[] = Object.values(InvoiceSelect),
		asJsonSelect: boolean = false,
		hasTotal: boolean = false
	) {
		const selects: string[] = [];
		const json: boolean = asJsonSelect;

		selects.push(`
			${(json) ? `'id', ${alias}.id` : `${alias}.id`}
		`);
		selects.push(`
			${(json) ? `'number', ${alias}.number` : `${alias}.number`}
		`);
		selects.push(`
			${(json) ? `'amount', ${alias}.amount` : `${alias}.amount`}
		`);
		selects.push(`
			${(json) ? `'status', ${alias}.status` : `${alias}.status`}
		`);
		selects.push(`
			${(json) ? `'dueDate', to_char((${alias}."dueDate")::date + interval '12 hour', 'YYYY-MM-DD"T"HH24:MI:SS"Z"')` : `to_char((${alias}."dueDate")::date + interval '12 hour', 'YYYY-MM-DD"T"HH24:MI:SS"Z"') AS "dueDate"`}
		`);
		selects.push(`
			${(json) ? `'created', ${alias}.created` : `${alias}.created`}
		`);
		selects.push(`
			${(json) ? `'tacticId', ${alias}."tacticId"` : `${alias}."tacticId"`}
		`);
		selects.push(`
			${(json) ? `'vendorId', ${alias}."vendorId"` : `${alias}."vendorId"`}
		`);
		selects.push(`
			${(json) ? `'authorId', ${alias}."authorId"` : `${alias}."authorId"`}
		`);

		if(hasTotal) {
			selects.push(`
				${(json) ? `'total', ${alias}.total` : `${alias}.total`}
			`);
		}

		if(targets.includes(InvoiceSelect.Tactic)) {
			if(json) {
				selects.push(`
					'tactic', ${alias}_tactic.tactic
				`);
			} else {
				selects.push(`
					${alias}_tactic.tactic AS "tactic"
				`);
			}
		}

		if(targets.includes(InvoiceSelect.BrandAllocations)) {
			if(json) {
				selects.push(`
					'brandAllocations', ${alias}_brandallocations."allocationsArr"
				`);
			} else {
				selects.push(`
					${alias}_brandallocations."allocationsArr" AS "brandAllocations"
				`);
			}
		}

		if(targets.includes(InvoiceSelect.Vendor)) {
			if(json) {
				selects.push(`
					'vendor', ${alias}_vendor.vendor
				`);
			} else {
				selects.push(`
					${alias}_vendor.vendor AS vendor
				`);
			}
		}

		if(targets.includes(InvoiceSelect.Author)) {
			if(json) {
				selects.push(`
					'author', ${alias}_author.author
				`);
			} else {
				selects.push(`
					${alias}_author.author AS author
				`);
			}
		}

		if(targets.includes(InvoiceSelect.Warnings)) {
			if(json) {
				selects.push(`
					'warnings', ${alias}_warnings."warningsArr"
				`);
			} else {
				selects.push(`
					${alias}_warnings."warningsArr" AS warnings
				`);
			}
		}

		if(targets.includes(InvoiceSelect.ExternalIds)) {
			if(json) {
				selects.push(`
					'externalIds', ${alias}_externalids."externalIdsArr"
				`);
			} else {
				selects.push(`
					${alias}_externalids."externalIdsArr" AS "externalIds"
				`);
			}
		}

		return selects.join(',');
	}

	public static getSubqueries(
		alias: string = this.INVOICE_QUERY_ALIAS,
		targets: InvoiceSelect[] = Object.values(InvoiceSelect)
	) {
		if(!targets.length) {
			return '';
		}

		const subQueries: string[] = [];

		if(targets.includes(InvoiceSelect.Tactic)) {
			subQueries.push(`
				LATERAL (
					SELECT
						JSON_BUILD_OBJECT (
							'id', t2.id,
							'name', t2.name,
							'program', "programData".program
						) AS tactic
					FROM
						"tactics" AS t2,
						LATERAL (
							SELECT
								JSON_BUILD_OBJECT (
									${ProgramQuery.getSelects('p2', [ProgramSelect.BudgetPeriod], true)}
								) AS program
							FROM
								programs AS p2
							${ProgramQuery.getSubqueries('p2', [ProgramSelect.BudgetPeriod])}
							WHERE
								p2.id = t2."programId"
						) AS "programData"
					WHERE
						t2.id = ${alias}."tacticId"
				) AS ${alias}_tactic
			`);
		}

		if(targets.includes(InvoiceSelect.BrandAllocations)) {
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								'id', bra2.id,
								'brandId', brands2.id,
								'brand', JSON_BUILD_OBJECT (
									'id', brands2.id,
									'name', brands2.name
								),
								'split', bra2.split
							)
						FROM
							"brandAllocations" AS bra2
						LEFT JOIN
							brands as brands2
								ON
									brands2.id = bra2."brandId"
						WHERE
							bra2."invoiceId" = ${alias}.id
					) AS "allocationsArr"
				) AS ${alias}_brandallocations
			`);
		}

		if(targets.includes(InvoiceSelect.Vendor)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSON_BUILD_OBJECT (
								'id', v2.id,
								'name', v2."name"
							)
						FROM
							"vendors" AS v2
						WHERE
							v2.id = ${alias}."vendorId"
					) AS "vendor"
				) AS ${alias}_vendor
			`);
		}

		if(targets.includes(InvoiceSelect.Author)) {
			subQueries.push(`
				LATERAL (
					SELECT
						JSON_BUILD_OBJECT (
							'id', u2.id,
							'email', u2."email",
							'profile', u2."profile"
						) AS "author"
					FROM
						"users" AS u2
					WHERE
						u2.id = ${alias}."authorId"
				) AS ${alias}_author
			`);
		}

		if(targets.includes(InvoiceSelect.Warnings)) {
			const warningAlias: string = 'w';
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								${WarningQuery.getSelects(warningAlias, true)}
							)
						FROM
							"warnings" AS ${warningAlias}
						WHERE
							${warningAlias}."invoiceId" = ${alias}.id
							AND ${warningAlias}.dismissed = false
					) AS "warningsArr"
				) AS ${alias}_warnings
			`);
		}

		if(targets.includes(InvoiceSelect.ExternalIds)) {
			const externalIdAlias: string = 'eid';
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								${ExternalIdQuery.getSelects('eid', undefined, true)}
							)
						FROM
							"externalIds" AS ${externalIdAlias}
						${ExternalIdQuery.getSubqueries('eid')}
						WHERE
							${externalIdAlias}."invoiceId" = ${alias}.id
					) AS "externalIdsArr"
				) AS ${alias}_externalids
			`);
		}

		if(!subQueries.length) {
			return '';
		}

		return ',\n' + subQueries.join(',');
	}
}
