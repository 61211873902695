import { Column, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm';
import { BudgetAllocation, PublicBudgetAllocation } from '../budget-allocation/budget-allocation.entity';
import { BudgetDistribution, PublicBudgetDistribution } from '../budget-distribution/budget-distribution.entity';
import { Sortable } from '../_core/decorators/sortable.decorator';
import { Cost, PublicCost } from '../cost/cost.entity';

export class BudgetDistributionGroupDetails {
	[key: string]: any;
}

export type PublicBudgetDistributionGroup = Pick<
	BudgetDistributionGroup,
	'id' | 'name' | 'start' | 'end' | 'budgetAllocationId' | 'details' | 'costId'
> & {
	budgetAllocation?: PublicBudgetAllocation;
	cost?: PublicCost;
	budgetDistributions?: PublicBudgetDistribution[];
};

@Entity('budgetDistributionGroups')
export class BudgetDistributionGroup {
	@PrimaryGeneratedColumn('uuid')
	id: string;
	@Column('text', { nullable: false })
	@Sortable
	name: string;
	@Column('text', { nullable: true })
	budgetAllocationId: string;
	@ManyToOne(() => BudgetAllocation, {
		onDelete: 'CASCADE',
		orphanedRowAction: 'delete'
	})
	@JoinColumn({ name: 'budgetAllocationId' })
	budgetAllocation: BudgetAllocation;
	@Column('text', { nullable: true })
	costId: string;
	@ManyToOne(() => Cost, {
		onDelete: 'CASCADE',
		orphanedRowAction: 'delete'
	})
	@JoinColumn({ name: 'costId' })
	cost: Cost;
	@OneToMany(
		() => BudgetDistribution,
		budgetDistribution => budgetDistribution.budgetDistributionGroup,
		{
			cascade: true
		}
	)
	budgetDistributions?: BudgetDistribution[];
	@Column({ type: 'timestamptz', nullable: true })
	@Sortable
	start?: string;
	@Column({ type: 'timestamptz', nullable: true })
	@Sortable
	end?: string;
	@Column('jsonb', { nullable: true })
	details?: BudgetDistributionGroupDetails;

	constructor(value?: Partial<BudgetDistributionGroup>) {
		for (const k in value) {
			this[k] = value[k];
		}
	}

	public toPublic() {
		const pub: Partial<PublicBudgetDistributionGroup> = {
			id: this.id,
			name: this.name,
			start: this.start,
			end: this.end,
			budgetAllocationId: this.budgetAllocationId,
			costId: this.costId,
			details: this.details
		};

		if (this.budgetAllocation) {
			pub.budgetAllocation = new BudgetAllocation(this.budgetAllocation).toPublic();
		}

		if (this.cost) {
			pub.cost = new Cost(this.cost).toPublic();
		}

		if (this.budgetDistributions?.length) {
			pub.budgetDistributions = this.budgetDistributions.map(d => new BudgetDistribution(d).toPublic());
		}

		return pub as PublicBudgetDistributionGroup;
	}
}
