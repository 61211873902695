import {
	Entity,
	PrimaryGeneratedColumn,
	Column,
	ManyToOne,
	JoinColumn,
	Index,
	ManyToMany,
	JoinTable
} from 'typeorm';

import { Sortable } from '../_core/decorators/sortable.decorator';

import { Organization } from '../organization/organization.entity';
import { BudgetPeriod, PublicBudgetPeriod } from '../budget-period/budget-period.entity';
import { Optional } from '@nestjs/common';

export type PublicBrandInitiative = Pick<
	BrandInitiative,
	'id' | 'name'
> & {
	budgetPeriod?: PublicBudgetPeriod,
	parentInitiative?: PublicBrandInitiative
};

@Entity('brandInitiatives')
@Index(['remoteId', 'organizationId'], { unique: true })
export class BrandInitiative {
	constructor(value?: Partial<BrandInitiative>) {
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: true })
	remoteId: string;

	@Column('text', { nullable: false })
	@Sortable
	name: string;

	@Column('text', { nullable: false })
	organizationId: string;
	@ManyToOne(
		type => Organization,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'organizationId' })
	organization: Organization;

	@Column('text', { nullable: true })
	budgetPeriodId?: string;
	@ManyToOne(
		() => BudgetPeriod,
		undefined,
		{
			eager: true
		}
	)
	@JoinColumn({ name: 'budgetPeriodId' })
	budgetPeriod?: BudgetPeriod;

	@Column('text', { nullable: true })
	parentInitiativeId?: string;
	@ManyToOne(
		() => BrandInitiative,
		initiative => initiative.id,
		{
			eager: true
		}
	)
	@JoinColumn({ name: 'parentInitiativeId' })
	parentInitiative?: BrandInitiative;

	@Column('boolean', { nullable: false, default: false })
	hidden: boolean;

	public toPublic?(): PublicBrandInitiative {
		const pub: Partial<PublicBrandInitiative> = {
			id: this.id,
			name: this.name,
		};

		if(this.budgetPeriod) {
			pub.budgetPeriod = new BudgetPeriod(this.budgetPeriod).toPublic();
		}

		if(this.parentInitiative) {
			pub.parentInitiative = new BrandInitiative(this.parentInitiative).toPublic();
		}

		return pub as PublicBrandInitiative;
	}
}
