import { cloneDeep as lodashCloneDeep } from 'lodash';

/**
 * Return an object of the differences between the two objects provided.
 * @param o1
 * @param o2
 */
export function diffObjects(o1: object, o2: object) {
	return Object.keys(o2).reduce((diff, key) => {
		if (o1[key] === o2[key]) {
			return diff;
		}

		return {
			...diff,
			[key]: o2[key]
		};
	}, {});
}

/**
 * Safely traverse through an object with a dot notated path string
 * @param path
 * @param obj
 */
export function resolveDotNotationPath(path, obj) {
	return path?.split('.').reduce((prev, curr) => (prev ? prev[curr] : undefined), obj || self);
}

/**
 * Allows you to set a value on an object using a dot notated path string
 * @param obj
 * @param path
 * @param value
 * @returns
 */
export function setObjectValueAtPath(obj: any, path: string | string[], value: any) {
	// Regex explained: https://regexr.com/58j0k
	const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);

	pathArray.reduce((acc, key, i) => {
		if (acc[key] === undefined) acc[key] = {};
		if (i === pathArray.length - 1) acc[key] = value;
		return acc[key];
	}, obj);

	return obj;
}

/**
 * Detect if an object is truly empty
 * @param obj
 */
export function objectIsEmpty(obj) {
	let empty = true;

	Object.entries(obj).forEach(([key, value]) => {
		if (value) {
			empty = false;
		}
	});

	return empty;
}
/**
 * Returns the last property for a given dotted path
 * @param path
 */

export function getLastPropertyFromPath(path) {
	let paths = path.split('.');
	return paths[paths.length - 1];
}

export function mergeDeep(target, source) {
	if (
		!(target && typeof target === 'object' && !Array.isArray(target)) ||
		!(source && typeof source === 'object' && !Array.isArray(source))
	) {
		return target;
	}
	// Clone
	target = JSON.parse(JSON.stringify(target));
	source = JSON.parse(JSON.stringify(source));
	for (const [k, v] of Object.entries(source)) {
		if (v && typeof v === 'object' && !Array.isArray(v)) {
			if (typeof target[k] === 'undefined') {
				target[k] = new (Object.getPrototypeOf(v).constructor)();
			}
			target[k] = mergeDeep(target[k], source[k]);
		} else {
			target[k] = v === null ? undefined : v;
		}
	}
	return target;
}

export function unfreeze(obj: any) {
	return JSON.parse(JSON.stringify(obj));
}

export function cloneDeep(obj: any) {
	return lodashCloneDeep(obj);
}

export function parseJSON<T>(json: string): T {
	try {
		return JSON.parse(json);
	} catch (e) {
		return undefined;
	}
}

export function deepEqual(obj1: unknown, obj2: unknown): boolean {
	if (obj1 === obj2) {
		return true;
	}

	if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
		return false;
	}

	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
			return false;
		}
	}

	return true;
}
