import {
	Column,
	Entity,
	PrimaryGeneratedColumn,
	Index
} from 'typeorm';

export type PublicExternalIdType = Pick<ExternalIdType, 'id' | 'name'>;

export const DEFAULT_REMOTE_ID = 'SYSTEM_DEFINED';

@Entity('externalIdTypes')
@Index(['remoteId'], { unique: true })
export class ExternalIdType {
	constructor(value?: Partial<ExternalIdType>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	remoteId: string;

	@Column('text', { nullable: false })
	name: string;

	public toPublic(): PublicExternalIdType {
		return {
			id: this.id,
			name: this.name
		};
	}
}