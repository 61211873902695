import {
	Entity,
	PrimaryGeneratedColumn,
	Column,
	ManyToOne,
	JoinColumn,
	Index
} from 'typeorm';

import { Sortable } from '../_core/decorators/sortable.decorator';

import { StringUtils } from '../_core/utils/utils.string';

import { Organization } from '../organization/organization.entity';

export type PublicTag = Pick<Tag, 'id' | 'name' | 'promoted'>;

@Entity('tags')
@Index(['name', 'organizationId'], { unique: true })
export class Tag {
	constructor(value?: Partial<Tag>) {
		for(let k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', {
		nullable: false,
		transformer: {
			to: StringUtils.titleCase,
			from: StringUtils.titleCase
		}
	})
	@Sortable
	name: string;

	@Column('boolean', { nullable: false, default: false})
	promoted: boolean;

	@Column('text', {
		array: true,
		default: '{}',
		nullable: false
	})
	restrictions: string[];

	@Column('text', { nullable: false })
	organizationId: string;
	@ManyToOne(
		() => Organization,
		{
			eager: false,
			nullable: false,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'organizationId' })
	organization: Organization;

	public toPublic(): PublicTag {
		return {
			id: this.id,
			name: this.name,
			promoted: this.promoted
		};
	}
}