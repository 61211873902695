import {
	Entity,
	PrimaryGeneratedColumn,
	Column,
	ManyToOne,
	JoinColumn,
	Index
} from 'typeorm';

import { Sortable } from '../_core/decorators/sortable.decorator';
import { Organization } from '../organization/organization.entity';
import { Category } from '../category/category.entity';
import { BrandStrategy } from './dtos/set-brand-strategy.dto';

export type PublicBrand = Pick<Brand, 'id' | 'name' | 'strategies' | 'color'>;

@Entity('brands')
@Index(['remoteId', 'organizationId'], { unique: true })
export class Brand {
	constructor(value?: Partial<Brand>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	remoteId: string;

	@Column('text', { nullable: false })
	@Sortable
	name: string;

	@Column('text', { nullable: false })
	organizationId: string;
	@ManyToOne(
		() => Organization,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'organizationId' })
	organization: Organization;

	@Column('ltree', { nullable: true })
	map?: string;

	@Column('jsonb', { nullable: true })
	strategies?: BrandStrategy[];

	@Column('boolean', { nullable: false, default: false })
	hidden: boolean;

	@Column('text', { nullable: true })
	color?: string;

	public toPublic(): PublicBrand {
		return {
			id: this.id,
			name: this.name,
			strategies: this.strategies,
			color: this.color
		};
	}
}
