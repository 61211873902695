import 'reflect-metadata';
import QueryFragmentGenerator from '../interfaces/query-fragment-generator.class';

export type Target = {
	new (...args: any[]): any
}

export function SortHelper(fragmentGenerator: QueryFragmentGenerator) {
	return (
		target: Object,
		propertyKey: string | symbol
	) => {
		Reflect.defineMetadata('SortHelper', fragmentGenerator, target, propertyKey);
	};
}