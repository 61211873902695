import {
	Column,
	Entity,
	PrimaryGeneratedColumn,
	ManyToOne,
	JoinColumn,
	OneToMany,
	ManyToMany,
	JoinTable
} from 'typeorm';

import { Sortable } from '../_core/decorators/sortable.decorator';

import { Organization } from '../organization/organization.entity';
import { User, PublicUser } from '../user/user.entity';

import { Plan, PublicPlan } from '../plan/plan.entity';
import { Program, PublicProgram } from '../program/program.entity';
import { FundingType, PublicFundingType } from '../funding-type/funding-type.entity';
import { FundingSource, PublicFundingSource } from '../funding-source/funding-source.entity';
import { CalendarLayer } from '../calendar-layer/calendar-layer.entity';
import { BudgetPeriodQuarter, PublicBudgetPeriodQuarter } from './budget-period-quarter.entity';

export enum BudgetPeriodState {
	Planning = 'planning',
	Activation = 'activation',
	Archived = 'archived'
}

export enum BrandStrategyPreference {
	Directed = 'directed',
	Freeform = 'freeform'
}

export type PublicBudgetPeriod = Pick<BudgetPeriod,
	'id' | 'name' | 'start' | 'end' | 'hidePlanningFundingTypes' | 'brandStrategyPreference' |
	'state' | 'created' | 'deleted'
> & {
	author: PublicUser,
	plans?: PublicPlan[],
	programs?: PublicProgram[],
	planningFundingTypes?: PublicFundingType[],
	planningFundingSources?: PublicFundingSource[];
	quarters?: PublicBudgetPeriodQuarter[];
};

@Entity('budgetPeriods')
// TODO: May want to make these unique by period name or something.
// NOTE: It's not enforced at the DB level, but periods must not overlap start/end dates.
// @Unique(['something', 'else'])
export class BudgetPeriod {
	constructor(value?: Partial<BudgetPeriod>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	@Sortable
	name: string;

	@Column('text', { nullable: false })
	organizationId: string;
	@ManyToOne(
		() => Organization,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'organizationId' })
	organization: Organization;

	@Column({ type: 'timestamptz', nullable: false })
	@Sortable
	start: string;

	@Column({ type: 'timestamptz', nullable: false })
	@Sortable
	end: string;

	@Column({
		type: 'enum',
		enum: BudgetPeriodState,
		default: BudgetPeriodState.Planning
	})
	@Sortable
	state: BudgetPeriodState;

	@Column('boolean', { default: false, nullable: false })
	deleted: boolean;

	@OneToMany(
		() => Plan,
		plan => plan.budgetPeriod,
		{
			onDelete: 'CASCADE',
			nullable: true
		}
	)
	plans?: Plan[] | Partial<Plan>[];

	@OneToMany(
		() => Program,
		program => program.budgetPeriod,
		{
			onDelete: 'CASCADE',
			nullable: true
		}
	)
	programs?: Program[] | Partial<Program>[];


	@ManyToMany(
		() => FundingSource,
		{
			nullable: true
		}
	)
	@JoinTable({ name: 'budgetPeriodPlanningFundingSources' })
	planningFundingSources?: FundingSource[] | Partial<FundingSource>[];

	@ManyToMany(
		() => FundingType,
		{
			nullable: true
		}
	)
	@JoinTable({ name: 'budgetPeriodPlanningFundingTypes' })
	planningFundingTypes?: FundingType[] | Partial<FundingType>[];

	@OneToMany(
		() => CalendarLayer,
		calendarLayer => calendarLayer.budgetPeriod,
		{
			onDelete: 'CASCADE',
			nullable: true
		}
	)
	calendarLayers?: CalendarLayer[] | Partial<CalendarLayer>[];

	@OneToMany(
		() => BudgetPeriodQuarter,
		budgetPeriodQuarter => budgetPeriodQuarter.budgetPeriod,
		{
			onDelete: 'CASCADE',
			nullable: true
		}
	)
	quarters?: BudgetPeriodQuarter[] | Partial<BudgetPeriodQuarter>[];

	@Column('boolean', { default: false, nullable: false })
	hidePlanningFundingTypes: boolean;

	@Column('enum', {
		enum: BrandStrategyPreference,
		default: BrandStrategyPreference.Freeform,
		nullable: false
	})
	brandStrategyPreference: BrandStrategyPreference;

	@Column('text', { nullable: false })
	authorId: string;
	@ManyToOne(
		() => User,
		{
			eager: true,
			onDelete: 'CASCADE'
		}
	)
	author: User;

	@Column({ type: 'timestamptz', nullable: false, default: () => 'NOW()' })
	@Sortable
	created: string;

	public toPublic(include: string[] = []): PublicBudgetPeriod {
		const pub: Partial<PublicBudgetPeriod> = {
			id: this.id,
			name: this.name,
			start: this.start,
			end: this.end,
			state: this.state,
			created: this.created,
			hidePlanningFundingTypes: this.hidePlanningFundingTypes,
			brandStrategyPreference: this.brandStrategyPreference,
			deleted: this.deleted
		};

		if(this.author) {
			pub.author = new User(this.author).toPublic();
		}

		if(include.includes('programs') && this.programs?.length) {
			pub.programs = (this.programs as Partial<Program>[])
				.map(p => {
					return new Program(p).toPublic();
				});
		}

		if(include.includes('plans') && this.plans?.length) {
			pub.plans = (this.plans as Partial<Plan>[])
				.map(p => {
					return new Plan(p).toPublic();
				});
		}

		if(this.planningFundingSources) {
			pub.planningFundingSources = (this.planningFundingSources as Partial<FundingSource>[])
				.map(p => {
					return new FundingSource(p).toPublic();
				});
		}

		if(this.planningFundingTypes) {
			pub.planningFundingTypes = (this.planningFundingTypes as Partial<FundingType>[])
				.map(p => {
					return new FundingType(p).toPublic();
				});
		}

		if(this.quarters) {
			pub.quarters = (this.quarters as Partial<BudgetPeriodQuarter>[])
				.map(p => {
					return new BudgetPeriodQuarter(p).toPublic();
				});
		}


		return pub as PublicBudgetPeriod;
	}
}
