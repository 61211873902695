import {
	Entity,
	PrimaryGeneratedColumn,
	Column,
	ManyToOne,
	JoinColumn,
	Index
} from 'typeorm';

import { Organization } from '../organization/organization.entity';

export type PublicFundingType = Pick<
	FundingType,
	'id' | 'name'
>;

@Entity('fundingTypes')
@Index(['remoteId', 'organizationId'], { unique: true })
export class FundingType {
	constructor(value?: Partial<FundingType>) {
		for(let k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	remoteId: string;

	@Column('text', { nullable: false })
	name: string;

	@Column('text', { nullable: false })
	organizationId: string;
	@ManyToOne(
		type => Organization,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'organizationId' })
	organization: Organization;

	@Column('boolean', { nullable: false, default: false })
	hidden: boolean;

	public toPublic(): PublicFundingType {
		return {
			id: this.id,
			name: this.name
		};
	}
}