import {
	Entity,
	PrimaryGeneratedColumn,
	Column,
	ManyToOne
} from 'typeorm';

import { PublicUser, User } from '../user/user.entity';

export type PublicNote = Pick<Note, 'id' | 'body' | 'layoutLocationCode' | 'created'> & {
	author: PublicUser
};

@Entity('notes')
export class Note {
	constructor(value?: Partial<Note>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	body: string;

	@Column('text', { nullable: true })
	layoutLocationCode?: string;

	@Column('text', { nullable: false })
	authorId: string;
	@ManyToOne(
		type => User,
		{
			eager: true,
			onDelete: 'CASCADE'
		}
	)
	author: User;

	@Column({ type: 'timestamptz', nullable: false, default: () => 'NOW()' })
	created: string;

	public toPublic(): PublicNote {
		const pub: Partial<PublicNote> = {
			id: this.id,
			body: this.body,
			layoutLocationCode: this.layoutLocationCode,
			created: this.created
		};

		if(this.author) {
			pub.author = new User(this.author).toPublic();
		}

		return pub as PublicNote;
	}
}
