import {
	Entity,
	PrimaryGeneratedColumn,
	Column,
	ManyToOne,
	JoinColumn,
	OneToMany,
	ManyToMany,
	JoinTable,
	Index
} from 'typeorm';

import { Sortable } from '../_core/decorators/sortable.decorator';

import { Program, PublicProgram } from '../program/program.entity';
import { PublicUser, User } from '../user/user.entity';
import { InvestmentType, PublicInvestmentType } from '../investment-type/investment-type.entity';

export type PublicInvestment = Pick<
	Investment,
	'id' | 'amount' | 'programId' | 'investmentTypeId' | 'created'
> & {
	program: PublicProgram,
	investmentType: PublicInvestmentType,
	author: PublicUser
};

@Entity('investments')
export class Investment {
	constructor(value?: Partial<Investment>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	programId: string;
	@ManyToOne(
		type => Program,
		program => program.investments,
		{
			// cascade: ['insert', 'remove'],
			nullable: false,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'programId' })
	program: Program | Partial<Program>;

	@Column('decimal', { nullable: false })
	@Sortable
	amount: number;

	@Column('text', { nullable: false })
	investmentTypeId: string;
	@ManyToOne(
		type => InvestmentType,
		investmentType => investmentType.id,
		{
			nullable: false
		}
	)
	@JoinColumn({ name: 'investmentTypeId' })
	investmentType: InvestmentType | Partial<InvestmentType>;

	@Column('text', { nullable: false })
	authorId: string;
	@ManyToOne(
		type => User,
		{
			eager: true,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'authorId' })
	author: User;

	@Column({ type: 'timestamptz', nullable: false, default: () => 'NOW()' })
	@Sortable
	created: string;

	public toPublic(): PublicInvestment {
		const pub: Partial<PublicInvestment> = {
			id: this.id,
			amount: Number(this.amount),
			programId: this.programId,
			investmentTypeId: this.investmentTypeId,
			created: this.created
		};

		if(this.program) {
			pub.program = new Program(this.program).toPublic();
		}

		if(this.investmentType) {
			pub.investmentType = new InvestmentType(this.investmentType).toPublic();
		}

		if(this.author) {
			pub.author = new User(this.author).toPublic();
		}

		return pub as PublicInvestment;
	}
}
