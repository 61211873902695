import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpClient, HttpInterceptor } from '@angular/common/http';
import { Observable, Subject, from } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

import * as CryptoUtils from '../utils/crypto.utils';

@Injectable()
export class MegaGraphInterceptor implements HttpInterceptor {

	private requests = new Map<string, {subject: Subject<any>, subscribers: number, graphs: string[]}>();
	private delayWindow = 500; // Time in milliseconds to wait for additional requests

	constructor(private http: HttpClient) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Only intercept requests to 'mega-graph'
		if (req.url.includes('mega-graph')) {
			return from(this.manageRequest(req, next));
		}

		return next.handle(req);
	}

	/**
	 * Manages the request and returns an Observable that will share the same result with all subscribers
	 * Evaluates to determine if the request is new or if it should be combined with an existing request
	 * Combine the "graphs" parameter from the request body if the request is not new
	 */
	async manageRequest(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
		const key = await this.generateKey(req);

		// Extract the "graphs" parameter from the request body
		const graphs = req.body?.primary.graphs || [];

		// console.log('Request key:', key, graphs, req.body);

		// If the request is new, create a new Subject and send the request
		if (!this.requests.has(key)) {
			// console.log('New request, creating a new Subject');
			const subject = new Subject<HttpEvent<any>>();
			this.requests.set(key, { subject, subscribers: 1, graphs: req.body?.primary?.graphs || [] });

			// Wait for a short period of time to see if another request with the same key is made
			setTimeout(() => {
				if (this.requests.has(key)) {
					const combinedReq = this.requests.get(key);
					// console.log('Sending request', combinedReq);
					this.sendRequest(
						req.clone({ body: {
							...req.body,
							primary: {
								...req.body.primary,
								graphs: combinedReq.graphs
							}
						} }),
						next
					).subscribe(
						response => combinedReq.subject.next(response),
						error => combinedReq.subject.error(error),
						() => combinedReq.subject.complete()
					);
					this.requests.delete(key);
				}
			}, this.delayWindow);

		} else {
			// Same request has been made, increase the number of subscribers
			// and update the "graphs" parameter in the existing request
			console.log('Existing request, updating the number of subscribers and graphs');
			const existingReq = this.requests.get(key);
			existingReq.subscribers++;
			existingReq.graphs = [...existingReq.graphs, ...graphs];
		}

		// Return an Observable that will share the same result with all subscribers
		return this.requests.get(key).subject.asObservable().pipe(
			shareReplay(1)
		).toPromise();
	}

	/**
	 * Sends the request and returns an Observable that will share the same result with all subscribers.
	 */
	private sendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Directly handle the request here if you need to customize the sending logic,
		// or just pass it to the next handler.
		return next.handle(req);
	}

	/**
	 * Generates a unique key for the request based on the request body
	 */
	private async generateKey(req: HttpRequest<any>) {
		// Remove anything that we don't want to count towards the compare hash.
		const body = {
			...req.body,
			primary: {
				...req.body.primary,
				graphs: [],
				graphOptions: {}
			}
		};

		// Save any parameters that we want to include in the compare hash.

		if (req.body?.graphOptions?.budgetDistribution) {
			body.primary.graphOptions.budgetDistribution = req.body.primary.graphOptions.budgetDistribution;
		}

		// console.log('Hashing request body:', body);

		return await CryptoUtils.hashObject(body);
	}
}
