import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Plan, PreviousPeriodData } from './plan.model';

export interface PlanState extends EntityState<Plan>, ActiveState {
	previousPeriodData: PreviousPeriodData;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'plan' })
export class PlanStore extends EntityStore<PlanState> {
	constructor() {
		super();
	}
}
