import QueryFragmentGenerator from '../../_core/interfaces/query-fragment-generator.class';

import { SortStrategy } from '../../find/models/find.models';

export class WhereOptions {
	fileAlias?: string;
	fileMatch?: boolean;
};

export enum OrderKey {
	Name = 'name'
};

export class ProgramFragmentGenerator extends QueryFragmentGenerator {
	private aliasDefault: string = 'p2';
	private optionsDefault: WhereOptions = {
		fileAlias: 'f'
	};

	constructor(whereOptions?: WhereOptions) {
		super();

		this.optionsDefault = { ...this.optionsDefault, ...whereOptions };
	}

	public getFrom(
		whereOptions: WhereOptions = {},
		alias: string = this.aliasDefault
	) {
		whereOptions = { ...this.optionsDefault, ...whereOptions };

		const fragment = `
			, LATERAL (
				SELECT (
					SELECT
						JSON_BUILD_OBJECT (
							'name', ${alias}2."name"
						)
					FROM
						"programs" AS ${alias}2
					WHERE
						${this.getWhere(whereOptions, alias)}
				) AS "programValues"
			) AS ${alias}
		`;

		return fragment;
	}

	public getGroupBy(orderKey: OrderKey = OrderKey.Name, alias: string = this.aliasDefault) {
		if(!Object.values(OrderKey).includes(orderKey)) {
			orderKey = Object.values(OrderKey)[0];
		}
		return `${alias}."programValues" ->> '${orderKey}'`;
	}

	public getOrderBy(orderKey: OrderKey = OrderKey.Name, strategy: SortStrategy, alias: string = this.aliasDefault) {
		if(!Object.values(OrderKey).includes(orderKey)) {
			orderKey = Object.values(OrderKey)[0];
		}
		return `${alias}."programValues" ->> '${orderKey}' ${strategy}`;
	}

	private getWhere(whereOptions: WhereOptions, alias: string = this.aliasDefault) {
		let where = '';

		if(whereOptions.fileMatch) {
			where += `${alias}2."id" = ${whereOptions.fileAlias}."programId"`;
		}

		return where;
	}
}