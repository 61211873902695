import {
	Entity,
	PrimaryGeneratedColumn,
	Column,
	ManyToOne,
	JoinColumn,
	Index
} from 'typeorm';
import { Organization, PublicOrganization } from '../organization/organization.entity';
import { PublicVendorType, VendorType } from '../vendor-type/vendor-type.entity';

import { Sortable } from '../_core/decorators/sortable.decorator';

export type PublicVendor = Pick<Vendor, 'id' | 'name'> & {
	vendorType?: PublicVendorType;
	vendorTypeId?: string;
	logoUrl?: string;
	remoteId?: string;
	deleted?: boolean;
	organizationId?: string;
	organization?: PublicOrganization;
};

@Entity('vendors')
@Index(['name'], {
	unique: true
})
export class Vendor {
	constructor(value?: Partial<Vendor>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', {
		nullable: false//,
		// transformer: {
		// 	to: StringUtils.titleCase,
		// 	from: StringUtils.titleCase
		// }
	})
	@Sortable
	name: string;

	// Vendor remote id references Sygnl vendor ids.
	@Column('text', { nullable: true })
	remoteId?: string;

	@Column('text', { nullable: true })
	logoUrl?: string;

	@Column('boolean', { default: false, nullable: false })
	deleted: boolean;

	@Column('text', { nullable: true })
	vendorTypeId?: string;
	@ManyToOne(
		type => VendorType,
		vendorType => vendorType.id,
		{
			nullable: true,
			eager: true,
			cascade: true
		}
	)
	vendorType?: VendorType | Partial<VendorType>;

	@Column('text', { nullable: true })
	organizationId?: string;
	@ManyToOne(
		() => Organization,
		{
			eager: false,
			nullable: true,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'organizationId' })
	organization?: Organization;

	public toPublic(): PublicVendor {
		const pub: Partial<PublicVendor> = {
			id: this.id,
			name: this.name,
			organizationId: this.organizationId
		};

		if(this.vendorType) {
			pub.vendorType = new VendorType(this.vendorType).toPublic();
		}

		if(this.vendorTypeId) {
			pub.vendorTypeId = this.vendorTypeId;
		}

		if(this.logoUrl) {
			pub.logoUrl = this.logoUrl;
		}

		if(this.remoteId) {
			pub.remoteId = this.remoteId;
		}

		if(this.organizationId) {
			pub.organization = new Organization(this.organization).toPublic();
		}

		return pub as PublicVendor;
	}
}
