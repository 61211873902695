import { Column, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm';
import { Program } from '../program/program.entity';
import { Tactic } from '../tactic/tactic.entity';
import { Sortable } from '../_core/decorators/sortable.decorator';

export type PublicTacticGroup = Pick<TacticGroup, 'id' | 'name' | 'status' | 'tactics' | 'programId'>;

export enum TacticGroupStatus {
	Draft = 'draft',
	Approved = 'approved'
}

@Entity('tacticsGroups')
export class TacticGroup {
	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	name: string;

	@Column({
		type: 'enum',
		enum: TacticGroupStatus,
		default: TacticGroupStatus.Draft
	})
	@Sortable
	status: TacticGroupStatus;

	@Column('boolean', { default: false, nullable: false })
	deleted: boolean;

	@OneToMany(
		() => Tactic,
		tactic => tactic.tacticGroup,
		{
			eager: false,
			cascade: true
		}
	)
	tactics?: Tactic[] | Partial<Tactic>[];

	@Column('text', { nullable: true })
	programId: string;
	@ManyToOne(
		type => Program,
		program => program.tacticsGroups,
		{
			// cascade: ['insert', 'remove'],
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'programId' })
	program: Program | Partial<Program>;

	constructor(value?: Partial<TacticGroup>) {
		for (let k in value) {
			this[k] = value[k];
		}
	}

	public toPublic(): PublicTacticGroup {
		return {
			id: this.id,
			name: this.name,
			status: this.status,
			programId: this.programId,
			tactics: this.tactics
		};
	}
}
