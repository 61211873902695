import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from "typeorm";
import { Program } from "../program/program.entity";
import { Plan } from "./plan.entity";

export type PublicFeaturedProgram = Pick<
	FeaturedProgram,
	'id' | 'plansId' | 'programsId' | 'sortIdx'
> & {
	plansId: string,
	programsId: string,
	sortIdx: number,
};

@Entity('plansFeaturedPrograms')
export class FeaturedProgram {
	constructor(value?: Partial<FeaturedProgram>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('uuid')
	plansId: string;
	@ManyToOne(
		() => Plan,
		plan => plan.featuredPrograms,
		{
			onDelete: 'CASCADE',
			orphanedRowAction: 'delete'
		}
	)
	@JoinColumn({ name: 'plansId' })
	plan: Plan;

	@Column('uuid', { nullable: false })
	programsId: string;
	@ManyToOne(() => Program, {
		onDelete: 'CASCADE'
	})
	@JoinColumn({ name: 'programsId' })
	program: Program;

	@Column('numeric', { nullable: true })
	sortIdx?: number;

	public toPublic(): PublicFeaturedProgram {
		const pub: Partial<PublicFeaturedProgram> = {
			programsId: this.programsId,
			sortIdx: this.sortIdx
		};

		return pub as PublicFeaturedProgram;
	}
}
