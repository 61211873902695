import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from "typeorm";
import { Organization, PublicOrganization } from "../organization/organization.entity";
import { BudgetPeriod } from "../budget-period/budget-period.entity";

export type PublicCalendarLayer = Pick<
	CalendarLayer,
	'id' | 'name' | 'category' | 'color' | 'items' | 'created' | 'budgetPeriodId'
> & {
	organization?: PublicOrganization;
};

export class CalendarLayerItem {
	id: string;
	name: string;
	color?: string;
	start: string;
	end: string;
	meta: {
		[key: string]: any;
	}
}

@Entity('calendarLayers')
export class CalendarLayer {
	constructor(value?: Partial<CalendarLayer>) {
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: true })
	organizationId: string;
	@ManyToOne(
		type => Organization,
		organization => organization.id,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'organizationId' })
	organization?: Organization | Partial<Organization>;

	@Column('text', { nullable: false })
	budgetPeriodId: string;
	@ManyToOne(
		() => BudgetPeriod,
		budgetPeriod => budgetPeriod.calendarLayers,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'budgetPeriodId' })
	budgetPeriod: BudgetPeriod;

	@Column('text', { nullable: false })
	name: string;

	@Column('text', { nullable: false })
	category: string;

	@Column('text', { nullable: false })
	color: string;

	// Jsonb column
	@Column('jsonb', { nullable: true })
	items: CalendarLayerItem[];

	// Created
	@Column({ type: 'timestamptz', nullable: false, default: () => 'NOW()' })
	created: string;

	public toPublic(): PublicCalendarLayer {
		let pub: Partial<PublicCalendarLayer> = {
			id: this.id,
			budgetPeriodId: this.budgetPeriodId,
			name: this.name,
			category: this.category,
			color: this.color,
			items: this.items
		};

		if (this.organization) {
			pub.organization = new Organization(this.organization).toPublic();
		}

		return pub as PublicCalendarLayer;
	}

}
