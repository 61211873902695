import { Query as InvoiceQuery } from '../../invoice/utils/query.utils';
import { Query as MilestoneQuery } from '../../milestone/utils/query.utils';
import { Query as CostQuery } from '../../cost/utils/query.utils';
import { Query as WarningQuery } from '../../warning/utils/query.utils';
import { Query as FileQuery } from '../../file/utils/query.utils';
import { Query as ExternalIdQuery } from '../../external-id/utils/query.utils';
import { ProgramSelect, Query as ProgramQuery } from '../../program/utils/query.utils';

export enum TacticSelect {
	TacticType = 'tacticType',
	TacticPhase = 'tacticPhase',
	FundingSource = 'fundingSource',
	RMN = 'rmn',
	DueDate = 'dueDate',
	NextSteps = 'nextSteps',
	BuySpecs = 'buySpecs',
	Flowchart = 'flowchart',
	LandingPage = 'landingPage',
	Brands = 'brands',
	Notes = 'notes',
	Tags = 'tags',
	Vendors = 'vendors',
	Costs = 'costs',
	Offers = 'offers',
	Invoices = 'invoices',
	BudgetCache = 'budgetCache',
	BrandCaches = 'brandCaches',
	Author = 'author',
	Milestones = 'milestones',
	Location = 'location',
	// This only returns id, budgetPeriodId.
	// Refactor if more are needed.
	Program = 'program',
	TacticGroup = 'tacticGroup',
	Warnings = 'warnings',
	Files = 'files',
	Measurements = 'measurements',
	ExternalIds = 'externalIds',
	TacticAgency = 'tacticAgency',
}

export interface TacticSubSelects {
	programSelects?: ProgramSelect[];
}
export interface QueryOptions {
	includeDeletedCosts?: boolean;
}
export class Query {
	private static readonly TACTIC_QUERY_ALIAS: string = 't';

	public static getSelects(
		alias: string = this.TACTIC_QUERY_ALIAS,
		targets: TacticSelect[] = Object.values(TacticSelect),
		asJsonSelect: boolean = false,
		excludes: TacticSelect[] = [],
		hasTotal: boolean = false
	) {
		if (!targets) {
			targets = [];
		}
		const selects: string[] = [];
		const json: boolean = asJsonSelect;

		selects.push(`
			${json ? `'id', ${alias}.id` : `${alias}.id`}
		`);
		selects.push(`
			${json ? `'name', ${alias}.name` : `${alias}.name`}
		`);
		selects.push(`
			${json ? `'detail', ${alias}.detail` : `${alias}.detail`}
		`);
		selects.push(`
			${
				json
					? `'start',to_char((${alias}.start)::date + interval '12 hour', 'YYYY-MM-DD"T"HH24:MI:SS"Z"')`
					: `to_char((${alias}.start)::date + interval '12 hour', 'YYYY-MM-DD"T"HH24:MI:SS"Z"') AS start`
			}
		`);
		selects.push(`
			${
				json
					? `'end',to_char((${alias}.end)::date + interval '12 hour', 'YYYY-MM-DD"T"HH24:MI:SS"Z"')`
					: `to_char((${alias}.end)::date + interval '12 hour', 'YYYY-MM-DD"T"HH24:MI:SS"Z"') AS end`
			}
		`);
		selects.push(`
			${
				json
					? `'lastCouponExpiration',to_char((${alias}."lastCouponExpiration")::date + interval '12 hour', 'YYYY-MM-DD"T"HH24:MI:SS"Z"')`
					: `to_char((${alias}."lastCouponExpiration")::date + interval '12 hour', 'YYYY-MM-DD"T"HH24:MI:SS"Z"') AS "lastCouponExpiration"`
			}
		`);
		selects.push(`
			${json ? `'destinationURL', ${alias}."destinationURL"` : `${alias}."destinationURL"`}
		`);
		selects.push(`
			${json ? `'deleted', ${alias}.deleted` : `${alias}.deleted`}
		`);
		selects.push(`
			${json ? `'locationId', ${alias}."locationId"` : `${alias}."locationId"`}
		`);
		selects.push(`
			${json ? `'programId', ${alias}."programId"` : `${alias}."programId"`}
		`);
		selects.push(`
			${json ? `'tacticTypeId', ${alias}."tacticTypeId"` : `${alias}."tacticTypeId"`}
		`);
		selects.push(`
			${json ? `'tacticGroupId', ${alias}."tacticGroupId"` : `${alias}."tacticGroupId"`}
		`);
		selects.push(`
			${json ? `'tacticPhaseId', ${alias}."tacticPhaseId"` : `${alias}."tacticPhaseId"`}
		`);
		selects.push(`
			${json ? `'fundingSourceId', ${alias}."fundingSourceId"` : `${alias}."fundingSourceId"`}
		`);
		selects.push(`
			${json ? `'commercePlatformWorkflow', ${alias}."commercePlatformWorkflow"` : `${alias}."commercePlatformWorkflow"`}
		`);
		selects.push(`
			${json ? `'authorId', ${alias}."authorId"` : `${alias}."authorId"`}
		`);

		selects.push(`
			${json ? `'created', ${alias}.created` : `${alias}.created`}
		`);

		if (targets.includes(TacticSelect.RMN) && !excludes.includes(TacticSelect.RMN)) {
			selects.push(`
				${json ? `'rmn', ${alias}.rmn` : `${alias}.rmn`}
			`);
		}

		if (targets.includes(TacticSelect.DueDate) && !excludes.includes(TacticSelect.DueDate)) {
			selects.push(`
				${
					json
						? `'dueDate',to_char((${alias}."dueDate")::date + interval '12 hour', 'YYYY-MM-DD"T"HH24:MI:SS"Z"')`
						: `to_char((${alias}."dueDate")::date + interval '12 hour', 'YYYY-MM-DD"T"HH24:MI:SS"Z"') AS "dueDate"`
				}
			`);
		}

		if (targets.includes(TacticSelect.NextSteps) && !excludes.includes(TacticSelect.NextSteps)) {
			selects.push(`
				${json ? `'nextSteps', ${alias}."nextSteps"` : `${alias}."nextSteps"`}
			`);
		}

		if (targets.includes(TacticSelect.BuySpecs) && !excludes.includes(TacticSelect.BuySpecs)) {
			selects.push(`
				${json ? `'buySpecs', ${alias}."buySpecs"` : `${alias}."buySpecs"`}
			`);
		}

		if (targets.includes(TacticSelect.Flowchart) && !excludes.includes(TacticSelect.Flowchart)) {
			selects.push(`
				${json ? `'flowchart', ${alias}.flowchart` : `${alias}.flowchart`}
			`);
		}

		if (targets.includes(TacticSelect.LandingPage) && !excludes.includes(TacticSelect.LandingPage)) {
			selects.push(`
				${json ? `'landingPage', ${alias}."landingPage"` : `${alias}."landingPage"`}
			`);
		}

		selects.push(`
			${json ? `'landingPage', ${alias}."landingPage"` : `${alias}."landingPage"`}
		`);
		selects.push(`
			${json ? `'plannedOffersData', ${alias}."plannedOffersData"` : `${alias}."plannedOffersData"`}
        `);

		if (hasTotal) {
			selects.push(`
				${json ? `'total', ${alias}.total` : `${alias}.total`}
			`);
		}

		if (targets.includes(TacticSelect.TacticType)) {
			if (json) {
				selects.push(`
					'tacticType', ${alias}_tactictype."tacticType"
				`);
			} else {
				selects.push(`
					${alias}_tactictype."tacticType" AS "tacticType"
				`);
			}
		}

		if (targets.includes(TacticSelect.TacticPhase)) {
			if (json) {
				selects.push(`
					'tacticPhase', ${alias}_tacticphase."tacticPhase"
				`);
			} else {
				selects.push(`
					${alias}_tacticphase."tacticPhase" AS "tacticPhase"
				`);
			}
		}

		if (targets.includes(TacticSelect.FundingSource)) {
			if (json) {
				selects.push(`
					'fundingSource', ${alias}_fundingsource."fundingSource"
				`);
			} else {
				selects.push(`
					${alias}_fundingsource."fundingSource" AS "fundingSource"
				`);
			}
		}

		if (targets.includes(TacticSelect.Brands)) {
			if (json) {
				selects.push(`
					'brands', ${alias}_brands."brandsArr"
				`);
			} else {
				selects.push(`
					${alias}_brands."brandsArr" AS "brands"
				`);
			}
		}

		if (targets.includes(TacticSelect.Notes)) {
			if (json) {
				selects.push(`
					'notes', ${alias}_notes."notesArr"
				`);
			} else {
				selects.push(`
					${alias}_notes."notesArr" AS "notes"
				`);
			}
		}

		if (targets.includes(TacticSelect.Tags)) {
			if (json) {
				selects.push(`
					'tags', ${alias}_tags."tagsArr"
				`);
			} else {
				selects.push(`
					${alias}_tags."tagsArr" AS "tags"
				`);
			}
		}

		if (targets.includes(TacticSelect.Vendors) && !excludes.includes(TacticSelect.Vendors)) {
			if (json) {
				selects.push(`
					'vendors', ${alias}_vendors."vendorsArr"
				`);
			} else {
				selects.push(`
					${alias}_vendors."vendorsArr" AS "vendors"
				`);
			}
		}

		if (targets.includes(TacticSelect.Costs)) {
			if (json) {
				selects.push(`
					'costs', ${alias}_costs."costsArr"
				`);
			} else {
				selects.push(`
					${alias}_costs."costsArr" AS "costs"
				`);
			}
		}

		if (targets.includes(TacticSelect.Offers)) {
			if (json) {
				selects.push(`
					'offers', ${alias}_offers."offersArr"
				`);
			} else {
				selects.push(`
					${alias}_offers."offersArr" AS "offers"
				`);
			}
		}

		if (targets.includes(TacticSelect.Invoices)) {
			if (json) {
				selects.push(`
					'invoices', ${alias}_invoices."invoicesArr"
				`);
			} else {
				selects.push(`
					${alias}_invoices."invoicesArr" AS "invoices"
				`);
			}
		}

		if (targets.includes(TacticSelect.BudgetCache)) {
			if (json) {
				selects.push(`
					'budgetCache', ${alias}_budgetcache."budgetCache"
				`);
			} else {
				selects.push(`
					${alias}_budgetcache."budgetCache" AS "budgetCache"
				`);
			}
		}

		if (targets.includes(TacticSelect.BrandCaches)) {
			if (json) {
				selects.push(`
					'brandCaches', ${alias}_brandcaches."brandCaches"
				`);
			} else {
				selects.push(`
					${alias}_brandcaches."brandCaches" AS "brandCaches"
				`);
			}
		}

		if (targets.includes(TacticSelect.Author)) {
			if (json) {
				selects.push(`
					'author', ${alias}_author.author
				`);
			} else {
				selects.push(`
					${alias}_author.author AS author
				`);
			}
		}

		if (targets.includes(TacticSelect.Location)) {
			if (json) {
				selects.push(`
					'location', ${alias}_location."location"
				`);
			} else {
				selects.push(`
					${alias}_location."location" AS "location"
				`);
			}
		}

		if (targets.includes(TacticSelect.Program)) {
			if (json) {
				selects.push(`
					'program', ${alias}_program.program
				`);
			} else {
				selects.push(`
					${alias}_program.program AS program
				`);
			}
		}

		if (targets.includes(TacticSelect.Warnings)) {
			if (json) {
				selects.push(`
					'warnings', ${alias}_warnings."warningsArr"
				`);
			} else {
				selects.push(`
					${alias}_warnings."warningsArr" AS warnings
				`);
			}
		}

		if (targets.includes(TacticSelect.Files)) {
			if (json) {
				selects.push(`
					'files', ${alias}_files."filesArr"
				`);
			} else {
				selects.push(`
					${alias}_files."filesArr" AS files
				`);
			}
		}

		if (targets.includes(TacticSelect.Measurements)) {
			if (json) {
				selects.push(`
					'measurements', ${alias}_measurements."measurementsArr"
				`);
			} else {
				selects.push(`
					${alias}_measurements."measurementsArr" AS measurements
				`);
			}
		}

		if (targets.includes(TacticSelect.ExternalIds)) {
			if (json) {
				selects.push(`
					'externalIds', ${alias}_externalids."externalIdsArr"
				`);
			} else {
				selects.push(`
					${alias}_externalids."externalIdsArr" AS "externalIds"
				`);
			}
		}

		if (targets.includes(TacticSelect.Milestones)) {
			if (json) {
				selects.push(`
					'milestones', ${alias}_milestones."milestonesArr"
				`);
			} else {
				selects.push(`
					${alias}_milestones."milestonesArr" AS "milestones"
				`);
			}
		}
		if (targets.includes(TacticSelect.TacticGroup)) {
			if (json) {
				selects.push(`
					'tacticGroup', ${alias}_tacticgroup."tacticGroup"
				`);
			} else {
				selects.push(`
					${alias}_tacticgroup."tacticGroup" AS "tacticGroup"
				`);
			}
		}

		if (targets.includes(TacticSelect.TacticAgency) && !excludes.includes(TacticSelect.TacticAgency)) {
			selects.push(`
				${json ? `'tacticAgency', ${alias}_tacticagency."tacticAgency"` : `${alias}_tacticagency."tacticAgency"`}
			`);
		}

		return selects.join(',');
	}

	public static getSubqueries(
		alias: string = this.TACTIC_QUERY_ALIAS,
		targets: TacticSelect[] = Object.values(TacticSelect),
		subSelects?: TacticSubSelects,
		// TODO
		excludes: TacticSelect[] = [],
		options?: QueryOptions
	) {
		if (!targets?.length) {
			return '';
		}

		const subQueries: string[] = [];

		if (targets.includes(TacticSelect.Program)) {
			const programSelects = [ProgramSelect.BudgetPeriod, ...(subSelects?.programSelects || [])];
			subQueries.push(`
				LATERAL (
					SELECT
						JSON_BUILD_OBJECT (
							${ProgramQuery.getSelects('p2', programSelects, true)}
						) AS "program"
					FROM
						"programs" AS p2
						${ProgramQuery.getSubqueries('p2', programSelects)}
					WHERE
						p2.id = ${alias}."programId"
				) AS ${alias}_program
			`);
		}

		if (targets.includes(TacticSelect.TacticType)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSON_BUILD_OBJECT (
								'id', tt.id,
								'name', tt.name,
								'tacticCategoryId', tt."tacticCategoryId",
								'tacticCategory', "tacticCategory"."category",
								'mediaType', tt."mediaType"
							)
						FROM
							"tacticTypes" AS tt,
							LATERAL (
								SELECT
									JSON_BUILD_OBJECT (
										'id', tc.id,
										'name', tc.name
									) AS "category"
								FROM
									"tacticCategories" AS tc
								WHERE
									tc.id = tt."tacticCategoryId"
							) AS "tacticCategory"
						WHERE
							tt.id = ${alias}."tacticTypeId"
					) AS "tacticType"
				) AS ${alias}_tactictype
			`);
		}

		if (targets.includes(TacticSelect.TacticPhase)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSON_BUILD_OBJECT (
								'id', tp2.id,
								'name', tp2.name,
								'color', tp2.color
							)
						FROM
							"tacticPhases" AS tp2
						WHERE
							tp2.id = ${alias}."tacticPhaseId"
					) AS "tacticPhase"
				) AS ${alias}_tacticphase
			`);
		}

		if (targets.includes(TacticSelect.FundingSource)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSON_BUILD_OBJECT (
								'id', tfs2.id,
								'name', tfs2.name
							)
						FROM
							"fundingSources" AS tfs2
						WHERE
							tfs2.id = ${alias}."fundingSourceId"
					) AS "fundingSource"
				) AS ${alias}_fundingsource
			`);
		}

		if (targets.includes(TacticSelect.Location) && !excludes.includes(TacticSelect.Location)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSON_BUILD_OBJECT (
								'id', l2.id,
								'name', l2.name,
								'type', l2.type,
								'map', l2.map,
								'parent', COALESCE(ROW_TO_JSON(lparent), NULL)
							)
						FROM
							locations AS l2
						LEFT JOIN
							locations AS lparent
							ON
								NLEVEL(l2."map") > 1
								AND
								lparent."map" = SUBPATH(l2."map", 0, -1)
						WHERE
							l2.id = ${alias}."locationId"
					) AS "location"
				) AS ${alias}_location
			`);
		}

		if (targets.includes(TacticSelect.Brands)) {
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								'id', tb3.id,
								'name', tb3.name
							)
						FROM
							"tacticBrands" AS tb2
						LEFT JOIN
							brands AS tb3
							ON
								tb3.id = tb2."brandsId"
						WHERE
							tb2."tacticsId" = ${alias}.id
					) AS "brandsArr"
				) AS ${alias}_brands
			`);
		}

		if (targets.includes(TacticSelect.Notes)) {
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								'id', n2.id,
								'layoutLocationCode', n2."layoutLocationCode",
								'body', n2.body,
								'author', JSON_BUILD_OBJECT (
									'id', u2.id,
									'email', u2."email",
									'profile', u2."profile"
								),
								'created', n2.created
							)
						FROM
							"tacticNotes" AS tn2
						LEFT JOIN
							notes AS n2
							ON
								n2.id = tn2."notesId"
						LEFT JOIN
							users AS u2
							ON
								n2."authorId" = u2.id
						WHERE
							tn2."tacticsId" = ${alias}.id
					) AS "notesArr"
				) AS ${alias}_notes
			`);
		}

		if (targets.includes(TacticSelect.Tags)) {
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								'id', tags.id,
								'name', tags.name
							)
						FROM
							"tacticTags" AS ttags2
						LEFT JOIN
							tags
							ON
								tags.id = ttags2."tagsId"
						WHERE
							ttags2."tacticsId" = ${alias}.id
					) AS "tagsArr"
				) AS ${alias}_tags
			`);
		}

		if (targets.includes(TacticSelect.Vendors) && !excludes.includes(TacticSelect.Vendors)) {
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								'id', vendors.id,
								'name', vendors.name,
								'remoteId', vendors."remoteId",
								'vendorTypeId', vendors."vendorTypeId",
								'logoUrl', vendors."logoUrl"
							)
						FROM
							"tacticVendors" AS tvendors2
						LEFT JOIN
							vendors
							ON
								vendors.id = tvendors2."vendorsId"
						WHERE
							tvendors2."tacticsId" = ${alias}.id
					) AS "vendorsArr"
				) AS ${alias}_vendors
			`);
		}

		if (targets.includes(TacticSelect.Costs)) {
			const costAlias = 'co2';

			let deletedQuery = `AND ${costAlias}.deleted != true`;
			if (options?.includeDeletedCosts) {
				deletedQuery = '';
			}

			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								${CostQuery.getSelects(costAlias, undefined, true)}
							)
						FROM
							"costs" AS ${costAlias}
							${CostQuery.getSubqueries(costAlias)}
						WHERE
							${costAlias}."tacticId" = ${alias}.id
							${deletedQuery}
					) AS "costsArr"
				) AS ${alias}_costs
			`);
		}

		if (targets.includes(TacticSelect.Offers)) {
			/* subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								'id', o2.id,
								'name', o2."name",
								'code', o2."code",
								'created', o2.created,
								'deleted', o2.deleted,
								'author', JSON_BUILD_OBJECT (
									'id', u2.id,
									'email', u2."email",
									'profile', u2."profile"
								),
								'data', o2."data"
							)
						FROM
							offers AS o2
						LEFT JOIN
							users AS u2
							ON
								u2.id = o2."authorId"
						WHERE
							o2."tacticId" = ${alias}.id AND o2.deleted != true
					) AS "offersArr"
				) AS ${alias}_offers
			`); */
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								'id', o2.id,
								'name', o2."name",
								'code', o2."code",
								'created', o2.created,
								'deleted', o2.deleted,
								'author', JSON_BUILD_OBJECT (
									'id', u2.id,
									'email', u2."email",
									'profile', u2."profile"
								),
								'data', o2."data",
								'measurements', (
									SELECT ARRAY (
										SELECT
											JSON_BUILD_OBJECT (
												'id', m.id,
												'measurementTypeId', m."measurementTypeId",
												'tacticId', m."tacticId",
												'benchmark', m.benchmark,
												'value', m.value,
												'created', m.created,
												'measurementType', JSON_BUILD_OBJECT (
													'id', mt.id,
													'name', mt."name",
													'unit', mt."unit",
													'aggregations', mt."aggregations"
												),
												'author', JSON_BUILD_OBJECT (
													'id', au.id,
													'email', au."email",
													'profile', au."profile"
												)
											)
										FROM
											"measurements" AS m
										LEFT JOIN
											"measurementTypes" AS mt
										ON
											mt.id = m."measurementTypeId"
										LEFT JOIN
											"users" AS au
										ON
											au.id = m."authorId"
										WHERE
											m."offerId" = o2.id
									)
								)
							)
						FROM
							"offers" AS o2
						LEFT JOIN
							"users" AS u2
						ON
							u2.id = o2."authorId"
						WHERE
							o2."tacticId" = ${alias}.id AND o2.deleted != true
					) AS "offersArr"
				) AS ${alias}_offers
			`);
		}

		if (targets.includes(TacticSelect.Invoices)) {
			const invoiceAlias = 'i2';
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								${InvoiceQuery.getSelects(invoiceAlias, undefined, true)}
							)
						FROM
							"invoices" AS ${invoiceAlias}
							${InvoiceQuery.getSubqueries(invoiceAlias)}
						WHERE
							${invoiceAlias}."tacticId" = ${alias}.id
					) AS "invoicesArr"
				) AS ${alias}_invoices
			`);
		}

		if (targets.includes(TacticSelect.BudgetCache)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSON_BUILD_OBJECT (
								'id', tbc2.id,
								'amountPlanned', tbc2."amountPlanned",
								'amountActual', tbc2."amountActual",
								'spendEstimated', tbc2."spendEstimated",
								'spendActual', tbc2."spendActual",
								'details', tbc2."details"
							)
						FROM
							"budgetCaches" AS tbc2
						WHERE
							(tbc2."tacticId" = ${alias}.id AND tbc2."brandId" IS NULL)
					) AS "budgetCache"
				) AS ${alias}_budgetcache
			`);
		}

		if (targets.includes(TacticSelect.BrandCaches)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							COALESCE(
								JSON_AGG(
									JSON_BUILD_OBJECT (
										'amountEstimated', bc2."amountEstimated",
										'amountPlanned', bc2."amountPlanned",
										'amountActual', bc2."amountActual",
										'spendEstimated', bc2."spendEstimated",
										'spendActual', bc2."spendActual",
										'details', bc2."details"
									)
								), '[]'
							)
						FROM
							"budgetCaches" AS bc2
						WHERE
							bc2."tacticId" = ${alias}.id
							AND bc2."brandId" IS NOT NULL
					) AS "brandCaches"
				) AS ${alias}_brandcaches
			`);
		}

		if (targets.includes(TacticSelect.Author)) {
			subQueries.push(`
				LATERAL (
					SELECT
						JSON_BUILD_OBJECT (
							'id', u2.id,
							'email', u2."email",
							'profile', u2."profile"
						) AS "author"
					FROM
						"users" AS u2
					WHERE
						u2.id = ${alias}."authorId"
				) AS ${alias}_author
			`);
		}

		if (targets.includes(TacticSelect.TacticGroup)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSON_BUILD_OBJECT (
								'id', tg.id,
								'name', tg.name,
								'status', tg.status
							)
						FROM
							"tacticsGroups" AS tg
						WHERE
							tg.id = ${alias}."tacticGroupId"
							AND tg.deleted = false
					) AS "tacticGroup"
				) AS ${alias}_tacticgroup
			`);
		}

		if (targets.includes(TacticSelect.Warnings)) {
			const warningAlias: string = 'w';
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								${WarningQuery.getSelects(warningAlias, true)}
							)
						FROM
							"warnings" AS ${warningAlias}
						WHERE
							${warningAlias}."tacticId" = ${alias}.id
							AND ${warningAlias}."invoiceId" IS NULL
							AND ${warningAlias}.dismissed = false
					) AS "warningsArr"
				) AS ${alias}_warnings
			`);
		}

		if (targets.includes(TacticSelect.Files)) {
			const fileAlias: string = 'tf';
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								${FileQuery.getSelects(fileAlias, null, true)}
							)
						FROM
							"files" AS ${fileAlias}
						WHERE
							${fileAlias}."tacticId" = ${alias}.id
					) AS "filesArr"
				) AS ${alias}_files
			`);
		}

		if (targets.includes(TacticSelect.Measurements)) {
			const measurementAlias: string = 'tm';
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								'id', ${measurementAlias}.id,
								'measurementType', JSON_BUILD_OBJECT (
									'id', mt.id,
									'name', mt.name,
									'unit', mt.unit,
									'aggregations', mt.aggregations
								),
								'benchmark', ${measurementAlias}.benchmark,
								'value', ${measurementAlias}.value,
								'author', JSON_BUILD_OBJECT (
									'id', u2.id,
									'email', u2."email",
									'profile', u2."profile"
								),
								'created', ${measurementAlias}.created,
								'offer', CASE
            						WHEN o.id IS NOT NULL THEN
										JSON_BUILD_OBJECT (
											'id', o.id,
											'name', o.name,
											'code', o.code,
											'created', o.created,
											'deleted', o.deleted,
											'author', JSON_BUILD_OBJECT (
												'id', u3.id,
												'email', u3.email,
												'profile', u3.profile
											),
											'data', o.data
										)
									ELSE NULL
          						END
							)
						FROM
							"measurements" AS ${measurementAlias}
						LEFT JOIN
							"measurementTypes" AS mt
							ON
								mt.id = ${measurementAlias}."measurementTypeId"
						LEFT JOIN
							users AS u2
							ON
								u2.id = ${measurementAlias}."authorId"
						LEFT JOIN
							"offers" AS o
							ON
								o.id = ${measurementAlias}."offerId"
						LEFT JOIN
							users AS u3
							ON
								u3.id = o."authorId"
						WHERE
							${measurementAlias}."tacticId" = ${alias}.id
					) AS "measurementsArr"
				) AS ${alias}_measurements
			`);
		}

		if (targets.includes(TacticSelect.ExternalIds)) {
			const externalIdAlias: string = 'eid';
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								${ExternalIdQuery.getSelects('eid', undefined, true)}
							)
						FROM
							"externalIds" AS ${externalIdAlias}
						${ExternalIdQuery.getSubqueries('eid')}
						WHERE
							${externalIdAlias}."tacticId" = ${alias}.id
					) AS "externalIdsArr"
				) AS ${alias}_externalids
			`);
		}

		if (targets.includes(TacticSelect.Milestones)) {
			const milestonesAlias = 'm2';
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								${MilestoneQuery.getSelects(milestonesAlias, undefined, true)}
							)
						FROM
							"milestones" AS ${milestonesAlias}
							${MilestoneQuery.getSubqueries(milestonesAlias)}
						WHERE
							${milestonesAlias}."tacticId" = ${alias}.id
					) AS "milestonesArr"
				) AS ${alias}_milestones
			`);
		}

		if (targets.includes(TacticSelect.TacticAgency) && !excludes.includes(TacticSelect.TacticAgency)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSON_BUILD_OBJECT (
								'id', ta2.id,
								'name', ta2.name,
								'order', ta2.order
							)
						FROM
							"tacticAgencies" AS ta2
						WHERE
							ta2.id = ${alias}."tacticAgencyId"
					) AS "tacticAgency"
				) AS ${alias}_tacticagency
			`);
		}

		if (!subQueries.length) {
			return '';
		}

		return ',\n' + subQueries.join(',');
	}
}
