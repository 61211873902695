import { PublicBudgetPeriod } from '../../../../../api/src/budget-period/budget-period.entity';
import { formatDate } from '@angular/common';
import { PublicBudgetPeriodQuarter } from '../../../../../api/src/budget-period/budget-period-quarter.entity';
import moment from 'moment';

export type DateRangePreset =
	| 'full-period'
	| 'ytd'
	| 'mtd'
	| 'qtd'
	| 'this-month'
	| 'previous-month'
	| 'this-quarter'
	| 'previous-quarter'
	| 'next-quarter'
	| 'q1'
	| 'q2'
	| 'q3'
	| 'q4';

enum QuarterIndex {
	Q1 = 0,
	Q2 = 1,
	Q3 = 2,
	Q4 = 3
}

export function getDateAsPostgresStyle(date: Date): string {
	return date
		.toISOString()
		.replace('T', ' ')
		.replace('Z', '');
}

export function dateDiffInWeeks(start: Date, end: Date): number {
	return moment(end).diff(moment(start), 'weeks');
}

export function dateDiffInMonths(start: Date, end: Date): number {
	return moment(end).diff(moment(start), 'months');
}

// a and b are javascript Date objects
export function dateDiffInDays(a, b) {
	if (!(a instanceof Date)) {
		a = new Date(a);
	}
	if (!(b instanceof Date)) {
		b = new Date(b);
	}
	const _MS_PER_DAY = 1000 * 60 * 60 * 24;

	// Discard the time and time-zone information.
	const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
	const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

	return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function addDays(date, days) {
	return new Date(
		date.getFullYear(),
		date.getMonth(),
		date.getDate() + days,
		date.getHours(),
		date.getMinutes(),
		date.getSeconds(),
		date.getMilliseconds()
	);
}

export function subtractDays(date, days) {
	return new Date(
		date.getFullYear(),
		date.getMonth(),
		date.getDate() - days,
		date.getHours(),
		date.getMinutes(),
		date.getSeconds(),
		date.getMilliseconds()
	);
}

export function getDateRangeFromPreset(preset: DateRangePreset, relativeDate: Date, budgetPeriods?: PublicBudgetPeriod[]) {
	budgetPeriods = budgetPeriods?.filter(bp => !!bp) || [];
	switch (preset) {
		case 'full-period':
			if (budgetPeriods?.length) {
				const { earliest, latest } = findEarliestAndLatestDate(budgetPeriods);
				// Budget period start and end are strings, so convert them to dates.
				const budgetPeriodStart = new Date(earliest);
				const budgetPeriodEnd = new Date(latest);

				return {
					start: budgetPeriodStart,
					end: budgetPeriodEnd
				};
			} else {
				return {
					start: new Date(relativeDate.getFullYear(), 0, 1),
					end: new Date(relativeDate.getFullYear(), 11, 31)
				};
			}

		case 'ytd': // Year to Date
			return {
				start: new Date(relativeDate.getFullYear(), 0, 1),
				end: relativeDate
			};

		case 'mtd': // Month to Date
			return {
				start: new Date(relativeDate.getFullYear(), relativeDate.getMonth(), 1),
				end: relativeDate
			};

		case 'qtd': // Quarter to Date
			return {
				start: new Date(relativeDate.getFullYear(), Math.floor(relativeDate.getMonth() / 3) * 3, 1),
				end: relativeDate
			};

		case 'this-month': // This Month
			return {
				start: new Date(relativeDate.getFullYear(), relativeDate.getMonth(), 1),
				end: new Date(relativeDate.getFullYear(), relativeDate.getMonth() + 1, 0)
			};

		case 'previous-month': // Previous Month
			return {
				start: new Date(relativeDate.getFullYear(), relativeDate.getMonth() - 1, 1),
				end: new Date(relativeDate.getFullYear(), relativeDate.getMonth(), 0)
			};

		case 'this-quarter': // This Quarter
			if (budgetPeriods?.length === 1) {
				const budgetPeriodStart = new Date(budgetPeriods[0].start);
				const budgetPeriodEnd = new Date(budgetPeriods[0].end);

				// Case if custom quarters are defined
				if (budgetPeriods[0]?.quarters?.length) {
					const foundCurrentQuarterIndex = _getQuarterIndexFromQuarters(budgetPeriods[0]?.quarters, new Date());

					// If current date does not fall within any of the quarters, return null
					if (foundCurrentQuarterIndex === null) {
						return null;
					}
					return _findCustomDefinedQuarterFromBudgetPeriod(budgetPeriods[0], foundCurrentQuarterIndex);
				} else {
					// Generate Budget quarters based on start and end date
					const budgetQuarters = generateBudgetQuarters(budgetPeriodStart, budgetPeriodEnd);
					const foundCurrentQuarterIndex = _getQuarterIndexFromQuarters(budgetQuarters, new Date());
					// If current date does not fall within any of the quarters, return null
					if (foundCurrentQuarterIndex === null) {
						return null;
					}
					return {
						start: new Date(budgetQuarters[foundCurrentQuarterIndex].start),
						end: new Date(budgetQuarters[foundCurrentQuarterIndex].end)
					};
				}
			} else {
				return {
					start: new Date(relativeDate.getFullYear(), Math.floor(relativeDate.getMonth() / 3) * 3, 1),
					end: new Date(relativeDate.getFullYear(), Math.floor(relativeDate.getMonth() / 3) * 3 + 3, 0)
				};
			}

		case 'previous-quarter': // Previous Quarter
			if (budgetPeriods?.length === 1) {
				const budgetPeriodStart = new Date(budgetPeriods[0].start);
				const budgetPeriodEnd = new Date(budgetPeriods[0].end);

				// Case if custom quarters are defined
				if (budgetPeriods[0]?.quarters?.length) {
					const foundCurrentQuarterIndex = _getQuarterIndexFromQuarters(budgetPeriods[0]?.quarters, new Date());
					// If current date does not fall within any of the quarters or is already first quarter, return null
					if (foundCurrentQuarterIndex === null || foundCurrentQuarterIndex === QuarterIndex.Q1) {
						return null;
					}
					return _findCustomDefinedQuarterFromBudgetPeriod(budgetPeriods[0], foundCurrentQuarterIndex - 1);
				} else {
					// Generate Budget quarters based on start and end date
					const budgetQuarters = generateBudgetQuarters(budgetPeriodStart, budgetPeriodEnd);
					const foundCurrentQuarterIndex = _getQuarterIndexFromQuarters(budgetQuarters, new Date());
					// If current date does not fall within any of the quarters or is already first quarter, return null
					if (foundCurrentQuarterIndex === null || foundCurrentQuarterIndex === QuarterIndex.Q1) {
						return null;
					}
					return {
						start: new Date(budgetQuarters[foundCurrentQuarterIndex - 1].start),
						end: new Date(budgetQuarters[foundCurrentQuarterIndex - 1].end)
					};
				}
			} else {
				// Get the previous quarter by subtracting 3 months from the current date
				return {
					start: new Date(relativeDate.getFullYear(), Math.floor((relativeDate.getMonth() - 3) / 3) * 3, 1),
					end: new Date(relativeDate.getFullYear(), Math.floor((relativeDate.getMonth() - 3) / 3) * 3 + 3, 0)
				};
			}

		case 'next-quarter': // Next Quarter
			if (budgetPeriods?.length === 1) {
				const budgetPeriodStart = new Date(budgetPeriods[0].start);
				const budgetPeriodEnd = new Date(budgetPeriods[0].end);

				// Case if custom quarters are defined
				if (budgetPeriods[0]?.quarters?.length) {
					const foundCurrentQuarterIndex = _getQuarterIndexFromQuarters(budgetPeriods[0]?.quarters, new Date());
					// If current date does not fall within any of the quarters or is already last quarter, return null
					if (foundCurrentQuarterIndex === null || foundCurrentQuarterIndex === QuarterIndex.Q4) {
						return null;
					}
					return _findCustomDefinedQuarterFromBudgetPeriod(budgetPeriods[0], foundCurrentQuarterIndex + 1);
				} else {
					// Generate Budget quarters based on start and end date
					const budgetQuarters = generateBudgetQuarters(budgetPeriodStart, budgetPeriodEnd);
					const foundCurrentQuarterIndex = _getQuarterIndexFromQuarters(budgetQuarters, new Date());
					// If current date does not fall within any of the quarters or is already last quarter, return null
					if (foundCurrentQuarterIndex === null || foundCurrentQuarterIndex === QuarterIndex.Q4) {
						return null;
					}
					return {
						start: new Date(budgetQuarters[foundCurrentQuarterIndex + 1].start),
						end: new Date(budgetQuarters[foundCurrentQuarterIndex + 1].end)
					};
				}
			} else {
				// Get the next quarter by adding 3 months to the current date
				return {
					start: new Date(relativeDate.getFullYear(), Math.floor(relativeDate.getMonth() / 3) * 3 + 3, 1),
					end: new Date(relativeDate.getFullYear(), Math.floor(relativeDate.getMonth() / 3) * 3 + 6, 0)
				};
			}
		case 'q1': // Get the first quarter of the budget period start and end dates (if they exist)
			if (budgetPeriods?.length === 1) {
				// Budget period start and end are strings, so convert them to dates.
				const budgetPeriodStart = new Date(budgetPeriods[0].start);
				const budgetPeriodEnd = new Date(budgetPeriods[0].end);

				const foundCustomDefinedQuarter = _findCustomDefinedQuarterFromBudgetPeriod(budgetPeriods[0], QuarterIndex.Q1);

				if (foundCustomDefinedQuarter) {
					return foundCustomDefinedQuarter;
				}

				// Generate Budget quarters based on start and end date
				const budgetQuarters = generateBudgetQuarters(budgetPeriodStart, budgetPeriodEnd);

				return {
					start: new Date(budgetQuarters[0].start),
					end: new Date(budgetQuarters[0].end)
				};
			} else {
				return {
					start: new Date(relativeDate.getFullYear(), 0, 1),
					end: new Date(relativeDate.getFullYear(), 3, 0)
				};
			}

		case 'q2': // Get the second quarter of the budget period start and end dates (if they exist)
			if (budgetPeriods?.length === 1) {
				// Budget period start and end are strings, so convert them to dates.
				const budgetPeriodStart = new Date(budgetPeriods[0].start);
				const budgetPeriodEnd = new Date(budgetPeriods[0].end);
				const foundCustomDefinedQuarter = _findCustomDefinedQuarterFromBudgetPeriod(budgetPeriods[0], QuarterIndex.Q2);
				if (foundCustomDefinedQuarter) {
					return foundCustomDefinedQuarter;
				}

				// Generate Budget quarters based on start and end date
				const budgetQuarters = generateBudgetQuarters(budgetPeriodStart, budgetPeriodEnd);

				return {
					start: new Date(budgetQuarters[1].start),
					end: new Date(budgetQuarters[1].end)
				};
			} else {
				return {
					start: new Date(relativeDate.getFullYear(), 3, 1),
					end: new Date(relativeDate.getFullYear(), 6, 0)
				};
			}

		case 'q3': // Get the third quarter of the budget period start and end dates (if they exist)
			if (budgetPeriods?.length === 1) {
				// Budget period start and end are strings, so convert them to dates.
				const budgetPeriodStart = new Date(budgetPeriods[0].start);
				const budgetPeriodEnd = new Date(budgetPeriods[0].end);
				const foundCustomDefinedQuarter = _findCustomDefinedQuarterFromBudgetPeriod(budgetPeriods[0], QuarterIndex.Q3);
				if (foundCustomDefinedQuarter) {
					return foundCustomDefinedQuarter;
				}

				// Generate Budget quarters based on start and end date
				const budgetQuarters = generateBudgetQuarters(budgetPeriodStart, budgetPeriodEnd);

				return {
					start: new Date(budgetQuarters[2].start),
					end: new Date(budgetQuarters[2].end)
				};
			} else {
				return {
					start: new Date(relativeDate.getFullYear(), 6, 1),
					end: new Date(relativeDate.getFullYear(), 9, 0)
				};
			}

		case 'q4': // Get the fourth quarter of the budget period start and end dates (if they exist)
			if (budgetPeriods?.length === 1) {
				// Budget period start and end are strings, so convert them to dates.
				const budgetPeriodStart = new Date(budgetPeriods[0].start);
				const budgetPeriodEnd = new Date(budgetPeriods[0].end);

				const foundCustomDefinedQuarter = _findCustomDefinedQuarterFromBudgetPeriod(budgetPeriods[0], QuarterIndex.Q4);
				if (foundCustomDefinedQuarter) {
					return foundCustomDefinedQuarter;
				}

				// Generate Budget quarters based on start and end date
				const budgetQuarters = generateBudgetQuarters(budgetPeriodStart, budgetPeriodEnd);

				return {
					start: new Date(budgetQuarters[3].start),
					end: new Date(budgetQuarters[3].end)
				};
			} else {
				return {
					start: new Date(relativeDate.getFullYear(), 9, 1),
					end: new Date(relativeDate.getFullYear(), 12, 0)
				};
			}
	}

	function _findCustomDefinedQuarterFromBudgetPeriod(budgetPeriod: PublicBudgetPeriod, quarterIndex: number) {
		if (!budgetPeriod?.quarters?.length) {
			return null;
		} else if (budgetPeriod?.quarters?.length < 3) {
			return null;
			// 	Check if any of quarters is null
		} else if (budgetPeriod.quarters.some(quarter => !quarter.start || !quarter.end)) {
			return null;
		}
		// Budget period start and end are strings, so convert them to dates.
		if (budgetPeriod?.quarters?.length > 0 && budgetPeriod.quarters[quarterIndex]) {
			const quarter = budgetPeriod.quarters[quarterIndex];
			return {
				start: new Date(createTimezoneProofDateString(quarter.start)),
				end: new Date(createTimezoneProofDateString(quarter.end))
			};
		}
	}

	function _getQuarterIndexFromQuarters(quarters: Partial<PublicBudgetPeriodQuarter>[], date: Date): number | null {
		for (let i = 0; i < quarters.length; i++) {
			const quarter = quarters[i];
			if (date.getTime() >= new Date(quarter.start).getTime() && date.getTime() <= new Date(quarter.end).getTime()) {
				return i;
			}
		}
		return null; // return null if the current date doesn't fall within any of the quarters
	}

	function generateBudgetQuarters(startDate: Date, endDate: Date): Partial<PublicBudgetPeriodQuarter>[] {
		const quarters: Partial<PublicBudgetPeriodQuarter>[] = [];
		const totalDays = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24) + 1;
		const quarterDays = Math.ceil(totalDays / 4);

		const currentStart = new Date(startDate);

		for (let i = 0; i < 4; i++) {
			const currentEnd = new Date(currentStart);
			currentEnd.setDate(currentEnd.getDate() + quarterDays - 1);

			if (currentEnd > endDate) {
				currentEnd.setTime(endDate.getTime());
			}

			quarters.push({ start: new Date(currentStart), end: new Date(currentEnd) });

			currentStart.setDate(currentStart.getDate() + quarterDays);

			if (currentStart > endDate) {
				break;
			}
		}

		return quarters;
	}
}

export function removeTimezonefromDate(date: Date) {
	return formatDate(new Date(date), `MMM d \''\'yy`, 'en-US');
}

export function convertDateWithoutTimezone(date: Date): Date {
	const timezoneOffset = date.getTimezoneOffset() * 60000; // Convert timezone offset to milliseconds
	return new Date(date.getTime() - timezoneOffset);
}

export function createTimezoneProofDateString(date: Date | string) {
	if (!(date instanceof Date)) {
		const { year, month, day } = parseDateString(date);
		return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}T12:00:00.000Z`;
	} else {
		date = convertDateWithoutTimezone(date);

		// Set the time to 12:00 UTC
		const utcYear = date.getUTCFullYear();
		const utcMonth = date.getUTCMonth();
		const utcDate = date.getUTCDate();

		// Note: Months are 0-indexed in JavaScript Date, hence the +1. Ensure to pad single digits with leading zeros.
		return `${utcYear}-${String(utcMonth + 1).padStart(2, '0')}-${String(utcDate).padStart(2, '0')}T12:00:00.000Z`;
	}
}
export function parseDateString(dateString: string) {
	// Regular expression to match the "YYYY-MM-DD" format
	const regex = /^\d{4}-\d{2}-\d{2}$/;

	if (!regex.test(dateString)) {
		throw new Error('Invalid date format. Please use "YYYY-MM-DD".');
	}

	const parts = dateString.split('-');
	const year = parseInt(parts[0], 10);
	const month = parseInt(parts[1], 10);
	const day = parseInt(parts[2], 10);
	return { year, month, day };
}

export function findEarliestAndLatestDate(dates: { start?; end? }[]): { earliest: Date; latest: Date } {
	// Flatten the array to get all dates, excluding undefined dates
	const allDates: Date[] = dates.reduce((acc: Date[], range: { start; end }) => {
		if (range.start) {
			acc.push(new Date(range.start));
		}
		if (range.end) {
			acc.push(new Date(range.end));
		}
		return acc;
	}, []);

	if (allDates.length === 0) {
		// If no valid dates were found, return null for both earliest and latest dates
		return { earliest: null, latest: null };
	}

	// Sort dates to get the earliest and latest
	allDates.sort((a, b) => a.getTime() - b.getTime());

	const earliest = allDates[0];
	const latest = allDates[allDates.length - 1];

	return { earliest, latest };
}

export function extractDateFromISOString(isoString: string): string {
	const date = new Date(isoString);
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
	const day = String(date.getDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
}
