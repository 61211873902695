import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ProgramPhase } from './program-phase.model';

export interface ProgramPhaseState extends EntityState<ProgramPhase> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'program-phase' })
export class ProgramPhaseStore extends EntityStore<ProgramPhaseState> {
  constructor() {
    super();
  }
}
