import {
	Column,
	Entity,
	JoinColumn,
	JoinTable,
	ManyToMany,
	ManyToOne,
	OneToMany,
	OneToOne,
	PrimaryGeneratedColumn,
	RelationId,
} from 'typeorm';

import { Sortable } from '../_core/decorators/sortable.decorator';

import { BudgetPeriod, PublicBudgetPeriod } from '../budget-period/budget-period.entity';
import { Program, PublicProgram } from '../program/program.entity';
import { PublicRetailer, Retailer } from '../retailer/retailer.entity';
import { Category, PublicCategory } from '../category/category.entity';
import { Brand, PublicBrand } from '../brand/brand.entity';
import { PublicUser, User } from '../user/user.entity';

import { BudgetCache, PublicBudgetCache } from '../budget-cache/budget-cache.entity';
import { PublicTag, Tag } from '../tag/tag.entity';
import { Note, PublicNote } from '../note/note.entity';
import { ExternalId, PublicExternalId } from '../external-id/external-id.entity';
import { CacheResultItem } from '../budget-cache/models/budget-cache.models';
import { BudgetCacheFragmentGenerator } from '../budget-cache/utils/budget-cache-fragment-generator';
import { RetailerFragmentGenerator } from '../retailer/utils/retailer-fragment-generator';
import { SortHelper } from '../_core/decorators/sort-helper.decorator';
import { BudgetAllocation, PublicBudgetAllocation } from '../budget-allocation/budget-allocation.entity';
import { File, PublicFile } from '../file/file.entity';
import { ProgramSector, PublicProgramSector } from '../program-sector/program-sector.entity';
import { BrandStrategy, BrandStrategyFreeform } from '../brand/dtos/set-brand-strategy.dto';
import { WorkflowOption } from '../integrations/vyc-commerce-platform/models';
import { FeaturedProgram } from './featured-program.entity';
import { Agency, PublicAgency } from '../agency/agency.entity';
import { AgencyFragmentGenerator } from '../agency/utils/agency-fragment-generator';
import { Location, PublicLocation } from '../location/location.entity';

export enum PlanStatus {
	Draft = 'draft',
	Approved = 'approved',
}

export type PublicPlan = Pick<
	Plan,
	| 'id'
	| 'name'
	| 'detail'
	| 'budgetPeriodId'
	| 'status'
	| 'retailerId'
	| 'agencyId'
	| 'locationId'
	| 'brandStrategy'
	| 'customerStrategy'
	| 'commercePlatformWorkflow'
	| 'created'
> & {
	budgetPeriod: PublicBudgetPeriod;
	retailer?: PublicRetailer;
	agency?: PublicAgency;
	location?: PublicLocation<any>;
	categories?: PublicCategory[];
	brands?: PublicBrand[];
	programSector?: PublicProgramSector;
	budgetAllocations: PublicBudgetAllocation[];
	externalIds?: PublicExternalId[];
	programIds?: string[];
	programs?: PublicProgram[];
	budgetCache?: PublicBudgetCache;
	brandCaches?: PublicBudgetCache[];
	budgetCacheBrand?: CacheResultItem;
	tags?: PublicTag[];
	notes?: PublicNote[];
	owners?: PublicUser[];
	author: PublicUser;
	files?: PublicFile[];
	featuredPrograms?: FeaturedProgram[] | Partial<FeaturedProgram>[];
};

@Entity('plans')
export class Plan {
	constructor(value?: Partial<Plan>) {
		if (value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	budgetPeriodId: string;
	@ManyToOne((type) => BudgetPeriod, {
		onDelete: 'CASCADE',
	})
	@JoinColumn({ name: 'budgetPeriodId' })
	budgetPeriod: BudgetPeriod;

	@Column('text', { nullable: true })
	retailerId?: string;
	@ManyToOne((type) => Retailer, {
		onDelete: 'CASCADE',
	})
	@JoinColumn({ name: 'retailerId' })
	@Sortable
	@SortHelper(new RetailerFragmentGenerator({ planMatch: true }))
	retailer?: Retailer;

	@Column('text', { nullable: true })
	agencyId?: string;
	@ManyToOne(() => Agency, {
		eager: true,
		onDelete: 'CASCADE',
	})
	@JoinColumn({ name: 'agencyId' })
	@Sortable
	@SortHelper(new AgencyFragmentGenerator({ programMatch: true }))
	agency?: Agency | Partial<Agency>;

	@Column('text', { nullable: true })
	locationId?: string;
	@ManyToOne(() => Location, {
		eager: true,
		onDelete: 'SET NULL',
	})
	@JoinColumn({ name: 'locationId' })
	location?: Location<any>;

	@Column({
		type: 'enum',
		enum: PlanStatus,
		default: PlanStatus.Draft,
	})
	@Sortable
	status: PlanStatus;

	@Column('text', { default: 'Unnamed Plan' })
	@Sortable
	name: string;

	@Column('text', { nullable: true })
	detail?: string;

	@Column('jsonb', { nullable: true })
	brandStrategy?: BrandStrategy[] | BrandStrategyFreeform;

	@Column('text', { nullable: true })
	@Sortable
	customerStrategy?: string;

	@Column('text', { nullable: true })
	programSectorId: string;
	@ManyToOne(() => ProgramSector, {
		nullable: true,
		eager: true,
		onDelete: 'CASCADE',
	})
	@JoinColumn({ name: 'programSectorId' })
	programSector: ProgramSector | Partial<ProgramSector>;

	@ManyToMany(() => Category, {
		eager: true,
		nullable: true,
		cascade: true,
	})
	@JoinTable({ name: 'planCategories' })
	categories?: Category[] | Partial<Category>[];

	@ManyToMany(() => Brand, {
		eager: true,
		nullable: true,
		cascade: true,
	})
	@JoinTable({ name: 'planBrands' })
	brands?: Brand[] | Partial<Brand>[];

	@OneToMany(() => BudgetAllocation, (budgetAllocation) => budgetAllocation.plan, {
		//eager: true,
		cascade: true,
	})
	budgetAllocations: BudgetAllocation[] | Partial<BudgetAllocation>[];

	@OneToMany(() => ExternalId, (externalId) => externalId.plan, {
		eager: true,
		cascade: true,
	})
	externalIds?: ExternalId[] | Partial<ExternalId>[];

	// @OneToMany(
	// 	() => Program,
	// 	program => program.plan,
	// 	{
	// 		nullable: true,
	// 		cascade: true,
	// 		onDelete: 'CASCADE'
	// 	}
	// )
	// programs?: Program[] | Partial<Program>[];

	@RelationId((plan: Plan) => plan.programs)
	programIds?: string[];

	@ManyToMany(() => Program, (program) => program.plans, {
		nullable: true,
		onDelete: 'CASCADE',
	})
	programs?: Program[] | Partial<Program>[];

	@ManyToMany(() => Tag, {
		eager: true,
		nullable: false,
		cascade: true,
	})
	@JoinTable({ name: 'planTags' })
	tags: Tag[] | Partial<Tag>[];

	@ManyToMany(() => Note, {
		eager: true,
		nullable: false,
		cascade: true,
		onDelete: 'CASCADE',
	})
	@JoinTable({ name: 'planNotes' })
	notes: Note[] | Partial<Note>[];

	@Column('jsonb', { nullable: true })
	commercePlatformWorkflow?: WorkflowOption;

	@ManyToMany(() => User, {
		eager: true,
		nullable: true,
		cascade: true,
		onDelete: 'CASCADE',
	})
	@JoinTable({ name: 'planOwners' })
	owners?: User[] | Partial<User>[];

	@OneToOne(() => BudgetCache, (budgetCache) => budgetCache.plan, {
		nullable: true,
		eager: true,
		cascade: ['insert', 'update'],
	})
	@Sortable
	@SortHelper(new BudgetCacheFragmentGenerator({ planMatch: true }))
	budgetCache?: BudgetCache;
	brandCaches?: CacheResultItem[];
	budgetCacheBrand?: CacheResultItem;

	@Column('text', { nullable: false })
	authorId: string;
	@ManyToOne((type) => User, {
		eager: true,
		onDelete: 'CASCADE',
	})
	author: User;

	@Column('boolean', {
		nullable: false,
		default: false,
	})
	deleted: boolean;

	@Column({ type: 'timestamptz', nullable: false, default: () => 'NOW()' })
	@Sortable
	created: string;

	files?: File[];

	type: 'Plan';

	@OneToMany(() => FeaturedProgram, (featuredProgram) => featuredProgram.plan, {
		cascade: true,
	})
	@JoinTable({ name: 'plansFeaturedPrograms' })
	featuredPrograms?: FeaturedProgram[] | Partial<FeaturedProgram>[];

	public toPublic(): PublicPlan {
		const pub: Partial<PublicPlan> = {
			id: this.id,
			name: this.name,
			detail: this.detail,
			budgetPeriodId: this.budgetPeriodId,
			status: this.status,
			retailerId: this.retailerId,
			agencyId: this.agencyId,
			locationId: this.locationId,
			brandStrategy: this.brandStrategy,
			customerStrategy: this.customerStrategy,
			commercePlatformWorkflow: this.commercePlatformWorkflow,
			created: this.created,
		};

		if (this.budgetPeriod) {
			pub.budgetPeriod = new BudgetPeriod(this.budgetPeriod).toPublic();
		}

		if (this.retailer) {
			pub.retailer = new Retailer(this.retailer).toPublic();
		}

		if (this.agency) {
			pub.agency = new Agency(this.agency).toPublic();
		}

		if (this.location) {
			pub.location = new Location<any>(this.location).toPublic();
		}

		if (this.programIds) {
			pub.programIds = this.programIds;
		}

		if (this.featuredPrograms) {
			pub.featuredPrograms = this.featuredPrograms.map((p) => new FeaturedProgram(p).toPublic());
		}

		if (this.categories?.length) {
			pub.categories = (this.categories as Partial<Category>[]).map((c) => {
				return new Category(c).toPublic();
			});
		}

		if (this.brands?.length) {
			pub.brands = (this.brands as Partial<Brand>[]).map((b) => {
				return new Brand(b).toPublic();
			});
		}

		if (this.programSector) {
			pub.programSector = new ProgramSector(this.programSector).toPublic();
		}

		if (this.externalIds) {
			pub.externalIds = (this.externalIds as Partial<ExternalId>[]).map((i) => {
				return new ExternalId(i).toPublic();
			});
		}

		if (this.budgetAllocations?.length) {
			pub.budgetAllocations = (this.budgetAllocations as Partial<BudgetAllocation>[]).map((b) => {
				return new BudgetAllocation(b).toPublic();
			});
		}

		if (this.programIds?.length) {
			pub.programIds = this.programIds;
		}

		if (this.programs?.length) {
			pub.programs = (this.programs as Partial<Program>[]).map((p) => {
				return new Program(p).toPublic();
			});
		}

		if (this.notes?.length) {
			pub.notes = (this.notes as Partial<Note>[]).map((n) => {
				return new Note(n).toPublic();
			});
		}

		if (this.tags?.length) {
			pub.tags = (this.tags as Partial<Tag>[]).map((t) => {
				return new Tag(t).toPublic();
			});
		}

		if (this.budgetCache) {
			pub.budgetCache = new BudgetCache(this.budgetCache).toPublic();
		}

		if (this.brandCaches) {
			pub.brandCaches = this.brandCaches.map((bc) => new BudgetCache(bc).toPublic());
		}

		if (this.budgetCacheBrand) {
			pub.budgetCacheBrand = this.budgetCacheBrand;
		}

		if (this.owners) {
			pub.owners = (this.owners as Partial<User>[]).map((o) => {
				return new User(o).toPublic();
			});
		}

		if (this.author) {
			pub.author = new User(this.author).toPublic();
		}

		if (this.files?.length) {
			pub.files = (this.files as Partial<File>[]).map((f) => {
				return new File(f).toPublic();
			});
		}

		return pub as PublicPlan;
	}
}
