import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { GlobalQuery } from '../../global/global.query';
import { Tactic } from '../tactic/tactic.model';
import { Invoice } from './invoice.model';
import { InvoiceStore } from './invoice.store';
import { InvoiceFacets } from '../../../../../../api/src/find/dtos/filter-set.dto';
import { Observable } from 'rxjs';
import { FilterParameters } from '../filter/filter.model';
import { TableCollection } from '../../table/table.model';

/**
 * Invoice Service
 * This service handles the logic for the invoices and API calls.
 */
@Injectable({ providedIn: 'root' })
export class InvoiceService {
	constructor(private invoiceStore: InvoiceStore, private http: HttpClient, private readonly globalQuery: GlobalQuery) {}

	// get() {}

	/**
	 * Set the list of invoices on the Akita store to be what is passed in.
	 */
	set(invoices: Invoice[]) {
		this.invoiceStore.set(invoices.map(invoice => this.prepareForAkita(invoice)));
	}

	/**
	 * Create an invoice on the API.
	 */
	create(tacticId: Tactic['id'], invoice: Invoice) {
		this.invoiceStore.setLoading(true);

		return this.http
			.post<Invoice>(
				`${environment.apiUrl}/organization/${environment.organizationId}/tactic/${tacticId}/invoice`,
				this.prepareForApi(invoice)
			)
			.pipe(
				tap(newValue => {
					this.invoiceStore.update(invoice.id, this.prepareForAkita(newValue));
					this.invoiceStore.setLoading(false);
				})
			);
	}

	/**
	 * Add an invoice to the Akita store.
	 */
	add(invoice: Invoice) {
		this.invoiceStore.add(invoice);
	}

	/**
	 * Update an invoice on the API.
	 * If the invoice doesn't have a `created` field, it will only be updated in the Akita store. This is for new invoices before they are saved to the API.
	 */
	update(tacticId: Tactic['id'], id: Invoice['id'], invoice: Partial<Invoice>, skipHTTP?: boolean) {
		this.invoiceStore.setLoading(true);

		if (skipHTTP) {
			this.invoiceStore.update(id, { ...invoice });
			return EMPTY;
		}

		if (invoice.created || skipHTTP === false) {
			return this.http
				.put<Invoice>(
					`${environment.apiUrl}/organization/${environment.organizationId}/tactic/${tacticId}/invoice/${id}`,
					this.prepareForApi(invoice)
				)
				.pipe(
					map(invoice => this.prepareForAkita(invoice)),
					tap(newValue => {
						this.invoiceStore.update(invoice.id, newValue);
						this.invoiceStore.setLoading(false);
					}),
					catchError(err => {
						this.invoiceStore.update(invoice.id, this.invoiceStore.getValue().entities[invoice.id]);
						return throwError(err);
					})
				);
		} else {
			console.log('Updating Akita Only', invoice);
			this.invoiceStore.update(invoice.id, this.prepareForAkita(invoice));
			this.invoiceStore.setLoading(false);
			return of(invoice);
		}
	}

	/**
	 * Remove an invoice from the API.
	 */
	remove(tacticId: Tactic['id'], id: Invoice['id']) {
		this.invoiceStore.setLoading(true);

		return this.http
			.delete<Invoice>(`${environment.apiUrl}/organization/${environment.organizationId}/tactic/${tacticId}/invoice/${id}`)
			.pipe(
				tap(newValue => {
					this.invoiceStore.remove(id);
					this.invoiceStore.setLoading(false);
				})
			);
	}

	/**
	 * Change the Akita loading state for this store.
	 */
	setLoading(state: boolean) {
		this.invoiceStore.setLoading(state);
	}

	/**
	 * Normalize an invoice for Akita.
	 */
	prepareForAkita(invoice: Partial<Invoice>): Invoice {
		const obj: Partial<Invoice> = {
			type: 'Invoice'
		};
		const settings = this.globalQuery.getValue().settings;

		console.log('Preparing for Akita', invoice);

		if (invoice) {
			Object.keys(invoice).forEach(key => {
				switch (key) {
					case 'brandAllocations':
						// Reconcile with brand objects if they aren't already fixed
						if (invoice[key]?.length && !invoice[key][0].name) {
							obj[key] = invoice[key]?.map(allocation => settings.brands.find(b => b.id === allocation.brand.id));
						} else {
							obj[key] = invoice[key];
						}
						break;

					default:
						obj[key] = invoice[key];
						break;
				}
			});
		}

		console.log('Akita Ready', obj);
		return obj as Invoice;
	}

	/**
	 * Normalize an invoice for the API.
	 */
	prepareForApi(invoice: Partial<Invoice>) {
		const obj = {};

		console.log('Preparing for API', invoice);

		if (invoice) {
			Object.keys(invoice).forEach(key => {
				switch (key) {
					case 'vendor':
						obj[key + 'Id'] = invoice[key]?.id;
						break;

					case 'amount':
						obj['value'] = invoice.amount.toString();
						break;

					case 'id':
					case 'created':
					case 'author':
						break;

					case 'status':
						if (invoice[key]?.['name']) {
							obj[key] = invoice[key]['name'];
						} else {
							obj[key] = invoice[key];
						}
						break;

					case 'budgetCache.spendActual':
						obj['amount'] = invoice[key];
						break;

					case 'brandAllocations':
						obj['brandAllocations'] = invoice[key]?.map(brand => ({
							brandId: brand.id,
							// Split brand allocation evenly among brands
							split: String(1 / invoice[key].length)
						}));
						break;

					default:
						obj[key] = invoice[key];
						break;
				}
			});
		}

		return obj;
	}

	/**
	 * Return an object of brands with the toal amounts of all the invoices provided for each brand.
	 */
	getBrandTotals(invoices: Invoice[]) {
		const obj = {};

		invoices?.forEach(invoice => {
			// Get the brands and then split the amount
			console.log(invoice, invoices);
			invoice.brandAllocations?.forEach(brand => {
				// Find the name depending on if we're using the flattend version or not
				const name = brand?.name || brand?.brand?.name;

				// Create an object
				if (!obj[name]) {
					obj[name] = 0;
				}

				// Increment the value of our amount / # of brands
				obj[name] += invoice.amount / invoice.brandAllocations.length;
			});
		});

		return obj;
	}

	/*
	Get invoices by filter
	*/
	getInvoices(filters: FilterParameters) : Observable<TableCollection<Invoice>> {
		const params = new HttpParams();
		params.append('page', '1');
		params.append('perPage', '1000');
		const dto : InvoiceFacets = {
			retailerIds: filters.retailers?.map(r => r.id),
			budgetPeriodIds: filters.budgetPeriods?.map(bp => bp.id),
			...(filters.brands?.length && { brandIds: filters.brands?.map(b => b.id)}),
		};
		return this.http.post<TableCollection<Invoice>>(`${environment.apiUrl}/organization/${environment.organizationId}/find/invoices`, dto, { params });
	}
}
